var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-cashout-error" }, [
    _vm.isLoading
      ? _c("div", [
          _c("i", { staticClass: "m-icon-load" }),
          _vm._v(" "),
          _c("p", { staticClass: "m-text-msg" }, [
            _vm._v(_vm._s(_vm.$t("loading:dot:label")))
          ])
        ])
      : _vm.isLoadError
      ? _c(
          "div",
          [
            _c("p", { staticClass: "m-text-msg" }, [
              _vm._v(_vm._s(_vm.$t("loading:fail:text")))
            ]),
            _vm._v(" "),
            _c(
              "af-Button",
              { attrs: { extraType: "text" }, on: { click: _vm.reload } },
              [_vm._v(_vm._s(_vm.$t("load:label")))]
            )
          ],
          1
        )
      : !_vm.loginStatus
      ? _c("div", [
          _c("p", { staticClass: "m-text-msg" }, [
            _vm._v(_vm._s(_vm.$t("cashout:nologin:text")))
          ])
        ])
      : _vm.isEmpty
      ? _c("div", [
          _c("p", { staticClass: "m-text-msg" }, [
            _vm._v(_vm._s(_vm.$t("no:cashout:text")))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "m-text-msg" }, [
            _vm._v(_vm._s(_vm.$t("cashout:place:bet:text")))
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }