export const flexiNConfig = {
	30: 27,
	29: 25,
	28: 24,
	27: 23,
	26: 22,
	25: 21,
	24: 20,
	23: 18,
	22: 17,
	21: 16,
	20: 14,
	19: 13,
	18: 11
};

export const unlimitNMax = 17;

// flexiNConfig = {
// 	30: 25,
// 	29: 22,
// 	28: 23,
// 	27: 20,
// 	26: 20,
// 	25: 20,
// 	24: 16,
// 	23: 15,
// 	22: 11,
// 	21: 10,
// };

// unlimitNMax = 19
