<template>
	<div class="m-live-event">
		<AfTableRow v-if="isShowTournament" :class="['m-content-row', 'title-row', {
			'live': productId === 1
			}] ">
				<template v-if="event.status === 1 || event.status === 2">
					<!-- 所有玩法都显示matchStatus -->
					<div class="match-status">
						{{event.matchStatus}}
					</div>
						<!-- 足球，篮球，Rugby, handball, ice hockey显示 -->
					<div class="clock-time" v-if="isShowPlaySec">{{ useRemainTime ? event.remainingTimeInPeriod : event.playedSeconds}}</div>
				</template>
				<!-- 没开始 -->
				<template v-else-if="event.status === 0">
					<div>Upcoming</div>
				</template>
				<!-- 其他情况 -->
				<template v-else>
					<div class="match-status">
						{{event.matchStatus}}
					</div>
				</template>
				<div class="game-id">ID:{{event.gameId}}</div>
				<div class="tournament-name" :title="event.categoryName + ' - ' + event.tournamentName">{{event.categoryName}} - {{event.tournamentName}}</div>
		</AfTableRow>
	<AfTableRow :class="['m-content-row', 'title-row', {
		'live': productId === 1
		}] ">
		<div v-if="isShowTournament" class="title-row--left"/>
		<div v-else class="title-row--left">
			<template v-if="event.status === 1 || event.status === 2">
				<!-- 所有玩法都显示matchStatus -->
				<div class="match-status">
					{{event.matchStatus}}
				</div>
					<!-- 足球，篮球，Rugby, handball, ice hockey显示 -->
				<div class="clock-time" v-if="isShowPlaySec">{{ useRemainTime ? event.remainingTimeInPeriod : event.playedSeconds}}</div>
				<!-- 列表页等不展示tounament名字的情况展示gameId -->
				<div class="game-id">ID:{{event.gameId}}</div>
			</template>
			<!-- 没开始 -->
			<template v-else-if="event.status === 0">
				<div>Upcoming</div>
			</template>
			<!-- 其他情况 -->
			<template v-else>
				<div class="match-status">
					{{event.matchStatus}}
				</div>
			</template>
		</div>
		<div class="title-row--right">
			<div class="market-xyz" v-for="marketId in currentMarketIds">{{getMarketXYZ(event, marketId)}}</div>
		</div>
	</AfTableRow>
	<AfTableRow :class="['m-content-row', 'match-row', sportName + '-row']">
		<!-- 为了兼容ie9嵌套多层 -->
		<AfTableCell class="left-team-cell">
			<div class="left-team-table">
				<!-- 队名 -->
				<div class="teams" @click="jump(event)" :title="event.homeTeamName + ' vs ' + event.awayTeamName">
					<!-- 直播比赛显示 当比赛状态是upcoming时显示 upcoming 否则不现实，赛前比赛显示gameId-->
					<div class="home-team">{{event.homeTeamName}}</div>
					<div class="away-team">{{event.awayTeamName}}</div>
				</div>
				<img class="live-icon" v-if="event.liveChannel" :src="require('./img/tv-color.png')"/>
				<!-- 比分 -->
				<div class="score">
					<div v-for="(one, index) in (event.setScore || '').split(':')" :key="index" class="score-item">
						{{one || ''}}
					</div>
				</div>

				<!-- 网球显示 没有数据显示空表格-->
				<div class="game-score" v-if="hasGameScore">
					<template v-if="isShowGameScore">
						<div v-for="(one, index) in event.gameScore[event.gameScore.length - 1].split(':')"
						:key="index"
						class="score-item">
							{{one || ''}}
						</div>
					</template>
				</div>

				<!-- 网球显示 没有数据显示空表格 -->
				<div class="point" v-if="sportId === 'sr:sport:5'">
					<template v-if="isShowPointScore">
						<div v-for="(one, index) in event.pointScore.split(':')" :key="index" class="score-item">
							{{one || ''}}
						</div>
					</template>
				</div>
			</div>
		</AfTableCell>
		<!-- market显示 -->
		<!-- sportId获取做一个兼容 -->
		<!-- 如果是直播比赛，并且是第一行就显示title -->
		<AfTableCell  :class="[
				'market-cell',
				{
					'two-markets': currentMarketIds.length === 2,
				}
			]">
			<Market v-for="(marketId, index) in currentMarketIds" :key="index"
				:productId="productId"
				:sportId="event.sportId || sportId"
				:marketId="marketId"
				:event="event"
				:isHideSelectSpecifiers="isHideSelectSpecifiers(marketId)"
				class="market"
				>
			</Market>
		</AfTableCell>
		<!-- 在这场比赛是live的时候跳转live，否则跳转pre match -->
		<AfTableCell @click.native="jump(event)" class="market-size">+{{event.totalMarketSize}}<i class="icon-arrow-right"/></AfTableCell>
	</AfTableRow>
</div>
</template>

<script>
	/**
	 * 针对一个events的列表做显示
	 */
	import { AfTable, AfTableRow, AfTableCell } from 'packages/tableLayout';
	import { getSportConfigLowerCase } from 'config/sportsType';
	import { getMarketXYZ } from 'config/marketConfig';
	import { baseUrl } from 'config/pageConfig';
	import Market from './market';

	export default {
		name: 'LiveEvent',
		components: {
			Market,
			AfTable,
			AfTableRow,
			AfTableCell
		},
		props: {
			// 里面有所有的比赛的map，应该与eventOrder相匹配
			event: {
				required: true,
				type: Object,
				default () {
					return {};
				}
			},
			// 当前需要显示的marketId数组-因为后台可能传递多个market，我们不一定显示，我们这里定义要显示的market
			currentMarketIds: {
				required: true,
				type: Array
			},
			sportId: {
				type: String
			},
			// 1(直播),3(赛前)
			productId: {
				type: [String, Number],
				required: true
			},
			isShowTournament: {
				type: Boolean,
				'default': true
			}
		},
		computed: {
			isShowPlaySec () {
				// tennis, cricket, volleyball, darts, beach volleyball
				const includes = [
					'sr:sport:5',
					'sr:sport:21',
					'sr:sport:31',
					'sr:sport:3',
					'sr:sport:20',
					'sr:sport:37',
					'sr:sport:32',
					'sr:sport:19'
				].includes(this.sportId);
				return !includes;
			},
			useRemainTime() {
				const includes = [
					'sr:sport:2',
					'sr:sport:24',
					'sr:sport:4'
				].includes(this.sportId);
				return includes;
			},
			sportConfigLowerCase() {
				return getSportConfigLowerCase();
			},
			sportName () {
				return this.sportConfigLowerCase[this.sportId.slice(9)] || '';
			},
			hasGameScore() {
				return [
					'sr:sport:5',
					'sr:sport:23',
					'sr:sport:34',
					'sr:sport:22',
					'sr:sport:31',
					'sr:sport:3',
					'sr:sport:20',
					'sr:sport:37',
					'sr:sport:32',
					'sr:sport:19'
				].includes(this.event.sportId);
			},
			isShowGameScore () {
				return this.event.gameScore && this.event.gameScore.length &&
				this.event.gameScore[this.event.gameScore.length - 1];
			},
			isShowPointScore () {
				return this.sportId === 'sr:sport:5' && this.event.pointScore;
			},
		},
		methods: {
			getMarketXYZ,
			isHideSelectSpecifiers (marketId) {
				// 网球为5的market下拉列表隐藏
				// if (this.sportId === 'sr:sport:5' && +marketId === 202) {
				// 	return true;
				// }
				if (this.sportId === 'sr:sport:21' && +marketId === 11) {
					return true;
				}
				return false;
			},
			// 跳转到对应的比赛详情页面
			jump (event) {
				const sportName = this.sportConfigLowerCase[event.sportId.slice(9)];
				let url = `${baseUrl}sport/${sportName}/${event.categoryId}/${event.tournamentId}/${event.eventId}`;
				if (this.productId === 1) {
					url = `${baseUrl}sport/${sportName}/live/${event.categoryId}/${event.tournamentId}/${event.eventId}`;
					if (event.status === 0) {
						url = `${baseUrl}sport/${sportName}/${event.categoryId}/${event.tournamentId}/${event.eventId}`;
					}
				}
				window.location.href = url;
			},
			// 暂时无用，取后台固定字段
			converNum (num) {
				if (!num) {
					return '';
				}
				num = String(num);
				const shu = {
					1: 'st',
					2: 'nd',
					3: 'rd'
				};
				const endS = /\d*(1|2|3)$/;
				const res = endS.exec(num);
				return res ? num + shu[res[1]] : num + 'th';
			}
		}
	};
</script>

<style lang="less" scoped>
@import '~base/style/variable.less';
@import '~base/style/mixin.less';
@import 'base/style/icon.less';

.m-live-event {
	border-bottom: 1px solid #49525F;
	padding: 9px 10px;

	.market-size {
		cursor: pointer;
		text-align: right;
		.icon-arrow-right {
			.m-icon-arrow--right();
			&:before {
				font-size: 12px;
				margin-left: 2px;
			}
		}
	}

}
.title-row {
	font-size: 12px;
	margin-bottom: 6px;
	line-height: 16px;
	color: @pcDark;
	text-align: left;
	&.live {
		color: @e9Gray;
	}
	.match-status {
		font-weight: bold;
	}
	.game-id, .match-status {
		flex: 0 0 auto;
		margin-right: 6px;
		color: @e9Gray;
	}
	.clock-time {
		flex: 0 0 auto;
		margin-right: 6px;
		font-weight: bold;
		color: @e9Gray;
	}
	.tournament-name {
		flex: 1 1 auto;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-right: 6px;
	}
	.title-row--left {
		width: 230px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.title-row--right {
		margin-right: 49px;
		flex: 1 1 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		.market-xyz {
			flex: 1;
			min-width: 1%;
			text-align: center;
		}
	}

	.blank-block {
		flex: 1 1 auto;
	}
}

.left-team-table {
	display: flex;
	align-items: center;
	justify-content: center;
	.teams {
		flex: 1;
		overflow: hidden;
		margin-right: 12px;
		line-height: 18px;
		div {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			text-align: left;
		}
	}
	.live-icon {
		margin-right:12px;
		flex: 0 0 auto;
		width: 16px;
		height: 16px;
	}
	.score {
		line-height: 18px;
		margin-right: 12px;
		color: @e9Gray;
	}
	.game-score, .point {
		line-height: 18px;
		margin-right: 12px;
		color: @midGray;
		opacity: .5;
	}
}
</style>
