<template>
<div class="m-loading-wrap" v-if="isLoading !== 0 && isLoading !== false">
	<div :class="['m-loading', themeName]">
		<div class="loading" v-if="isLoading !== -1">{{i18n.t('loading:dot:label')}}</div>
		<div class="loading-fail" v-if="isLoading === -1">
			<p class="fail-text">{{i18n.t('data:load:fail')}}</p>
			<div class="fail-btn" @click="fail">{{i18n.t('load:label')}}</div>
		</div>
	</div>
</div>
</template>

<script>
import { i18n } from 'locale/setup';

export default {
	props: {
		theme: {
			type: String,
				// light 和 dark
			'default': 'light',
		},
		isLoading: {
			type: [Boolean, Number],
			'default': true
		}
	},
	data() {
		return {
			i18n
		};
	},
	computed: {
		themeName() {
			let name = 'light'
			switch (window.theme) {
				case "theme-default":
					name = 'light'
					console.log(this.theme)
					if(this.theme) {
						name = this.theme
					}
					break
				case "theme-dark":
					name = 'blue'

			}
			return name
		}
	},
	methods: {
		fail () {
			this.$emit('fail');
		}
	}
};
</script>

<style lang="less">
@import '~base/style/variable.less';
@import 'base/style/icon.less';
@import 'base/style/logoLoading.less';

.m-loading-wrap{
	min-height: 300px;
}
.m-loading {
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	width: 100%;
	text-align: center;
	.loading{
		.m-icon-loading-circle();
		top: 50%;
		left: 50%;
		position: absolute;
		transform: translate(-50%, -50%);
		&:before{
			height: 60px;
			width: 60px;
			line-height: 60px;
			font-size: 33px;
			display: block;
			border-radius: 60px;
			color: @white;
			margin-bottom: 10px;
			animation: logoLoading 1s infinite linear;
		}
	}
	.loading-fail{
		text-align: center;
		margin: 0 auto;
		display: block;
		position: absolute;
		transform: translate(-50%, -50%);
		left: 50%;
		top: 50%;
		z-index: 111;
		.m-icon-tips();
		&::before {
			transform: rotate(180deg);
			transform-origin: center;
			display: block;
			font-size: 32px;
			color: @midGray;
		}
		.fail-text{
			font-size: 14px;
			display: inline-block;
		}
		.fail-btn{
			border-radius: 2px;
			width: 65px;
			height: 32px;
			line-height: 32px;
			text-align: center;
			background-color: @c05CFD8toE1FF2F;
			color:@cFFFFFFto000000;
			cursor: pointer;
			margin: auto;
			margin-top: 18px;
			font-size: 12px;
			&:active{
				background-color: @c47EDF4toE1FF2F;
			}
			&:hover{
				background-color: @c00E0E9toE1FF2F;
			}
		}
	}
	&.light {
		// background: @white;
		.loading {
			// color: @darkGray;
			&:before{
				// background-color: @lightGray;
				color: @dark;
			}
		}
		.loading-fail{
			.fail-text{
				color: @dark;
			}
		}
	}
	&.dark {
		// background: @dark;
		.loading {
			// color: @darkGray;
			&:before{
				// background-color: fade(@lightGray, 15%);
				color: @white;
			}
		}
		.loading-fail{
			&::before {
				color: rgba(234, 236, 239, 0.15);
			}
			.fail-text{
				color: @white;
			}
		}
	}
	&.blue {
		background: #363E4B;
		.loading {
			color: #e1ff2f;
			&::before{
				color: #e1ff2f
			}
		}
		.loading-fail{
			&::before{
				color: #e1ff2f;
			}
			.fail-text{
				color: #e1ff2f;
			}
			.fail-btn{
				color: @cFFFFFFto000000;
				height: 36/px;
				line-height: 36/px;
				padding: 0 28/px;
				display: inline-block;
				border: 1px solid #e1ff2f;
				border-radius: 3/px;
			}
		}
	}
}
</style>
