<template>
<div class="m-market m-event-market-default" @click.stop="handlerStop">
	<!-- 注意：Next Goal 类型不显示 specifiers, wap 赛事列表页outcome每行最多显示三个 -->
	<template v-if="currentMarket && currentMarket.outcomeOrder">
		<!-- 有 specifiers就显示-->
		<div v-if="isShowSpecifier" class="m-outcome-specifier specifiers-select">
			<SpecifierList :markets="markets" :specifiers="specifiers" v-model="currentSpecifier" :sportId="sportId" :productId="productId" :event="event" />
		</div>
		<!-- 如果market上获取的productId
		和预先设置的不一样，认为market不可以投注 -->
		<OutcomeCell v-for="(id, index) in currentMarket.outcomeOrder" :key="index" :sportId="sportId" :productId="productId" :market="currentMarket" :event="event" :disabled="currentMarket.productId !== productId" :outcome="currentMarket.outcomes[id]">
			<span class="m-outcome-title" v-if="title && title[index]">{{title[index]}}</span>
			<!-- suspended显示 -->
			<span :class="['m-outcome-odds', {
				'm-icon-lock': currentMarket.status !== 0 || currentMarket.outcomes[id].isActive !== 1
			}]">{{currentMarket.status !== 0 || currentMarket.outcomes[id].isActive !== 1 ? '' : oddsTrans(currentMarket.outcomes[id].odds, oddsType)}}</span>
		</OutcomeCell>
	</template>
	<!-- 有一种情况是后台压根没有传递market，但是这个market还是要显示,所以取固定数据显示这个market -->
	<template v-else>
		<div v-if="isShowLockSpecifier" class="m-outcome m-event-outcome-default m-outcome--disabled  specifiers-select">
			<span class="m-icon-lock"></span>
		</div>
		<template v-if="currentMarketsOutComeSize">
			<div class="m-outcome--disabled m-outcome m-event-outcome-default m-icon-lock" v-for="one in currentMarketsOutComeSize" :key="one">
				<span class="m-icon-lock"></span>
			</div>
		</template>
	</template>
</div>
</template>

<script>
import {
	mapState
} from 'vuex';
import 'packages/select';
import { sortMarkets, getMarketConfig } from 'config/marketConfig';
import OutcomeCell from '../outcomeCell.vue';
import SpecifierList from '../specifierList.vue';

// 根据不同的id去显示不同的market
// 记住这里只显示market
export default {
	components: {
		OutcomeCell,
		SpecifierList
	},
	name: 'Markets',
	props: {
		// 只是marketId，不带specifier
		marketId: {
			type: [String, Number]
		},
		event: {
			type: Object,
		},
		// 是否只显示某个置顶的specifiers，这个只对带specifiers起作用
		showOnlyOneSpecifiers: {
			type: [String, Number],
			'default': ''
		},
		// 是否隐藏掉下拉列表的选择specifiers, 如果这个marketId在这个数组里面，则这个market不显示 select，选择specifiers
		// 仅对带specifiers的market管用
		isHideSelectSpecifiers: {
			type: Boolean,
			'default': false
		},
		// title用这里传递的，暂时无用
		title: {
			type: Array,
		},
		// 1(直播),3(赛前)
		productId: {
			type: [String, Number]
		},
		// 为什么需要sportId是因为在不同的sport下的marketId可能是一样的
		sportId: {
			type: String,
			required: true
		},
		// 是否显示Next Goal market下拉列表的选择specifiers
		isShowNextGoalSpecifiers: {
			type: Boolean,
			'default': false
		}
	},
	data() {
		return {
			currentMarket: null,
			specifiers: null,
			specifiersName: null,
			currentSpecifier: null
		};
	},
	watch: {
		markets: {
			immediate: true,
			handler(val) {
				if (!val) {
					this.reset();
					return;
				}
				// 如果是一个Object证明是一个普通的makerts, array就是一组specifier的market
				// 这里要注意如果是combo的maket，也有可能带specifier，目前列表页面没有这种情况
				if (!val.length) {
					this.currentMarket = val;
					this.specifiers = null;
					this.specifierName = null;
					this.currentSpecifier = null;
				} else {
					this.parseCurrentMarkets(val);
				}
			}
		},
		currentSpecifier(val) {
			if (val && this.markets) {
				this.currentMarket = this.markets[val.index];
			}
		}
	},
	computed: {
		...mapState('betslip', ['oddsType']), // 这里应保证引入了betslip store
		currentMarketsOutComeSize() {
			const marketConf = getMarketConfig(this.marketId, this.productId, this.sportId) || {};
			return marketConf.titleArr ? marketConf.titleArr.length : 0;
		},
		isShowNextGoal() {
			const isNextGoal = this.isNextGoal;
			const isShow = this.isShowNextGoalSpecifiers;
			return !isNextGoal || (isNextGoal && isShow);
		},
		isShowSelectSpecifier() {
			return !this.isHideSelectSpecifiers;
		},
		isShowSpecifier() {
			return this.isShowNextGoal && this.currentSpecifier && this.isShowSelectSpecifier;
		},
		isShowLockSpecifier() {
			const size = this.currentMarketsOutComeSize;
			return this.isShowNextGoal && size && size.length && this.isShowSelectSpecifier;
		},
		markets() {
			let markets = this.event.markets;
			markets = markets ? markets[this.marketId] || {} : {};
			if (markets.length) {
				markets = markets.filter(cur => cur.status === 0 || cur.status === 1);
				// 全部过滤掉了，这个时候就认为没有market
				if (markets.length === 0) {
					return null;
				}
			}
			return markets;
		},
		isNextGoal() {
			return +this.marketId === 8;
		}
	},
	methods: {
		handlerStop() {},
		reset() {
			this.currentMarket = null;
			this.specifiers = null;
			this.specifierName = null;
			this.currentSpecifier = null;
		},
		parseCurrentMarkets(targetMarkets) {
			const {
				markets,
				specifierList,
				name
			} = sortMarkets(targetMarkets);
			// 如果有可以投注的market
			if (specifierList.length) {
				// favourite字段目前没有处理，因为数据问题，不知道这货是否准确
				this.specifiers = specifierList;
				this.specifierName = name;
				const index = markets.findIndex(cur => cur.favourite === 1);
				const {
					status
				} = markets[index] || {};
				if (index > -1 && (status === 0 || status === 1)) {
					this.currentMarket = markets[index];
					this.currentSpecifier = specifierList[index];
				} else {
					for (let i = 0; i < markets.length; i++) {
						if (markets[i].status === 0 || markets[i].status === 1) {
							this.currentMarket = markets[i];
							this.currentSpecifier = specifierList[i];
							break;
						}
					}
				}

				// next goal: 显示 specifier值最大的一个
				if (+this.marketId === 8) {
					const max = specifierList.sort((a, b) => a.name < b.name)[0];
					this.currentMarket = markets[max.index];
					this.currentSpecifier = max;
				}

				if (!this.currentMarket) {
					this.currentMarket = markets[0];
					this.currentSpecifier = specifierList[0];
				}
			} else {
				this.reset();
			}
		}
	}
};
</script>
<style lang="less">
@import '~base/style/variable.less';
@import '~base/style/icon.less';

.m-event-market-default {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.m-market {
  width: 100%;
  position: relative;
  .m-outcome, .m-outcome-specifier {
    &:first-child{
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child{
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: none;
    }
    box-shadow: -1px 0px 0px 0px rgba(255, 255, 255, 0.4) inset;
  }
  .m-outcome {
    position: relative;
  }
  .m-outcome-specifier,.m-outcome{
    flex:1;
    display: block;
    min-width: 1%;
    text-align: center;
    background-color: @outcomeBgColor;
    color: @DarkWhite;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    cursor: pointer;
    &>span{
      display: block;
    }
    &.m-outcome--up{
      .m-icon-up2();
			&:before {
				color: @green;
			}
    }
    &.m-outcome--down{
      .m-icon-down2();
			&:before {
				color: @red;
			}
    }
    &.m-outcome--up,&.m-outcome--down{
      &:before{
        display: block;
        position: absolute;
        top: 2px;
        right: 2px;
        line-height: 1;
        font-size: 12px;
        transform: scale(0.83);
      }
    }
    &.m-outcome--checked {
      background: @buttonActiveBgColor;
      // color: @blueBlack;
      color: @whiteBlackColor;
      font-weight: bold;
      // &:before{
      // 	color: @dark;
      // }
      // &:hover{
      // 	background: #24D8FF;
      // }
      // &:active{
      // 	background: @outcomeActive;
      // }
    }
    //&:hover{
    //  background: @outcomeHover;
    //}
    //&:active{
    //  background: @outcomeActive;
    //}
    &.m-outcome--disabled{
      background: @whiteGray;
      color: @darkGray;
      &:before{
        font-size: 0;
      }
      .m-icon-lock{
        .m-icon-locked();
        &:before{
          font-size: 16px;
          vertical-align: middle;
        }
      }
    }
    border: 0px;
    &:last-of-type{
      border: 0px;
    }
  }
  .m-outcome-specifier.specifiers-select {
    overflow: visible;
  }
}
</style>
