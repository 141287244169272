// update stake
export const UPDATESTAKE = 'updateStake';

// update orderInfo
export const UPDATEORDERINFO = 'updateOrderInfo';

// update errorInfo
export const UPDATEERRORINFO = 'updateErrorInfo';

// update bet betLoading
export const UPDATEBETLOADING = 'updateBetLoading';

// reset stake
export const RESETSTAKE = 'resetStake';

export const UPDATEBONUS = 'updateBonus';
