<template>
<div :class="['m-market', 'm-event-market-table-b', {
	'show-more': showMore
	}]" @click.stop="handlerStop">
	<template v-if="showTable">
		<!-- 如果market上获取的productId
		和预先设置的不一样，认为market不可以投注 -->
		<div class="outcome-row" v-for="(row, i) in showTable">
			<template v-for="(id, index) in row">
				<OutcomeCell :class="['show-title', {
					live: +productId === 1
					}]" :key="index" v-if="id !== ''" :sportId="sportId" :productId="productId" :market="currentMarket" :event="event" :disabled="currentMarket.productId !== productId" :outcome="currentMarket.outcomes[id]">
					<span class="m-outcome-inner-title" v-if="currentMarket.outcomes[id] && currentMarket.outcomes[id].desc">{{currentMarket.outcomes[id].desc}}</span>
					<!-- suspended显示 -->
					<span :class="['m-outcome-odds', {
						'm-icon-lock': currentMarket.status !== 0 || currentMarket.outcomes[id].isActive !== 1
					}]">{{currentMarket.status !== 0 || currentMarket.outcomes[id].isActive !== 1 ? '' : oddsTrans(currentMarket.outcomes[id].odds, oddsType)}}</span>
				</OutcomeCell>
				<div v-else class="outcome-blank"/>
			</template>
		</div>
		<div class="outcome-action-wrap" v-if="showMore">
			<i :class="['m-icon', {
				'arrow-up': !isHide,
				'arrow-down': isHide
				}]" @click="toggleHide"/>
		</div>
	</template>
	<!-- 有一种情况是后台压根没有传递market，但是这个market还是要显示,所以取固定数据显示这个market -->
	<template v-else>
		<div class="outcome-row" v-if="currentMarketsOutComeSize">
			<div class="m-outcome--disabled m-outcome m-event-outcome-default m-icon-lock" v-for="one in currentMarketsOutComeSize" :key="one">
				<span class="m-icon-lock"></span>
			</div>
		</div>
	</template>
</div>
</template>

<script>
import {
	mapState
} from 'vuex';
import { getMarketConfig, b1 } from 'config/marketConfig';
import OutcomeCell from '../outcomeCell.vue';

// 根据不同的id去显示不同的market
// 记住这里只显示market
export default {
	components: {
		OutcomeCell
	},
	name: 'Markets',
	props: {
		// 只是marketId，不带specifier
		marketId: {
			type: [String, Number]
		},
		event: {
			type: Object,
		},
		// 是否只显示某个置顶的specifiers，这个只对带specifiers起作用
		showOnlyOneSpecifiers: {
			type: [String, Number],
			'default': ''
		},
		// 是否隐藏掉下拉列表的选择specifiers, 如果这个marketId在这个数组里面，则这个market不显示 select，选择specifiers
		// 仅对带specifiers的market管用
		isHideSelectSpecifiers: {
			type: Boolean,
			'default': false
		},
		// title用这里传递的，暂时无用
		title: {
			type: Array,
		},
		// 1(直播),3(赛前)
		productId: {
			type: [String, Number]
		},
		// 为什么需要sportId是因为在不同的sport下的marketId可能是一样的
		sportId: {
			type: String,
			required: true
		},
		// 是否显示Next Goal market下拉列表的选择specifiers
		isShowNextGoalSpecifiers: {
			type: Boolean,
			'default': false
		}
	},
	data() {
		return {
			currentMarket: null,
			formatTable: '',
			isHide: true
		};
	},
	watch: {
		markets: {
			immediate: true,
			handler(val) {
				if (!val) {
					this.reset();
					return;
				}
				const market = val;
				if (market) {
					this.currentMarket = market;
					this.parseMarket(market);
				} else {
					this.reset();
					console.error('market parse error');
				}
			}
		},
		currentSpecifier(val) {
			if (val && this.markets) {
				this.currentMarket = this.markets[val.index];
			}
		}
	},
	computed: {
		...mapState('betslip', ['oddsType']), // 这里应保证引入了betslip store
		currentMarketsOutComeSize() {
			const marketConf = getMarketConfig(this.marketId, this.productId, this.sportId) || {};
			return marketConf.titleArr ? marketConf.titleArr.length : 0;
		},
		markets() {
			let markets = this.event.markets;
			markets = markets ? markets[this.marketId] || {} : {};
			if (markets.length) {
				markets = markets.filter(cur => cur.status === 0 || cur.status === 1);
				// 全部过滤掉了，这个时候就认为没有market
				if (markets.length === 0) {
					return null;
				}
			}
			return markets;
		},
		showTable() {
			return this.isHide ? this.formatTable.slice(0, 3) : this.formatTable;
		},
		showMore() {
			return this.formatTable.length > 3;
		}
	},
	methods: {
		handlerStop() {},
		toggleHide() {
			this.isHide = !this.isHide;
		},
		reset() {
			this.currentMarket = null;
		},
		parseMarket(market) {
			const table = [];
			const marketId = +market.id || '';
			const {
				outcomeOrder,
				outcomes
			} = market;
			if (b1.indexOf(marketId) > -1) {
				// 足球correct score  三列
				// 打平和other都放在other中
				let homeWin = [],
					equal = [],
					awayWin = [];
				const other = [];
				outcomeOrder.forEach(key => {
					const {
						desc
					} = outcomes[key];
					if (desc.indexOf(':') > -1) {
						const scores = desc.split(':');
						const val = scores[0] - scores[1];
						if (val > 0) {
							// homeWin
							if (!homeWin[scores[0]]) {
								homeWin[scores[0]] = [];
							}
							homeWin[scores[0]][scores[1]] = key;
						} else if (val < 0) {
							// 这里希望做到比分和homeWin刚好对应
							if (!awayWin[scores[1]]) {
								awayWin[scores[1]] = [];
							}
							awayWin[scores[1]][scores[0]] = key;
						} else {
							// equal不需要二维数组排序
							equal[scores[0]] = key;
						}
					} else {
						// 这里认为没有:的就是other
						other.push(key);
					}
				});
				let left = [],
					middle = [],
					right = [];
				homeWin.forEach(row => {
					row.forEach(key => {
						left.push(key);
					});
				});
				middle = equal.concat(other);
				awayWin.forEach(row => {
					row.forEach(key => {
						right.push(key);
					});
				});
				let longest = left.length > right.length ? left : right;
				longest = longest.length > middle.length ? longest : middle;
				longest.forEach((key, i) => {
					table.push([left[i] || '', middle[i] || '', right[i] || '']);
				});
			}

			if (table.length > 0) {
				this.formatTable = table;
			}
		}
	}
};
</script>
<style lang="less">
@import '~base/style/variable.less';
@import '~base/style/icon.less';

.m-event-market-table-b {
	padding-top: 18px;
	padding-bottom: 24px;
	&.show-more {
		padding-bottom: 0;
	}
	.outcome-row {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 1px;
		&:last-child {
			margin-bottom: 0px;
		}
		.show-title {
			margin-top: 7px;
			margin-bottom: 0;
			position: relative;
			overflow: visible;
			.m-outcome-inner-title {
				position: absolute;
				line-height: 10px;
				font-size: 10px;
				left: 50%;
				transform: translateX(-50%);
				top: -5px;
				color: @DarkWhite;
				font-weight: normal;
			}
			&.live {
				.m-outcome-inner-title {
					color: @white;
				}
			}
			&.m-outcome--disabled {
				.m-outcome-inner-title {
					color: @darkGray;
				}
			}
		}
		.outcome-blank {
			flex: 1 1 auto;
			margin-right: 1px;
			min-width: 1%;
		}
	}

	.outcome-action-wrap {
		height: 24px;
		line-height: 24px;
		text-align: center;
		.m-icon {
			&::before {
				font-size: 14px;
			}
			&.arrow-up {
				.m-icon-arrow--up();
				color: @c000000toFFFFFF;
			}
			&.arrow-down {
				.m-icon-arrow--down();
				color: @c000000toFFFFFF;
			}
		}
	}
}
</style>
