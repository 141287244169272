import * as mutationTypes from './mutationTypes';

export default {
	[mutationTypes.UPDATEBONUS](state, data = null) {
		//
		// data.bonusRatios = [
		// 	{
		// 		qualifyingSelections: 2,
		// 		ratio: 300
		// 	},
		// 	{
		// 		qualifyingSelections: 3,
		// 		ratio: 500
		// 	},
		// 	{
		// 		qualifyingSelections: 4,
		// 		ratio: 800
		// 	}
		// ];
		if (data && data.bonusRatios) {
			state.bonusRatios = data.bonusRatios.reduce((res, cur) => {
				res[cur.qualifyingSelections] = cur.ratio / 10000;
				return res;
			}, {});
			state.qualifyingOddsLimit = data.qualifyingOddsLimit / 10000;
		}
	}
};
