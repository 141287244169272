import {
	CHANGE_LOADING,
	CHANGE_SKELETON_LOADING
} from './mutationTypes';

export default {
	// loading状态 -1表示加载失败，0|false 表示加载成功 1|true表示加载中
	[CHANGE_LOADING] (state, status) {
		if (status !== -1 && status !== 0 && status !== 1 && typeof status !== 'boolean') {
			return;
		}
		state.isLoading = status;
	},
	// false 表示隐藏 true表示展示
	[CHANGE_SKELETON_LOADING] (state, status) {
		if (typeof status !== 'boolean') {
			return;
		}
		// 主控
		if(!window.skeletonShow) {
			status = false
		}
		state.showSkeleton = status;
	},
};
