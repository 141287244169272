import { isEmptyObject } from 'utils';
import { getStakeIndex } from 'config/stakeConfig';
import { getStakeInfo } from './commonFun';

export default {
	/*
	获取的system play all时的历史输入值
	 */
	uStake: (state, getters) => state.singleStake.union,

	/*
	根据当前选中的投注类型，获取填入的历史stake值
	 */
	getStake: (state, getters, rootState) => {
		const ret = [];

		switch (rootState.betslip.currentType) {
		case 'signle': {
			ret.push(state.singleStake);
			break;
		}
		case 'multiple': {
			ret.push(state.multipleStake);
			break;
		}
		case 'system': {
			const stakeKeys = Object.keys(state.systemStake);
			for (const key of stakeKeys) {
				if (key !== 'union') {
					ret.push(state.systemStake[key]);
				}
			}
			break;
		}
		default:
		}

		return ret;
	},
	/*
	[ {name, count, odds} ]
	 */
	selectionInfo: (state, getters, rootState, rootGetters) => getStakeInfo(rootState.betslip, rootState.bonus, rootGetters['bonus/currentBonusConf'] || {}, state, rootGetters['betslip/isFlexi'], rootGetters['betslip/flexiThreshold'], rootGetters),

	// system投注组合中有没有Singles组合
	hasSingle: (state, getters) => {
		const selections = getters.selectionInfo || [];
		const selection = selections[0];
		if (!selection) {
			return false;
		}
		return getStakeIndex(selection.name) === 1; // 1对应 Singles
	},
	/*
	[ {name, count, odds, stake} ]
	 */
	stakeUnion: (state, getters, rootState) => {
		const betslip = rootState && rootState.betslip || {};
		if (isEmptyObject(betslip)) {
			return [];
		}
		const selections = getters.selectionInfo || [],
			currentType = betslip.currentType;

		// single下，如果存在suspend和unavailable的情况时，没有选项列表，直接返回空数组
		if (!selections.length) {
			return [];
		}

		switch (currentType) {
		case 'single':
			selections[0].stake = state.singleStake || '';
			break;
		case 'multiple':
			selections[0].stake = state.multipleStake || '';
			break;
		case 'system':
			{
				const systemStake = state.systemStake;
				const singleStake = state.singleStake;
				for (const selectItem of selections) {
					if (getStakeIndex(selectItem.name) === 1) { // Singles
						const betslips = rootState.betslip.betslips;
						const getSelectList = rootState.betslip.systemCheckedList;
						let sum = 0;
						const orderInfo = [];
						betslips.forEach(slip => {
							if (slip.outcomeInfo && slip.outcomeInfo.showOdds && getSelectList.indexOf(slip.key) > -1) {
								const slipStake = +singleStake[slip.key] || 0;
								sum += slipStake;
								orderInfo.push({
									odds: +slip.outcomeInfo.odds || 0,
									stake: slipStake,
									stakeStr: singleStake[slip.key]
								});
							}
						});
						selectItem.stake = sum;
						selectItem.orderInfo = orderInfo;
					} else {
						selectItem.stake = systemStake[selectItem.name] || '';
					}
				}
			}
		default:
		}

		return selections;
	},

	// total stake
	calTotalStake: (state, getters, rootState) => {
		const { bankerStatus, bankerList } = rootState.betslip;
		let ret = 0;
		for (const stakeItem of getters.stakeUnion) {
			if (stakeItem.count && stakeItem.stake) {
				if (getStakeIndex(stakeItem.name) === 1) {
					if (!bankerStatus || bankerList.length === 0) {
						ret += stakeItem.stake; // 已经算过single stake和了
					} else { // 有banker且Single组合则banker肯定只有一个
						ret += +state.singleStake[bankerList[0] || ''] || 0;
					}
				} else {
					ret += stakeItem.count * stakeItem.stake;
				}
			}
		}
		return ret;
	},
};
