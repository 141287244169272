// sport icon 样式
import { i18n } from 'locale/setup';
import './sports.less';

/**
 * sports 配置
 * id: sport id
 * text: 页面显示用 text
 * icon: sport 对应的 iconfont 样式
 * name: 球类名，遵循驼峰法，来源于属性名
 */

// 有些配置不涉及国际化的， 提供一个可直接导入执行的配置, 不过目前写法会导致warning，先不管
const getSportsConfigValue = () => ({
	soccer: {
		id: 'sr:sport:1',
		text: i18n.t('sport:name:1'),
		icon: 'm-icon-football'
	},
	basketball: {
		id: 'sr:sport:2',
		text: i18n.t('sport:name:2'),
		icon: 'm-icon-basketball'
	},
	tennis: {
		id: 'sr:sport:5',
		text: i18n.t('sport:name:5'),
		icon: 'm-icon-tennis'
	},
	rugby: {
		id: 'sr:sport:12',
		text: i18n.t('sport:name:12'),
		icon: 'm-icon-rugby'
	},
	cricket: {
		id: 'sr:sport:21',
		text: i18n.t('sport:name:21'),
		icon: 'm-icon-cricket'
	},
	volleyball: {
		id: 'sr:sport:23',
		text: i18n.t('sport:name:23'),
		icon: 'm-icon-volleyball',
	},
	iceHockey: {
		id: 'sr:sport:4',
		text: i18n.t('sport:name:4'),
		icon: 'm-icon-iceHockey',
	},
	handball: {
		id: 'sr:sport:6',
		text: i18n.t('sport:name:6'),
		icon: 'm-icon-handball',
	},
	darts: {
		id: 'sr:sport:22',
		text: i18n.t('sport:name:22'),
		icon: 'm-icon-darts',
	},
	beachVolley: {
		id: 'sr:sport:34',
		text: i18n.t('sport:name:34'),
		icon: 'm-icon-beachVolley',
	},
	futsal: {
		id: 'sr:sport:29',
		text: i18n.t('sport:name:29'),
		icon: 'm-icon-football'
	},
	football: {
		id: 'sr:sport:16',
		text: i18n.t('sport:name:16'),
		icon: 'm-icon-football'
	},
	aussieRules: {
		id: 'sr:sport:13',
		text: i18n.t('sport:name:13'),
		icon: 'm-icon-football'
	},
	fieldHockey: {
		id: 'sr:sport:24',
		text: i18n.t('sport:name:24'),
		icon: 'm-icon-football'
	},
	squash: {
		id: 'sr:sport:37',
		text: i18n.t('sport:name:37'),
		icon: 'm-icon-football'
	},
	bowls: {
		id: 'sr:sport:32',
		text: i18n.t('sport:name:32'),
		icon: 'm-icon-football',
	},
	snooker: {
		id: 'sr:sport:19',
		text: i18n.t('sport:name:19'),
		icon: 'm-icon-football',
	},
	baseball: {
		id: 'sr:sport:3',
		text: i18n.t('sport:name:3'),
		icon: 'm-icon-football',
	},
	badminton: {
		id: 'sr:sport:31',
		text: i18n.t('sport:name:31'),
		icon: 'm-icon-football',
	},
	tableTennis: {
		id: 'sr:sport:20',
		text: i18n.t('sport:name:20'),
		icon: 'm-icon-football',
	}
});

let sportsConfigCache;
// name用作标识， text用于展示文案， icon由于图标用的图片所以暂时无用
const getSportsConfig = () => {
	if (!sportsConfigCache) {
		sportsConfigCache = getSportsConfigValue();
	}
	return sportsConfigCache;
};

// sportsConfig 的 sport id 版本, key 为 sport id
let sportsConfigById;

const getSportsConfigById = () => {
	if (!sportsConfigById) {
		sportsConfigById = {};
		const config = getSportsConfig();
		for (const key in config) { // eslint-disable-line
			const sport = config[key];
			sport.name = key;
			sportsConfigById[sport.id] = sport;
		}
	}
	return sportsConfigById;
};

export {
	getSportsConfigById,
	getSportsConfig,
  getSportsConfigValue
};

export default getSportsConfig;
