var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "m-coupon-list" },
    [
      _c("CouponsSkipItem"),
      _vm._v(" "),
      _vm._l(_vm.getRenderList, function(item) {
        return _c("CouponsItem", { key: item.giftId, attrs: { coupon: item } })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }