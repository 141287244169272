var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "m-coupons-skip", on: { click: _vm.skipCoupon } },
    [
      _c("div", { staticClass: "m-select-ban" }, [
        _c("i", {
          class: {
            "m-icon-check": !_vm.skipStaus,
            "m-icon-checked": _vm.skipStaus
          }
        })
      ]),
      _vm._v(" "),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-info-ban" }, [
      _c("div", [_vm._v("Skip Using Gifts")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }