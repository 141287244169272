var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.showSkeleton
    ? _c("div", { staticClass: "important-match" }, [
        _c("div", { staticClass: "banner" }, [
          _c("span", { staticClass: "text" }, [
            _c("img", { attrs: { src: require("./image/sport.png") } }),
            _vm._v("\n\t\t\t" + _vm._s(_vm.$t("highlight:label")) + "\n\t\t")
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading"
                }
              ],
              staticClass: "config"
            },
            [
              _c(
                "span",
                { staticClass: "refresh", on: { click: _vm.fetchData } },
                [_vm._v(_vm._s(_vm.$t("refresh:label")))]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { attrs: { id: "important-match" } }, [
          _vm.sportSizeByOrder && _vm.sportSizeByOrder.length
            ? _c(
                "div",
                { staticClass: "sport-name" },
                [
                  _vm._l(_vm.preSportSize, function(sport) {
                    return _c(
                      "div",
                      {
                        key: sport.id,
                        class: [
                          "sport-name-item",
                          {
                            active: _vm.currentSportId === sport.id
                          }
                        ],
                        on: {
                          click: function($event) {
                            _vm.onChangeSport(sport.id)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "text" }, [
                          _vm._v(_vm._s(sport.name)),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                sport.eventSize > 99 ? "99+" : sport.eventSize
                              )
                            )
                          ])
                        ])
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.postSportSize.length > 0
                    ? _c("SimpleSelect", {
                        ref: "select",
                        class: [
                          "sport-simple-select",
                          _vm.isSelectedActive
                            ? "sport-simple-select-active"
                            : ""
                        ],
                        attrs: {
                          data: _vm.postSportSize,
                          placeholder: _vm.$t("more-game:label")
                        },
                        on: { selected: _vm.onSelectedSport }
                      })
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading:fetchData",
                  value: _vm.loading,
                  expression: "loading",
                  arg: "fetchData"
                }
              ]
            },
            [
              !_vm.loading
                ? [
                    _vm.sportProductStatus === 1
                      ? _c("div", { staticClass: "m-sport-bet-error" }, [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.$t("data:load:fail")) +
                              "\n\t\t\t\t"
                          )
                        ])
                      : !_vm.hasSports
                      ? _c("div", { staticClass: "m-sport-bet-no-data" }, [
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("no:match:available:1")))
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("no:match:available:2")))
                          ])
                        ])
                      : _c(
                          "div",
                          { staticClass: "match" },
                          [
                            _c("div", { staticClass: "flex-box" }, [
                              _c(
                                "div",
                                { staticClass: "left" },
                                _vm._l(
                                  _vm.sportMarketIds[_vm.currentSportId],
                                  function(group, i) {
                                    return group.value !== "default"
                                      ? _c(
                                          "div",
                                          {
                                            key: i,
                                            class: [
                                              "market-group",
                                              {
                                                active: _vm.currentGroup === i
                                              }
                                            ],
                                            on: {
                                              mouseenter: function($event) {
                                                _vm.changeGroup(i)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(group.name) +
                                                "\n\t\t\t\t\t\t\t"
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  }
                                )
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "right" },
                                _vm._l(
                                  _vm.sportMarketIds[_vm.currentSportId][
                                    _vm.currentGroup
                                  ].marketIds,
                                  function(market, i) {
                                    return _c(
                                      "div",
                                      { key: i, staticClass: "market-block" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "market-name" },
                                          [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.getMarketConfig(
                                                    market,
                                                    _vm.productId,
                                                    _vm.currentSportId
                                                  ).name
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t"
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "outcome-name-list" },
                                          _vm._l(
                                            _vm.getMarketConfig(
                                              market,
                                              3,
                                              _vm.currentSportId
                                            ).titleArr,
                                            function(title, i) {
                                              return title
                                                ? _c(
                                                    "div",
                                                    {
                                                      key: i,
                                                      staticClass:
                                                        "outcome-name"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(title) +
                                                          "\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            }
                                          )
                                        )
                                      ]
                                    )
                                  }
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.sport.eventOrder, function(
                              dateEvents,
                              key
                            ) {
                              return [
                                _vm._l(dateEvents, function(eventId, index) {
                                  return [
                                    _c("SportEvent", {
                                      key: eventId,
                                      attrs: {
                                        event: _vm.sport.events[eventId],
                                        currentMarketIds: _vm.currentMarketIds,
                                        sportId: _vm.currentSportId,
                                        productId: _vm.productId
                                      }
                                    })
                                  ]
                                })
                              ]
                            }),
                            _vm._v(" "),
                            _vm.sport
                              ? _c("div", { staticClass: "view-all-wrap" }, [
                                  _c("div", { staticClass: "blank-block" }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "view-all",
                                      on: { click: _vm.viewAllImportant }
                                    },
                                    [
                                      _c(
                                        "i18n",
                                        {
                                          attrs: {
                                            path: "view:all:sport",
                                            tag: "p"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { attrs: { place: "sport" } },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("today:adj")) +
                                                  " " +
                                                  _vm._s(_vm.currentSportName)
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "i",
                                            { attrs: { place: "match" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tc(
                                                    "match:label",
                                                    _vm.sportSizeMap[
                                                      _vm.currentSportId
                                                    ]
                                                  )
                                                ) +
                                                  "\n\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.sportSizeMap[
                                                      _vm.currentSportId
                                                    ]
                                                  )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "icon-arrow--right",
                                    on: { click: _vm.viewAllImportant }
                                  })
                                ])
                              : _vm._e()
                          ],
                          2
                        )
                  ]
                : _vm._e()
            ],
            2
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }