var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "container", staticClass: "m-specifier-list-wrap" }, [
    _vm.value
      ? _c(
          "div",
          { staticClass: "m-specifier-value", on: { click: _vm.openList } },
          [
            _c("span", [_vm._v(_vm._s(_vm.value.name))]),
            _vm._v(" "),
            !_vm.showList
              ? _c("i", { staticClass: "m-icon-arrow-down" })
              : _c("i", { staticClass: "m-icon-arrow-up" })
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showList
      ? _c("div", { staticClass: "m-overlay", on: { click: _vm.closeList } })
      : _vm._e(),
    _vm._v(" "),
    _vm.showList
      ? _c(
          "div",
          {
            class: [
              "m-list-modal-wrap",
              { top: _vm.inTop },
              { bottom: !_vm.inTop }
            ]
          },
          [
            _vm.isDark
              ? _c("img", {
                  staticClass: "m-list-edge",
                  attrs: { src: require("./img/edge-dark.png") }
                })
              : _c("img", {
                  staticClass: "m-list-edge",
                  attrs: { src: require("./img/edge-light.png") }
                }),
            _vm._v(" "),
            _c(
              "ul",
              {
                class: [
                  "m-specifier-list",
                  {
                    live: _vm.isLive
                  }
                ]
              },
              _vm._l(_vm.markets, function(row, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    class: [
                      "m-specifier-item",
                      { selected: _vm.value === _vm.specifiers[index] }
                    ]
                  },
                  [
                    _c(
                      "span",
                      {
                        class: ["specifier-value"],
                        on: {
                          click: function($event) {
                            _vm.onSelectOutcome(index, true)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.specifiers[index].name))]
                    ),
                    _vm._v(" "),
                    _vm._l(row.outcomeOrder, function(id, i) {
                      return _c(
                        "OutcomeCell",
                        {
                          key: i,
                          attrs: {
                            sportId: _vm.sportId,
                            market: row,
                            event: _vm.event,
                            disabled: row.productId !== _vm.productId,
                            outcome: row.outcomes[id]
                          },
                          on: {
                            click: function(val) {
                              return _vm.onSelectOutcome(index, val)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              class: [
                                "m-outcome-odds",
                                {
                                  "m-icon-lock":
                                    row.status !== 0 ||
                                    row.outcomes[id].isActive !== 1
                                }
                              ]
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  row.status !== 0 ||
                                    row.outcomes[id].isActive !== 1
                                    ? ""
                                    : _vm.oddsTrans(
                                        row.outcomes[id].odds,
                                        _vm.oddsType
                                      )
                                )
                              )
                            ]
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              })
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }