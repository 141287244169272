var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-betslip-search" },
    [
      _c("p", { staticClass: "m-text-main" }, [
        _vm._v(_vm._s(_vm.$t("betslip:guide:text")))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "m-opt-wrapper" },
        [
          _c("AfInput", {
            attrs: {
              placeholder: _vm.$t("booking:number:label"),
              icon: "delete",
              error: _vm.hasError,
              iconClick: _vm.clearAllInput
            },
            on: { click: _vm.handleClick },
            model: {
              value: _vm.code,
              callback: function($$v) {
                _vm.code = $$v
              },
              expression: "code"
            }
          }),
          _vm._v(" "),
          _c(
            "af-Button",
            {
              attrs: { type: "primary", disabled: _vm.submitStatus },
              on: { click: _vm.load }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.loading ? _vm.$t("loading:label1") : _vm.$t("load:label")
                )
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasError
        ? _c("Tips", { attrs: { type: "error", msg: _vm.errorMsg } })
        : _vm._e(),
      _vm._v(" "),
      _c("p", { staticClass: "m-text-tips" }, [
        _vm._v(_vm._s(_vm.$t("booking:number:share1:text")))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }