var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "m-gift-wrapper",
        {
          "m-cash-wrapper":
            _vm.contentData.isLapse && !_vm.contentData.isMultiple
        }
      ]
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "m-pop-main" }, [
        _c("div", { staticClass: "m-coupon-type" }, [
          _vm._v("Received " + _vm._s(_vm.getGiftsType) + " !")
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              "m-coupons-info",
              {
                "m-coupon--green":
                  _vm.contentData.isLapse && !_vm.contentData.isMultiple,
                "m-coupon--yellow":
                  !_vm.contentData.isLapse && !_vm.contentData.isMultiple,
                "m-coupon--mix": _vm.contentData.isMultiple
              }
            ]
          },
          [
            _c("div", [
              _c("div", { staticClass: "m-coupons-detail" }, [
                _c("p", { staticClass: "m-coupons-desc" }, [
                  _vm._v(_vm._s(_vm.getGiftsDesc))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "m-coupons-title" }, [
                _c("span", { staticClass: "m-value" }, [
                  _c("span", { staticClass: "m-lable" }, [
                    _vm._v(_vm._s(_vm.showCurrency))
                  ]),
                  _vm._v(
                    _vm._s(_vm.contentData.amount) + " " + _vm._s(_vm.showOff)
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "m-coupons-use" }, [
                _vm._v("Use it in your Betslip")
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "m-gifts-desc-wrapper" }, [
        _c("div", { staticClass: "m-gifts-desc" }, [
          _c("p", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.contentData.title))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.contentData.srcCtt))
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "m-btn-wrapper" },
          [
            _c(
              "af-Button",
              {
                staticClass: "m-btn-gift",
                attrs: {
                  extraType: "text",
                  "data-action": "close",
                  "data-ret": "close"
                },
                on: { click: _vm.goGifts }
              },
              [_vm._v("View")]
            ),
            _vm._v(" "),
            _c(
              "af-Button",
              {
                staticClass: "m-btn-bet",
                attrs: {
                  extraType: "text",
                  "data-action": "close",
                  "data-ret": "bet"
                },
                on: { click: _vm.goBet }
              },
              [_vm._v("Bet Now")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-pop-header" }, [
      _c("i", {
        staticClass: "m-icon-close",
        attrs: { "data-action": "close", "data-ret": "close" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }