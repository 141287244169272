<template>
	<div class="m-livetracker widgets-dark" :id="idKey" v-loading:configWidget.dark="loading"></div>
</template>

<script>
export default {
	name: 'liveMatchTracker',
	props: {
		type: String,
		eventId: String
	},
	data() {
		return {
			widget: null,
			loading: false
		};
	},
	computed: {
		matchId() {
			return this.eventId.split(':')[2];
		},
		idKey() {
	    return 'LMT' + this.matchId;
		}
	},
	watch: {
		type(val) {
			if (val) {
				this.configWidget();
			}
		}
	},
	methods: {
		// 配置并添加 widget
		configWidget() {
			const matchId = this.matchId;
			window.SIR('addWidget', '#' + this.idKey, 'match.lmtPlus', { matchId });
		}
	},

	mounted() {
		this.loading = true;
		this.configWidget();
	}
};
</script>

<style lang="less">
@import './style.less';
</style>
