var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    { staticClass: "s-right" },
    [
      _vm._t("default", [
        _vm.showBetslips
          ? _c(
              "div",
              {
                staticClass: "m-item m-betslip-wrapper",
                attrs: { id: "j_betslip" }
              },
              [
                _c(
                  "AfTabs",
                  {
                    attrs: { value: _vm.acctiveBetTab },
                    on: { change: _vm.handleToggle }
                  },
                  [
                    _c("AfTabPane", {
                      attrs: { label: _vm.getShowBetslipTitle, name: "Betslip" }
                    }),
                    _vm._v(" "),
                    _c("AfTabPane", {
                      attrs: { label: _vm.getShowCashoutTitle, name: "Cashout" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("Betslips", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.acctiveBetTab === "Betslip",
                      expression: "acctiveBetTab === 'Betslip'"
                    }
                  ]
                }),
                _vm._v(" "),
                _vm.acctiveBetTab === "Cashout" ? _c("Cashout") : _vm._e()
              ],
              1
            )
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }