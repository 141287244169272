import * as mutationTypes from './mutationTypes';

export default {
	/*
	bonus
	 */
	getBonus({
		state,
		commit
	}) {
		return new Promise((resolve, reject) => {
			// fetch('/promotion/v1/bonus/plans/valid')  // 这个接口404了
			fetch("/promotion/bonus/list")   // 和 wap 使用一样的接口
				.then(res => res.json())
				.then(ret => {
					// const code = ret.code;
					if (ret.code === 10000 && ret.data && ret.data.entityList) {
						commit(mutationTypes.UPDATEBONUS, ret.data.entityList[0] || []);
					}
				})
				.catch(err => {
					// eslint-disable-line
				});
		});
	}
};
