var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-radio" }, [
    _c(
      "div",
      {
        class: [
          "m-radio-wrap",
          {
            active: _vm.isActive
          }
        ],
        on: { click: _vm.toggle }
      },
      [_c("div", { staticClass: "m-radio-btn" })]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }