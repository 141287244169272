<template lang="html">
	<div class="m-cashout-error">
		<div v-if="isLoading">
			<i class="m-icon-load"></i>
			<p class="m-text-msg">{{$t('loading:dot:label')}}</p>
		</div>

		<!-- 加载cashout列表失败 -->
		<div v-else-if="isLoadError">
			<p class="m-text-msg">{{$t('loading:fail:text')}}</p>
			<af-Button
				extraType='text'
				@click="reload"
			>{{$t('load:label')}}</af-Button>
		</div>
		<div v-else-if="!loginStatus">
			<p class="m-text-msg">{{$t('cashout:nologin:text')}}</p>
		</div>
		<!-- 没有cashout列表 -->
		<div v-else-if="isEmpty">
			<p class="m-text-msg">{{$t('no:cashout:text')}}</p>
			<p class="m-text-msg">{{$t('cashout:place:bet:text')}}</p>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import { EventBus } from 'kernel/js/eventBus.js';
import commonEvent from 'config/eventConfig/commonEvent';
import 'packages/button';

export default {
	name: 'EmptyPage',

	props: {
		isEmpty: {
			type: Boolean,
			'default': false
		},
		isLoadError: {
			type: Boolean,
			'default': false
		},
		isLoading: {
			type: Boolean,
			'default': false
		}
	},
	data() {
		return {
			loginStatus: window.loginStatus
		};
	},
	mounted() {
		EventBus.$on(commonEvent.UPDATE_LOGIN_STATUS, () => {
			this.loginStatus = window.loginStatus;
		});
	},
	methods: {
		...mapActions('cashout', {
			getCashoutList: 'fetchCashoutList'
		}),
		reload() {
			this.getCashoutList();
		}
	}
};
</script>

<style lang="css">
</style>
