
/**
 * 按照wap的方法来计算stakeunion 替换之前pc的计算 只替换system
 */
import { getStakeShowName } from "config/betslip/stakeConfig";
import { formatNumber } from "utils";
/*
	get single odds info
 */
export function getSingleOddsInfoWap(
	mutexObject,
	betslip,
	betslipMap,
	betslipStake,
	isBoosting,
	boostBets
) {
	const oddsInfoMap = [];
	const bonus = [];
	const qualifyingOddsLimit = betslipStake.qualifyingOddsLimit;
	const bonusRatios = betslipStake.bonusRatios;

	const betslips = betslip.betslips || [];
	const outcomeMap = getOutcomeMap(mutexObject, betslips, betslipMap);
	const keys = Object.keys(outcomeMap);
	let singleCount = 0;
	let boostCount = 0;

	const { bankerStatus, bankerMap } = betslip;
	const bankerkeys = Object.keys(bankerMap || {});
	if (bankerStatus && bankerkeys.length === 1) {
		const outcome = betslips[betslipMap[bankerkeys[0]]];
		if (outcome.outcomeInfo.showOdds) {
			const tempOddsInfoMap = getMaxOdds(
				[outcome],
				qualifyingOddsLimit,
				bonusRatios,
				isBoosting,
				boostBets
			);
			console.log(tempOddsInfoMap);
			singleCount += tempOddsInfoMap.count;
			boostCount += tempOddsInfoMap.boostCount;
			oddsInfoMap.push(tempOddsInfoMap.oddsMap);
			bonus.push(tempOddsInfoMap.bonus);
		}
		return {
			count: singleCount,
			oddsInfoMap,
			bonus,
			boostCount
		};
	}
	for (const key of keys) {
		const eventObj = outcomeMap[key];
		eventObj &&
			Object.keys(eventObj).forEach(marketId => {
				// eslint-disable-line
				const outcomes = eventObj[marketId];
				if (outcomes && outcomes.length > 0) {
					const tempOddsInfoMap = getMaxOdds(
						outcomes,
						qualifyingOddsLimit,
						bonusRatios,
						isBoosting,
						boostBets
					);

					singleCount += tempOddsInfoMap.count;
					boostCount += tempOddsInfoMap.boostCount;
					oddsInfoMap.push(tempOddsInfoMap.oddsMap);
					bonus.push(tempOddsInfoMap.bonus);
				}
			});
	}

	return {
		count: singleCount,
		oddsInfoMap,
		bonus,
		boostCount
	};
}

/*
	get system odds info
 */
export function getSysOddsInfoWap(mutexObject, betslip, betslipMap, betslipStake, validKeys) {
	const ret = [];
	const eventKeys = Object.keys(mutexObject),
		bankerMap = betslip.bankerStatus ? betslip.bankerMap : {},
		bankerList = Object.keys(bankerMap),
		bankerCount = bankerList.filter(key => validKeys.indexOf(key) > -1).length;
	const qualifyingOddsLimit = betslipStake.qualifyingOddsLimit;
	const bonusRatios = betslipStake.bonusRatios;

	const betslips = betslip.betslips || [];
	const list = [];
	const numList = [];
	eventKeys.forEach((eventkey, index) => {
		list.push(mutexObject[eventkey].map(gameKey => {
			if (betslips[betslipMap[gameKey]]) {
				return +betslips[betslipMap[gameKey]].outcomeInfo.odds;
			}
			return 0;
		}));
		numList.push(index + 1);
	});
	let bankerOddsInfo = 1;
	// banker中大于1.2的个数
	let bankerBigerNum = 0;
	if (bankerCount) {
		const name = getStakeShowName(bankerCount - 1);
		bankerOddsInfo = bankerList.reduce((sum, cur) => {
			const index = betslipMap[cur];
			if (index > -1 && betslips[index]) {
				const odds = +betslips[index].outcomeInfo.odds;
				if (odds >= qualifyingOddsLimit) {
					bankerBigerNum += 1;
				}
				sum *= odds;
			}
			return sum;
		}, 1);
		// console.log('计算bonus', bankerOddsInfo, bonusRatios[bankerBigerNum]);
		ret.unshift({
			name,
			count: 1,
			bonus: bankerOddsInfo * (bonusRatios[bankerBigerNum] || 0),
			odds: getShowOdds([bankerOddsInfo]),
			min: bankerOddsInfo,
			sum: bankerOddsInfo
		});
	}
	// 算出所有的组合，如果选择胆码，则已经排除胆码
	const combineList = CR2(list, numList);
	const combineKeys = Object.keys(combineList);
	console.log(combineList, combineKeys, bankerOddsInfo, '赔率组合');
	for (const currentNum of combineKeys) {
		let sum = 0;
		let bonus = 0;
		const bonusList = [];
		const oddsInfo = combineList[currentNum].map(cur => { // eslint-disable-line
			const res = (cur.res || 0) * bankerOddsInfo;
			let nf = cur.nf;
			nf += bankerBigerNum;
			if (nf && bonusRatios[nf]) {
				console.log('计算bonus', nf, bonusRatios, bonusRatios[nf], res);
				bonus += bonusRatios[nf] * res;
				bonusList.push(bonusRatios[nf] * res);
			} else {
				bonusList.push(0);
			}
			sum += res;
			return res;
		});
		oddsInfo.sort((a, b) => a - b);
		bonusList.sort((a, b) => a - b);
		console.log('所有的 bonus',bonusList);
		const bonusSet = Array.from(new Set([...bonusList]));
		const name = getStakeShowName(+currentNum + bankerCount - 1);
		const odds = getShowOdds(oddsInfo);
		ret.push({
			name,
			count: combineList[currentNum].length,
			odds,
			bonus,
			min: oddsInfo[0],
			minBonus: bonusSet[0] || 0,
			sum
		});
	}
	return ret;
}

/*
	组合算法
	CR2([[1, 2], [2, 3], [3, 5]], [1, 2, 3])
	返回Ojbect每个值对应每个组合
	每个组合是一个数组，数组有2个属性，res表示乘积的值
	nf表示每个组合大于1.2值得个数
 */

function CR2(arr, num, qualifyingOddsLimit = 1.2) {
	const r = {};
	for (const i of num) {
		r[i] = CR(arr, i, qualifyingOddsLimit);
	}
	return r;
}

function CR(arr, k, qualifyingOddsLimit = 1.2) {
	const result = [];
	const dfs = function (result, list, kLeft, from, to) {
		if (kLeft === 0) {
			let res = 1;
			let nf = 0;
			list.forEach(cur => {
				res *= cur;
				if (cur >= qualifyingOddsLimit) {
					nf += 1;
				}
			});
			result.push({ res, nf });
			return;
		}
		for (let i = from; i <= to; ++i) {
			for (let j = 0; j < arr[i - 1].length; j++) {
				list.push(arr[i - 1][j]);
				dfs(result, list, kLeft - 1, i + 1, to + 1);
				list.pop();
			}
		}
	};
	dfs(result, [], k, 1, arr.length - k + 1);
	return result;
}

// 通过mutexObject获取eventid到marketId再到具体outcome的三级关系， 用来计算同一market下outcome互斥的逻辑，目前仅single订单使用
function getOutcomeMap(mutexObject, betslips, betslipsMap) { 
	const outcomeMap = {};
	let	tmp,
		outcome,
		marketKey;
	mutexObject && Object.keys(mutexObject).forEach(eventId => {
		tmp = {};
		mutexObject[eventId].forEach(key => {
			outcome = betslips[betslipsMap[key]];
			marketKey = `${outcome.sportId}_${outcome.eventId}_${outcome.marketInfo.id}`;
			if (tmp[marketKey]) {
				tmp[marketKey].push(outcome);
			} else {
				tmp[marketKey] = [outcome];
			}
		});
		outcomeMap[eventId] = tmp;
	});
	return outcomeMap;
}

function getMaxOdds (outcomes, qualifyingOddsLimit, bonusRatios, isBoosting, boostBets = {}) { // 多个outcome求最大odds
	const oddsMap = [];
	let bonus = null,
		count = 0,
		boostCount = 0;

	for (const outcome of outcomes) {
		const tmpOdds = +outcome.outcomeInfo.odds;
		let boostItem = isBoosting ? boostBets[outcome.key] : null;

		boostItem = boostItem && boostItem.sptBoost ? boostItem : null;

		oddsMap.push({
			key: outcome.key,
			value: boostItem ? boostItem.boostOdds : tmpOdds
		});

		if (tmpOdds >= qualifyingOddsLimit && bonusRatios[1]) {
			const tempBonus = tmpOdds * bonusRatios[1],
				temp = bonus && bonus.value || 0;

			if (tempBonus > temp) {
				bonus = {
					key: outcome.key,
					value: tempBonus
				};
			}
		}

		if (outcome.outcomeInfo.showOdds) {
			++count;

			boostItem && (++boostCount);
		}
	}

	return bonus ? { oddsMap, bonus, count, boostCount } : { oddsMap, count, boostCount };
}

/*
	get show odds
	获取一个用于显示的odds范围，只能获取 从m个中选取n个这种的 odds的显示范围
 */
function getShowOdds(oddsList = []) {
	if (!oddsList.length) {
		return '';
	}
	const min = oddsList[0],
		sum = oddsList.reduce((total, item) => total += +item, 0),
		odds = min === sum ? `${formatNumber(sum, 2)}` : `${formatNumber(min, 2)} ~ ${formatNumber(sum, 2)}`;

	return odds;
}
