var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-live-event" },
    [
      _vm.isShowTournament
        ? _c(
            "AfTableRow",
            {
              class: [
                "m-content-row",
                "title-row",
                {
                  live: _vm.productId === 1
                }
              ]
            },
            [
              _vm.event.status === 1 || _vm.event.status === 2
                ? [
                    _c("div", { staticClass: "match-status" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.event.matchStatus) +
                          "\n\t\t\t\t\t"
                      )
                    ]),
                    _vm._v(" "),
                    _vm.isShowPlaySec
                      ? _c("div", { staticClass: "clock-time" }, [
                          _vm._v(
                            _vm._s(
                              _vm.useRemainTime
                                ? _vm.event.remainingTimeInPeriod
                                : _vm.event.playedSeconds
                            )
                          )
                        ])
                      : _vm._e()
                  ]
                : _vm.event.status === 0
                ? [_c("div", [_vm._v("Upcoming")])]
                : [
                    _c("div", { staticClass: "match-status" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.event.matchStatus) +
                          "\n\t\t\t\t\t"
                      )
                    ])
                  ],
              _vm._v(" "),
              _c("div", { staticClass: "game-id" }, [
                _vm._v("ID:" + _vm._s(_vm.event.gameId))
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tournament-name",
                  attrs: {
                    title:
                      _vm.event.categoryName + " - " + _vm.event.tournamentName
                  }
                },
                [
                  _vm._v(
                    _vm._s(_vm.event.categoryName) +
                      " - " +
                      _vm._s(_vm.event.tournamentName)
                  )
                ]
              )
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "AfTableRow",
        {
          class: [
            "m-content-row",
            "title-row",
            {
              live: _vm.productId === 1
            }
          ]
        },
        [
          _vm.isShowTournament
            ? _c("div", { staticClass: "title-row--left" })
            : _c(
                "div",
                { staticClass: "title-row--left" },
                [
                  _vm.event.status === 1 || _vm.event.status === 2
                    ? [
                        _c("div", { staticClass: "match-status" }, [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.event.matchStatus) +
                              "\n\t\t\t\t"
                          )
                        ]),
                        _vm._v(" "),
                        _vm.isShowPlaySec
                          ? _c("div", { staticClass: "clock-time" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.useRemainTime
                                    ? _vm.event.remainingTimeInPeriod
                                    : _vm.event.playedSeconds
                                )
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "game-id" }, [
                          _vm._v("ID:" + _vm._s(_vm.event.gameId))
                        ])
                      ]
                    : _vm.event.status === 0
                    ? [_c("div", [_vm._v("Upcoming")])]
                    : [
                        _c("div", { staticClass: "match-status" }, [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.event.matchStatus) +
                              "\n\t\t\t\t"
                          )
                        ])
                      ]
                ],
                2
              ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "title-row--right" },
            _vm._l(_vm.currentMarketIds, function(marketId) {
              return _c("div", { staticClass: "market-xyz" }, [
                _vm._v(_vm._s(_vm.getMarketXYZ(_vm.event, marketId)))
              ])
            })
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "AfTableRow",
        { class: ["m-content-row", "match-row", _vm.sportName + "-row"] },
        [
          _c("AfTableCell", { staticClass: "left-team-cell" }, [
            _c("div", { staticClass: "left-team-table" }, [
              _c(
                "div",
                {
                  staticClass: "teams",
                  attrs: {
                    title:
                      _vm.event.homeTeamName + " vs " + _vm.event.awayTeamName
                  },
                  on: {
                    click: function($event) {
                      _vm.jump(_vm.event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "home-team" }, [
                    _vm._v(_vm._s(_vm.event.homeTeamName))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "away-team" }, [
                    _vm._v(_vm._s(_vm.event.awayTeamName))
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.event.liveChannel
                ? _c("img", {
                    staticClass: "live-icon",
                    attrs: { src: require("./img/tv-color.png") }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "score" },
                _vm._l((_vm.event.setScore || "").split(":"), function(
                  one,
                  index
                ) {
                  return _c("div", { key: index, staticClass: "score-item" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" + _vm._s(one || "") + "\n\t\t\t\t\t"
                    )
                  ])
                })
              ),
              _vm._v(" "),
              _vm.hasGameScore
                ? _c(
                    "div",
                    { staticClass: "game-score" },
                    [
                      _vm.isShowGameScore
                        ? _vm._l(
                            _vm.event.gameScore[
                              _vm.event.gameScore.length - 1
                            ].split(":"),
                            function(one, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "score-item" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(one || "") +
                                      "\n\t\t\t\t\t\t"
                                  )
                                ]
                              )
                            }
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.sportId === "sr:sport:5"
                ? _c(
                    "div",
                    { staticClass: "point" },
                    [
                      _vm.isShowPointScore
                        ? _vm._l(_vm.event.pointScore.split(":"), function(
                            one,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "score-item" },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(one || "") +
                                    "\n\t\t\t\t\t\t"
                                )
                              ]
                            )
                          })
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c(
            "AfTableCell",
            {
              class: [
                "market-cell",
                {
                  "two-markets": _vm.currentMarketIds.length === 2
                }
              ]
            },
            _vm._l(_vm.currentMarketIds, function(marketId, index) {
              return _c("Market", {
                key: index,
                staticClass: "market",
                attrs: {
                  productId: _vm.productId,
                  sportId: _vm.event.sportId || _vm.sportId,
                  marketId: marketId,
                  event: _vm.event,
                  isHideSelectSpecifiers: _vm.isHideSelectSpecifiers(marketId)
                }
              })
            })
          ),
          _vm._v(" "),
          _c(
            "AfTableCell",
            {
              staticClass: "market-size",
              nativeOn: {
                click: function($event) {
                  _vm.jump(_vm.event)
                }
              }
            },
            [
              _vm._v("+" + _vm._s(_vm.event.totalMarketSize)),
              _c("i", { staticClass: "icon-arrow-right" })
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }