import { isEmptyObject, objType } from 'utils';
import { i18n } from 'locale/setup';
import { invokeLogin, invokeResetUser } from 'login/index';
// import { createOrderTips4Coins } from 'store/coins/config';
import { getParams } from './commonFun';

import * as mutationTypes from './mutationTypes';

import '../../../mockData/betslip/betslip?debug';

let timerId = null;
let handleRes = false;
let hasTimeout = false;

export default {
	placeBet({ commit, state, getters, rootState, rootGetters }, payAmount) {
		// 重置数据
		commit(mutationTypes.UPDATEERRORINFO, null);
		commit(mutationTypes.UPDATEORDERINFO, null);
		handleRes = false;

		if (
			!rootState ||
			isEmptyObject(rootState) ||
			!rootState.betslip ||
			isEmptyObject(rootState.betslip)
		) {
			return;
		}

		const isFlexi = rootGetters['betslip/isFlexi'] || false,
			payMethod = rootGetters['betslip/getCurrentPayMethods'],
			params = getParams(
				1,
				rootState.betslip,
				state,
				rootGetters['coupons/checkItem'] || null,
				isFlexi,
				getters.stakeUnion,
				payMethod
			);

		window.__debug__ && console.log(params);

		if (!params) {
			return;
		}

		params.actualPayAmount = payAmount;

		commit(mutationTypes.UPDATEBETLOADING, true);

		return new Promise((resolve, reject) => {
			hasTimeout = false;

			// 超过10s
			timerId = setTimeout(() => {
				clearTimeout(timerId);
				hasTimeout = true;

				if (!handleRes) {
					commit(mutationTypes.UPDATEERRORINFO, {
						title: i18n.t('betslip:pending:title'),
						msg: i18n.t('betslip:pending:msg'),
						optType: 'clear'
					});

					state.betLoading &&
						commit(mutationTypes.UPDATEBETLOADING, false);

					reject({
						timeout: true
					});
				}
			}, 60000);

			const t = new Headers();
			t.append('Content-Type', 'application/json;charset=UTF-8');

			fetch('/order/order', {
				method: 'POST',
				body: JSON.stringify(params),
				headers: t
			})
				.then(res => {
					if (hasTimeout) {
						reject({
							timeout: true
						});
					} else {
						handleRes = true;
						clearTimeout(timerId);

						state.betLoading &&
							commit(mutationTypes.UPDATEBETLOADING, false);

						return res.json();
					}
				})
				.then(data => {
					// 未登录
					if (data.login === false) {
						invokeLogin(() => {
							window.login({ activeTab: 'Login' });
						});
						reject({
							createOrder: false
						});
					}

					const code = data.code;

					switch (code) {
					case 10000:
						commit(
								mutationTypes.UPDATEORDERINFO,
								data.data || null
							);
						invokeResetUser();
						reject({
							createOrder: true
						});
						return;
						// 下注金额不在范围内
					case 4100:
						// 下单被拒
					case 4300:
						commit(mutationTypes.UPDATEERRORINFO, {
							title: i18n.t('betslip:submit:fail'),
							msg: data.message,
							optType: isFlexi ? 'flexibet' : ''
						});
						reject({
							createOrder: false
						});
						return;
					case 4310:
						commit(mutationTypes.UPDATEERRORINFO, {
							msg: data.message,
							optType: 'flexibet'
						});
						break;
						// 余额不足
					case 4200:
						commit(mutationTypes.UPDATEERRORINFO, {
							title: i18n.t('betslip:balance:insufficient'),
							msg: data.message,
							optType: ''
						});
						break;
						// 红包生单失败
					case 4210:
						commit(mutationTypes.UPDATEERRORINFO, {
							title: 'Gift unavailable',
							msg: data.message,
							optType: 'coupons'
						});
						break;
					default:
						commit(mutationTypes.UPDATEERRORINFO, {
							msg: data.message
						});
						break;
					}

					reject({
						createOrder: false
					});
				})
				.catch(err => {
					if (hasTimeout) {
						reject({
							timeout: true
						});
					} else {
						let msg = i18n.t('no:internet:text');
						if (err) {
							if (objType(err) === 'string') {
								msg = err;
							} else if (err.message) {
								msg = err.message;
							}
						}
						commit(mutationTypes.UPDATEERRORINFO, {
							msg
						});
						reject({
							createOrder: false
						});
						clearTimeout(timerId);

						state.betLoading &&
							commit(mutationTypes.UPDATEBETLOADING, false);
					}
				});
		});
	},
	/*
	bonus
	 */
	getBonus({ state, commit }) {
		return new Promise((resolve, reject) => {
			// fetch('/promotion/v1/bonus/plans/valid')  // 这个接口404了
			fetch('/promotion/bonus/list') // 和 wap 使用一样的接口
				.then(res => res.json())
				.then(ret => {
					// const code = ret.code;
					if (ret.code === 10000 && ret.data && ret.data.entityList) {
						commit(
							mutationTypes.UPDATEBONUS,
							ret.data.entityList[0] || []
						);
					}
				})
				.catch(err => {
					// eslint-disable-line
				});
		});
	}
};
