import {
	getSportMarketIds,
	getLiveMarketIds,
} from 'config/sportsMarket.js';
// import { clearBdInfo } from './commonFun';

const state = {
	/* 获取 important match 列表 */
	sport: {},
	/* 获取 live match 列表 */
	live: {},
	// 推荐比赛
	recommendEvents: null,
	recommendLoading: true,
	// 当前支持的market
	getSportMarketIds,
	getLiveMarketIds,
	currentGroup: 0,
	currentLiveGroup: 0,
	// product status
	liveProductStatus: 0,
	sportProductStatus: 0,
	recommendProductStatus: 0,
};

export default state;
