import { render, staticRenderFns } from "./topNav.vue?vue&type=template&id=77fdd58e"
import script from "./topNav.vue?vue&type=script&lang=js"
export * from "./topNav.vue?vue&type=script&lang=js"
import style0 from "./topNav.vue?vue&type=style&index=0&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/admin/Documents/ben_git/acinggaming_fe/fe-ng/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('77fdd58e', component.options)
    } else {
      api.reload('77fdd58e', component.options)
    }
    module.hot.accept("./topNav.vue?vue&type=template&id=77fdd58e", function () {
      api.rerender('77fdd58e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/main/desktop/common/components/layout/pagelet/topNav.vue"
export default component.exports