import { i18n } from 'locale/setup';
import { convertToInternationalCurrencySystem, formatNumber, fixed2Number} from 'utils/index';

function apnm() {
	return ` 
		<div style="margin-bottom: 10px;">
			<p style="font-weight:bold;">${i18n.t('actual:stake:label')}:</p>
			<p>Total Stake - Excise Tax;</p>
			<p style="font-weight:bold;">${i18n.t('potential:win:label')}:</p>
			<p>Actual Stake X Odds;</p>
			<p style="font-weight:bold;">${i18n.t('net:win:label')}:</p>
			<p>Potential Win - WH Tax;</p>
			<p style="font-weight:bold;">${i18n.t('max:pot:win')}: </p>
			<p>${window.showCurrency} ${convertToInternationalCurrencySystem(window.sportsCfg.potWinMax)}</p>
		</div>
		`;
}
function pt() {
	return `<div style="margin-bottom: 10px;">
			<p style="font-weight:bold;">${i18n.t('pot:ex:tax')}: </p>
			<p>${i18n.t('total:stake:label')} x ${fixed2Number(window.taxCfg.stakeTax * 100)}%</p>
		</div>`;
}
function ap() {
	return `
		<div style="margin-bottom: 10px;">
			<p style="font-weight:bold;">${i18n.t('all:wh:tax')}: </p>
			<p>
			(${i18n.t('potential:win:label')} - ${i18n.t('actual:stake:label')}) x ${fixed2Number(window.taxCfg.winTax * 100)}%</p>
		</div>
	`;
}
function m() {
	let potWin = convertToInternationalCurrencySystem(window.sportsCfg.potWinMax);
	if (window.lang === 'zh_CN') {
		potWin = window.sportsCfg.potWinMax;
	}
	return `
			<div style="margin-bottom: 10px;">
				<p style="font-weight:bold;">${i18n.t('max:pot:win')}: </p>
				<p>${window.showCurrency} ${potWin}</p>
			</div>
		`;
}
function mmm() {
	return `<div style="margin-bottom: 10px;">
		<p style="font-weight:bold;">${i18n.t('max:stake:label')}: </p>
		<p>${window.showCurrency} ${formatNumber(window.sportsCfg.max)}</p>
		<p style="font-weight:bold;">${i18n.t('min:stake:label')}: </p>
		<p>${window.showCurrency} ${formatNumber(window.sportsCfg.min)}</p>
		<p style="font-weight:bold;">${i18n.t('max:pot:win')}:</p>
		<p>${window.showCurrency} ${formatNumber(window.sportsCfg.potWinMax, 0)}</p>
		</div>`;
}
/**
 * 弹窗模板 1-totalStake 2-exciseTax
 *
 * key 定义规则 default-类型
 * 		      国家-default-类型
 * 		      国家-op-类型
 */
const stakeTipObj = {
	'default-1': apnm,
	'default-2': pt,
	'default-3': ap,
	'default-4': m,
	'br-default-1': mmm,
	'ng-lottonowngn-1': mmm,
	'ke-lottonowwinspaceksh-1': apnm,
	'id-lottonowioneclubidr-1': mmm,
	'mx-lottonowmoneclubmxn-1': mmm,
	'ar-lottonowarsbetars-1': mmm,
	'my-lottonow12winmyr-1': mmm,
	'trc-lottonowkoousdt-1': mmm,
	'kr-lottonowkonekrw-1': mmm,
	'ph-lottonowponeclubphp-1': mmm,
	'br-lottonowicebrl-1': mmm,
};

function stakeTipTmps(type) {
	const keyCountryOpType = `${window.country}-${window.op}-${type}`;
	const keyCountryDefaultType = `${window.country}-default-${type}`;
	const keyDefaultType = `default-${type}`;
	console.log(keyCountryOpType);
	const keyCountryOpTypeRs = typeof stakeTipObj[keyCountryOpType] === 'function' && stakeTipObj[keyCountryOpType]();
	const keyCountryDefaultTypeRs = typeof stakeTipObj[keyCountryDefaultType] === 'function' && stakeTipObj[keyCountryDefaultType]();
	const keyDefaultTypeRs = typeof stakeTipObj[keyDefaultType] === 'function' && stakeTipObj[keyDefaultType]();
	return keyCountryOpTypeRs || keyCountryDefaultTypeRs || keyDefaultTypeRs;
}

const stakeTipTmp = stakeTipTmps;
export default stakeTipTmp;
