var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading !== 0 && _vm.isLoading !== false
    ? _c("div", { staticClass: "m-loading-wrap" }, [
        _c("div", { class: ["m-loading", _vm.themeName] }, [
          _vm.isLoading !== -1
            ? _c("div", { staticClass: "loading" }, [
                _vm._v(_vm._s(_vm.i18n.t("loading:dot:label")))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isLoading === -1
            ? _c("div", { staticClass: "loading-fail" }, [
                _c("p", { staticClass: "fail-text" }, [
                  _vm._v(_vm._s(_vm.i18n.t("data:load:fail")))
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "fail-btn", on: { click: _vm.fail } },
                  [_vm._v(_vm._s(_vm.i18n.t("load:label")))]
                )
              ])
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }