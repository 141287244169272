import { getSportsConfigValue } from 'config/sports';

export const sportsIcon = {};
export const sportsIconColor = {};
const sportsConfig = getSportsConfigValue();
Object.keys(sportsConfig).forEach(key => {
	const sportFileName = key.toLowerCase();
	const sportId = sportsConfig[key].id;
	sportsIcon[sportId] = require(`./image/${sportFileName}.png`);
	sportsIconColor[sportId] = require(`./image/${sportFileName}-color.png`);
});
export default {
	sportsIcon,
	sportsIconColor
};
