<template lang="html">
	<div>
		<div class="cashout-label">{{getCashoutLabel}} {{cashoutInfo.cashoutAvailable ? showCashoutVal : ''}}</div>
		<div class="cashout-err" v-if="cashoutInfo.errorMsg">{{cashoutInfo.errorMsg}}</div>
		<div class="cashout-err" v-else-if="showErrTips">{{showErrTips}}</div>
		<div class="cashout-msg" v-else-if="+remainStake && isPartial">({{$t('remain:stake:label')}} {{remainStake}})</div>
		<div class="m-slider-bar" v-if="cashoutInfo.isSupportPartial && !cashoutInfo.errorMsg">
			<AfSlider
				v-model="cashoutVal"
				:height="4"
				:dotSize="20"
				:max="max"
				:min="minCashoutAmount"
				:lazy="true"
				:disabled="isSlideDisable"
				/>
			<div class="m-slide-range-label">
				<span>{{$t('min:label')}} {{showMin}}</span>
				<span>{{$t('max:label')}} {{showMax}}</span>
			</div>
		</div>
		<div :class="['commit-wrap', {
			'has-tips': betInfo.combinationNum > 1
			}]">
			<div class="cancel-btn" @click="cancel(betInfo.id)">{{$t('cancel:label')}}</div>
			<af-Button
				class="f-flex-item"
				extraType='primary'
				@click="commitCashout"
				:loading="confirmLoading"
				:disabled="isDisabled"
				>{{confirmLoading ? $t('loading:label') : $t('confirm:label')}}</af-Button>
				<div class="refresh-wrap" @click="refreshDetail">
					<i :class="[{
						'm-icon-refresh': !refreshLoading
						}, {
						'm-icon-loading': refreshLoading
						}]"/>
				</div>
		</div>
		<div class="m-cashout-tips" v-if="betInfo.combinationNum > 1">
			<i class="m-icon-tips"></i><span class="m-tips">{{$t('cashout:partial:text')}}</span>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { EventBus } from 'kernel/js/eventBus.js';
import { formatNumber, isEmptyObject } from 'utils';

import 'packages/button';
import 'packages/slider';

import * as mutationTypes from 'store/cashout/mutationTypes';
import SucPop from './popSuc.vue';

export default {
	name: 'CashoutDetail',

	props: {
		betInfo: {
			type: Object,
			default() {
				return {};
			}
		}
	},

	components: {
	},

	data() {
		return {
			// 显示确认窗
			showConfirm: false,

			cashoutVal: 0,
			showCashoutVal: 0,

			usedStake: 0,
			remainStake: formatNumber(0, 2),
			maxCashout: 0,

			// 完成cashout
			done: false,

			// 显示slider
			showPopOver: false,

			// 显示错误信息
			showErrorTips: false,

			// highlight cashout值
			highlight: false,

			// fefresh loading
			loading4refresh: false,

			// cashout值变化提示
			localErrorTips: '',
			confirmLoading: false,
		};
	},
	computed: {
		...mapState('cashout', [
			'cashoutDetail',
			'cashoutInfo',
			'currentBetId',
			'minCashoutAmount',
			'maxCashOutAmount',
			'errorInfo',
			'refreshLoading',
			'cashoutLoading'
		]),
		isDisabled() {
			return !!(this.showErrTips || this.cashoutInfo.errorMsg);
		},
		showErrTips() {
			const minCashoutAmount = this.minCashoutAmount,
				maxCashOutAmount = this.maxCashOutAmount,
				showCurrency = window.currency,
				cashoutInfo = this.cashoutInfo || {},
				cashoutVal = +cashoutInfo.maxCashOutAmount || 0;

			if (cashoutVal < minCashoutAmount || cashoutVal > maxCashOutAmount) {
				return this.$t('cashout:range:text', [showCurrency, minCashoutAmount, formatNumber(maxCashOutAmount, 0)]);
			}

			return '';
		},
		max() {
			return this.isSlideDisable ? this.maxCashOutAmount : this.maxCashout;
		},
		showMax() {
			return this.max.toFixed(2);
		},
		showMin() {
			return this.minCashoutAmount.toFixed(2);
		},
		isSlideDisable() {
			const minCashoutAmount = this.minCashoutAmount;

			if (this.showErrTips || this.maxCashout === minCashoutAmount) {
				return true;
			}

			return false;
		},
		showError() {
			const errorInfo = this.errorInfo || {};

			return errorInfo.type && errorInfo.type === 'replace' || this.betInfo.showTips;
		},
		getCashoutLabel() {
			if (!this.cashoutInfo.cashoutAvailable) {
				return this.$t('cashout:unavailable:label');
			}

			if (this.isPartial) {
				return this.$t('partial:cashout:label');
			}

			return this.$t('full:cashout:label');
		},
		getTips() {
			const errorInfo = this.errorInfo || {},
				betInfo = this.betInfo;

			let { showTips } = betInfo;

			if (!showTips) {
				showTips = errorInfo.msg;
			}

			return showTips;
		},

		getErrorTips() {
			const errorInfo = this.errorInfo || {};

			if (errorInfo.type === 'inline' || errorInfo.type === 'inline-always') {
				return errorInfo.msg;
			}
		},

		// remain stake只有在滑块显示时才显示
		showRemainStake() {
			return this.showPopOver && this.cashoutInfo.isSupportPartial;
		},

		/*
		格式化显示数据
		 */
		remainStakeShowVal() {
			return this.remainStake && formatNumber(this.remainStake, 2);
		},

		isPartial() {
			const cashoutInfo = this.cashoutInfo || {};
			return cashoutInfo.isSupportPartial ? +this.cashoutVal !== +cashoutInfo.maxCashOutAmount : false;
		},

		getErrorStyle() {
			const errorInfo = this.errorInfo || {},
				colorKey = errorInfo.color ? `m-text--${errorInfo.color}` : '';

			if (colorKey) {
				return [
					'm-text-error',
					colorKey
				];
			}

			return 'm-text-error';
		}
	},
	methods: {
		...mapActions('cashout', {
			requestCashout: 'cashout',
			getBetDetail: 'fetchCashoutDetail'
		}),
		...mapMutations('cashout', {
			refreshErrorInfo: mutationTypes.REFRESHERRORINFO,
			changeBetId: mutationTypes.UPDATEBETID
		}),
		cancel(id) {
			this.changeBetId(id);
		},
		refreshDetail() {
			if (this.loading4refresh) {
				return;
			}

			this.showErrorTips = false;
			this.localErrorTips = '';

			const cashoutInfo = Object.assign(this.cashoutInfo) || {},
				isSupportPartial = cashoutInfo.isSupportPartial || false;

			this.getBetDetail({
				isRefresh: true
			}).then(res => {
				const cashoutInfo = this.cashoutInfo || {},
					cashoutAvailable = cashoutInfo.cashoutAvailable || true,
					cashoutVal = +cashoutInfo.maxCashOutAmount || 0,
					currentPartal = cashoutInfo.isSupportPartial;

				if (cashoutInfo.errorMsg && !this.showError) {
					this.showErrorTips = true;
					this.showPopOver = false;
				}

				// cashout、部分cashout没有展开slider，均显示最新值
				if (!isSupportPartial || !this.showPopOver) {
					// 重置cashout值与显示值
					this.showCashoutVal = cashoutInfo.showCashoutVal;
					this.cashoutVal = cashoutVal;

					// 更新remainstake
					this.remainStake = formatNumber(0, 2);
					this.maxCashout = cashoutVal;
					return;
				}

				// partial( <=100或者---): 收起slider, 提示
				if (this.showPopOver &&
					(!cashoutAvailable || cashoutVal <= this.minCashoutAmount || cashoutVal > this.maxCashOutAmount || !currentPartal)) {
					// 重置cashout值与显示值
					this.showCashoutVal = cashoutInfo.showCashoutVal;
					this.cashoutVal = cashoutVal;

					// 更新remainstake
					this.remainStake = formatNumber(0, 2);
					this.maxCashout = cashoutVal;

					this.showPopOver = false;
					this.showErrorTips = true;

					return;
				}

				// partial( >100 ): 保持slider，按比例重算amount和remain
				if (this.showPopOver && cashoutVal > this.minCashoutAmount) {
					// TODO: 重算amount和remain
					this.calStake();
				}
			});
		},

		handleCashout() {
			if (this.loading4refresh) {
				return;
			}

			this.showErrorTips = false;
			this.localErrorTips = '';

			const cashoutVal = this.cashoutVal;

			// partial(slider展开)：收起slider，显示确认窗
			if (this.showPopOver) {
				this.showPopOver = false;
				this.showConfirm = true;
				return;
			}

			// cashout 或 partial(slider收起)
			if (cashoutVal >= this.minCashoutAmount && cashoutVal <= this.maxCashOutAmount) {
				// >=100: 显示确认窗
				this.showConfirm = true;
			} else {
				// <100或者无金额: 提示
				this.showErrorTips = true;
			}
		},

		cancelCashout() {
			/*
			关闭确认窗，显示新值
			 */
			this.showConfirm = false;
		},

		/*
		toggle滑块状态
		 */
		selectCashout() {
			if (this.loading4refresh) {
				return;
			}

			this.showErrorTips = false;
			this.localErrorTips = '';

			// 不可以cashout，提示，不展开slider
			const cashoutInfo = this.cashoutInfo || {},
				cashoutAvailable = cashoutInfo.cashoutAvailable || true,
				maxCashOutAmount = cashoutInfo.maxCashOutAmount || 0;

			if (!cashoutAvailable ||
				maxCashOutAmount <= this.minCashoutAmount ||
				maxCashOutAmount > this.maxCashOutAmount) {
				this.showErrorTips = true;
				return;
			}

			this.showPopOver = !this.showPopOver;
		},

		commitCashout() {
			const cashoutInfo = Object.assign(this.cashoutInfo) || {},
				isSupportPartial = cashoutInfo.isSupportPartial || false,
				// cashoutVal = this.cashoutVal,
				isPartial = this.isPartial;

			// fetch cashout
			const params = isSupportPartial ?
			{
				usedStake: this.usedStake + '',
				amount: this.cashoutVal + ''
			} :
				null;

			this.showErrorTips = false;
			this.localErrorTips = '';

			this.highlight = false;
			this.confirmLoading = true;
			this.requestCashout(params)
			.then(res => {
				this.confirmLoading = false;
				// 网络错误，收起confirm
				if (res.netError) {
					this.showConfirm = false;
					this.showErrorTips = true;
					return;
				}

				if (this.cashoutInfo.errorMsg && !this.showError) {
					this.showErrorTips = true;
					this.showConfirm = false;
				}

				// const maxCashOutAmount = +res.maxCashOutAmount || 0,
				const remainCount = +res.remainCount || 0;

				// const newCashoutInfo = this.cashoutInfo || {};
					// cashoutAvailable = newCashoutInfo.cashoutAvailable || true;
				if (!this.cashoutInfo.cashoutFaild) {
					this.$dialog({
						title: null,
						css: 'm-openBet--suc',
						content: SucPop,
						contentData: {
							type: 'success',
							isPartial,
							remainCount,
							isTotal: isEmptyObject(res),
							val: isSupportPartial ? params.amount : cashoutInfo.maxCashOutAmount,
						},
						button: []
					}).onClose(() => {
						EventBus.$emit('refresh_cashout_list');
					});
				} else if (this.errorInfo.msg) {
					this.$dialog({
						title: null,
						content: this.errorInfo.msg,
						button: [this.$t('ok:label')]
					});
				}
				// if (isEmptyObject(res)) {
				// 	this.showConfirm = false;
				// 	if (this.errorInfo.msg) {
				// 		this.showErrorTips = true;
				// 		// 更新cashout及showCashoutVal
				// 		this.showCashoutVal = newCashoutInfo.showCashoutVal;
				// 		this.cashoutVal = maxCashOutAmount;
				// 	}
				// 	return;
				// }

				// cashout或者partial的全部cashout： 成功
				// if (newCashoutInfo.done) {
				// 	this.done = true;
				// 	this.showConfirm = false;
				// 	return;
				// }

				// if (isPartial) {
				// 	if (!newCashoutInfo.cashoutFaild) {
				// 		// 更新cashout及showCashoutVal
				// 		this.showCashoutVal = newCashoutInfo.showCashoutVal;
				// 		this.cashoutVal = maxCashOutAmount;
				//
				// 		// partail(剩余次数为0)： ok不删除bet
				// 		if (!remainCount) {
				// 			this.shouldDeleteBet = false;
				// 			this.showConfirm = false;
				// 			return;
				// 		}
				//
				// 		this.maxCashout = maxCashOutAmount;
				// 		this.remainStake = formatNumber(0, 2);
				// 		this.showConfirm = false;
				// 		this.showErrorTips = true;
				// 		return;
				// 	}
				//
				// 	// partail( >本地值：partail值不变，矫正remain stake )
				// 	if (maxCashOutAmount >= cashoutVal && cashoutAvailable && maxCashOutAmount <= this.maxCashOutAmount) {
				// 		// TODO: 矫正remain stake
				// 		this.maxCashout = maxCashOutAmount;
				// 		this.calStake(cashoutVal);
				// 		this.highlight = true;
				// 		return;
				// 	}
				//
				// 	// partail( <本地值，但是>100 | <=100 | cashout不可用 ): 关闭确认窗，提示
				// 	if (!cashoutAvailable ||
				// 		maxCashOutAmount <= this.minCashoutAmount ||
				// 		(maxCashOutAmount > this.minCashoutAmount && maxCashOutAmount < cashoutVal) ||
				// 		maxCashOutAmount > this.maxCashOutAmount) {
				// 		// 更新cashout及showCashoutVal
				// 		this.showCashoutVal = newCashoutInfo.showCashoutVal;
				// 		this.cashoutVal = maxCashOutAmount;
				// 		this.maxCashout = maxCashOutAmount;
				// 		this.remainStake = formatNumber(0, 2);
				//
				// 		this.showConfirm = false;
				// 		this.localErrorTips = 'Partial Cashout amount unavailable, please choose again';
				// 		this.showErrorTips = true;
				//
				// 		return;
				// 	}
				// }

				// 更新cashout及showCashoutVal
				// this.showCashoutVal = newCashoutInfo.showCashoutVal;
				// this.cashoutVal = maxCashOutAmount;

				// 支持部分cashout情况下的cashout
				// this.maxCashout = maxCashOutAmount;
				// this.remainStake = formatNumber(0, 2);

				// cashout( >=100且不等 ): 确认窗显示新值
				// if (maxCashOutAmount >= this.minCashoutAmount && maxCashOutAmount !== cashoutVal) {
				// 	this.highlight = true;
				// 	return;
				// }

				// cashout( <100或者--- ): 不显示确认框，且提示
				// if (!cashoutAvailable ||
				// 	maxCashOutAmount < this.minCashoutAmount ||
				// 	maxCashOutAmount > this.maxCashOutAmount) {
				// 	this.showConfirm = false;
				// 	this.showErrorTips = true;
				// }
			});
		},

		/*
		更新remainStake和usedStake
		 */
		calStake(oldCashoutVal) {
			const cashoutInfo = this.cashoutInfo || {},
				coefficient = +cashoutInfo.coefficient || 0,
				maxCashOutAmount = +cashoutInfo.maxCashOutAmount || 0,
				isSupportPartial = cashoutInfo.isSupportPartial || false,
				cashoutAvailable = cashoutInfo.cashoutAvailable || true,
				maxCashout = this.maxCashout;

			if (!isSupportPartial) {
				return;
			}

			if (!cashoutAvailable) {
				this.usedStake = 0;
				this.remainStake = formatNumber(0, 2);

				return;
			}

			if (maxCashout !== maxCashOutAmount) {
				this.remainSliderPos(maxCashOutAmount, maxCashout);
				this.maxCashout = maxCashOutAmount;
			}

			const cashoutVal = oldCashoutVal || this.cashoutVal;
			if (cashoutVal !== maxCashOutAmount) {
				let stake = cashoutVal / coefficient;

				stake = stake.toFixed(2);

				// 超过最大值，还原为最大值
				stake = Math.min(cashoutInfo.availableStake, stake);
				this.usedStake = stake;
			} else {
				this.usedStake = cashoutInfo.availableStake;
			}

			this.remainStake = (this.betInfo.stake - this.usedStake).toFixed(2);
		},

		remainSliderPos(newMax, oldMax) {
			// 按比例重置cashoutVal的值
			if (oldMax && this.cashoutVal) {
				const ratio = this.cashoutVal / oldMax;

				this.cashoutVal = (newMax * ratio).toFixed(2);

				// 修正显示的cashout值
				this.showCashoutVal = formatNumber(this.cashoutVal, 2);
			}
		}
	},
	watch: {
		/*
		slider显示的情况下，cashoutVal值变化时，更新usedStake和remainStake
		 */
		cashoutVal(val, oldVal) {
			if (true || this.showPopOver) {
				// 重新计算remainStake和usedStake
				this.calStake();

				// 设置显示值
				this.showCashoutVal = formatNumber(val, 2);
			}
		},

		/*
		错误信息显示5s消息
		 */
		showErrorTips(val, oldVal) {
			if (val) {
				const errorInfo = this.errorInfo || {};
				if (errorInfo.type === 'inline-always') {
					return;
				}

				this.$nextTick(() => {
					setTimeout(() => {
						this.showErrorTips = false;

						if (this.localErrorTips) {
							this.localErrorTips = '';
						}

						// 如果是部分cashout，cashout成功，错误提示重新计算
						this.refreshErrorInfo();
					}, 5000);
				});
			}
		},

		/*
		cashout值高亮5s消息
		 */
		highlight(val, oldVal) {
			if (val) {
				this.$nextTick(() => {
					setTimeout(() => {
						this.highlight = false;
					}, 5000);
				});
			}
		},

		/*
		refresh loading
		 */
		refreshLoading(val, oldVal) {
			if (!val && oldVal) {
				setTimeout(() => {
					this.loading4refresh = this.refreshLoading;
				}, 500);
			} else {
				this.loading4refresh = this.refreshLoading;
			}
		}
	},
	mounted() {
		const cashoutInfo = this.cashoutInfo || {},
			maxCashOutAmount = +cashoutInfo.maxCashOutAmount || 0;

		this.cashoutVal = maxCashOutAmount;
		this.showCashoutVal = cashoutInfo.showCashoutVal;
		this.maxCashout = maxCashOutAmount;
		this.usedStake = cashoutInfo.availableStake;
		this.showErrorTips = !!cashoutInfo.errorMsg && !this.showError;
	}
};
</script>

<style lang="less">
@import 'base/style/variable.less';

.f-flex-item {
	&.af-button--primary {
		background: @c09D5DEtoE1FF2F;
		border-color: @c09D5DEtoE1FF2F;
		cursor: pointer;
		font-weight: bold;
		padding: 9px 0;
		font-size: 13px;
	}
}
</style>

<style lang="less" scoped>
@import 'base/style/variable.less';
@import 'base/style/icon.less';

.cashout-label {
	line-height: 22px;
	font-size: 14px;
	color: @c67686Dto9CA0AB;
	font-weight: bold;
	text-align: center;
}
.cashout-err {
	font-size: 12px;
	color: @red;
	line-height: 16px;
	text-align: center;
}
.cashout-msg {
	font-size: 12px;
	color: @pcDark;
	line-height: 22px;
	text-align: center;
}
.m-slider-bar {

}

.commit-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 5px;
	margin-bottom: 9px;
	&.has-tips {
		margin-bottom: 0;
	}
	.cancel-btn {
		cursor: pointer;
		flex: 0 0 auto;
		margin-right: 12px;
		border: 1px solid @c09D5DEtoE1FF2F;
		padding: 0 6px;
		line-height: 30px;
		border-radius: 4px;
		font-size: 12px;
		color: @c09D5DEtoE1FF2F;
	}
	.f-flex-item {
		flex: 1 1 auto;
		margin-right: 9px;
	}
	.m-icon-refresh {
		.m-icon-refresh();
		&::before {
			font-size: 14px;
			color: @c67686Dto9CA0AB;
		}
	}
	.m-icon-loading {
		.m-icon-loading();
	}
}
.m-cashout-tips {
	margin-top: 12px;
	margin-bottom: 9px;
	line-height: 16px;
	font-size: 12px;
	color: @pcDark;
	.m-icon-tips {
		.m-icon-tips();
		line-height: 16px;
		vertical-align: middle;
		&::before {
			font-size: 12px;
			margin-right: 6px;
			color: #DEDEDE;
		}
	}
}
</style>
