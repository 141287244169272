import dateFormat from 'kernel/js/dateFormat';
import { i18n } from 'locale/setup';

export function formatDate(date, regular) {
	return dateFormat.format(date, regular || 'DD/MM/YYYY HH:mm');
}

export function formatWithoutYear(date, regular) {
	return date && dateFormat.format(date, regular || 'DD/MM HH:mm') || '';
}

export function foramtTime(date, regular) {
	return dateFormat.format(date, regular || 'HH:mm:ss');
}

export function getBetStatusDesc(status) {
	const statusDescMap = {
		1: i18n.t('won:label'),
		2: i18n.t('lost:label')
	};
	return statusDescMap[status] || '';
}
export function getSelectionStatusDesc(status) {
	const statusDescMap = {
		0: i18n.t('running:label'),
		1: i18n.t('won:label'),
		2: i18n.t('lost:label'),
		3: i18n.t('void:label'),
		4: i18n.t('void:label'), // 'refund all' 显示成 Void
		5: i18n.t('partial-win:label'),
		6: i18n.t('partial-win:label')
	};
	return statusDescMap[status] || '';
}
export function getCombinationStatusDesc(status) {
	const statusDescMap = {
		0: i18n.t('running:label'),
		1: i18n.t('won:label'),
		2: i18n.t('lost:label'),
		3: i18n.t('void:label'),
		4: i18n.t('won:label'),
	};
	return statusDescMap[status] || '';
}
