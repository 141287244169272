var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["m-market", "m-event-market-table"],
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.handlerStop($event)
        }
      }
    },
    [
      _vm.formatTable
        ? _vm._l(_vm.formatTable, function(row, i) {
            return _c(
              "div",
              { staticClass: "outcome-row" },
              _vm._l(row, function(id, index) {
                return _c(
                  "OutcomeCell",
                  {
                    key: index,
                    class: [
                      "show-title",
                      ,
                      {
                        a2: _vm.isA2Type
                      }
                    ],
                    attrs: {
                      sportId: _vm.sportId,
                      productId: _vm.productId,
                      market: _vm.currentMarket,
                      event: _vm.event,
                      disabled: _vm.currentMarket.productId !== _vm.productId,
                      outcome: _vm.currentMarket.outcomes[id]
                    }
                  },
                  [
                    _c("span", { staticClass: "m-outcome-inner-title" }, [
                      _vm._v(_vm._s(_vm.getDesc(id)))
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        class: [
                          "m-outcome-odds",
                          {
                            "m-icon-lock":
                              _vm.currentMarket.status !== 0 ||
                              _vm.currentMarket.outcomes[id].isActive !== 1
                          }
                        ]
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.currentMarket.status !== 0 ||
                              _vm.currentMarket.outcomes[id].isActive !== 1
                              ? ""
                              : _vm.oddsTrans(
                                  _vm.currentMarket.outcomes[id].odds,
                                  _vm.oddsType
                                )
                          )
                        )
                      ]
                    )
                  ]
                )
              })
            )
          })
        : [
            _vm.currentMarketsOutComeSize
              ? _c(
                  "div",
                  { staticClass: "outcome-row" },
                  _vm._l(_vm.currentMarketsOutComeSize, function(one) {
                    return _c(
                      "div",
                      {
                        key: one,
                        staticClass:
                          "m-outcome--disabled m-outcome m-event-outcome-default m-icon-lock"
                      },
                      [_c("span", { staticClass: "m-icon-lock" })]
                    )
                  })
                )
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }