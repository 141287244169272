var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-dialog-wrapper m-dialog-suc" }, [
    _c("div", { staticClass: "m-pop-header m-pop-header-fix" }, [
      _c("i", { staticClass: "m-icon-suc" }),
      _vm._v(" "),
      _c("span", { staticClass: "m-suc-text" }, [
        _vm._v(_vm._s(_vm.i18n.t("ticket:accept:text")))
      ]),
      _vm._v(" "),
      _c("i", {
        staticClass: "m-icon-close",
        attrs: { "data-action": "close", "data-ret": "close" }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "m-pop-main" }, [
      _c("ul", { staticClass: "m-order" }, [
        _c("li", { staticClass: "m-order-info" }, [
          _c("div", { staticClass: "m-label" }, [
            _vm._v(_vm._s(_vm.i18n.t("total:stake:label")))
          ]),
          _vm._v(" "),
          _vm.contentData.totalStake
            ? _c("div", { staticClass: "m-value" }, [
                _vm._v(_vm._s(_vm.contentData.showTotalStake))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.contentData.showFavorAmount
          ? _c("li", { staticClass: "m-order-info" }, [
              _vm.contentData.favorType === 1
                ? _c("div", { staticClass: "m-label" }, [_vm._v("Cash Gifts")])
                : _c("div", { staticClass: "m-label" }, [
                    _vm._v("Discount Gifts")
                  ]),
              _vm._v(" "),
              _vm.contentData.showFavorAmount
                ? _c("div", { staticClass: "m-value" }, [
                    _vm._v(_vm._s(_vm.contentData.showFavorAmount))
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("li", { staticClass: "m-order-info" }, [
          _c("div", { staticClass: "m-label" }, [
            _vm._v(_vm._s(_vm.i18n.t("potential:win:label")))
          ]),
          _vm._v(" "),
          _vm.contentData.potentialWinnings
            ? _c("div", { staticClass: "m-value" }, [
                _vm._v(_vm._s(_vm.contentData.potentialWinnings))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.contentData.shareCode
          ? _c("li", { staticClass: "m-order-info share-code" }, [
              _c(
                "div",
                { staticClass: "m-label" },
                [
                  _vm._v(
                    _vm._s(_vm.i18n.t("booking:number:label")) + "\n\t\t\t\t\t"
                  ),
                  _vm._v(" "),
                  _c("em"),
                  _vm._v(" "),
                  _c(
                    "PopOver",
                    {
                      attrs: {
                        position: "bottom",
                        isCenter: false,
                        arrowCenter: false,
                        width: "320px"
                      }
                    },
                    [
                      _c("p", [
                        _vm._v(_vm._s(_vm.i18n.t("booking:number:share:text")))
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "m-value" }, [
                _vm._v(_vm._s(_vm.contentData.shareCode)),
                _vm.isHaveCopy
                  ? _c(
                      "i",
                      {
                        ref: "copyShareCode",
                        attrs: {
                          "data-clipboard-text": _vm.contentData.shareCode
                        }
                      },
                      [_vm._v(_vm._s(_vm.i18n.t("copy:label")))]
                    )
                  : _vm._e()
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.contentData.showFavorAmount
        ? _c("p", { staticClass: "cashout-top" }, [
            _vm._v("Note: Cashout unavailable as Gifts have been used.")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "m-btn-wrapper m-ok-wrap" },
        [
          _c(
            "af-button",
            { attrs: { "data-action": "close", "data-ret": "close" } },
            [_vm._v("OK")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.contentData.shareCode
        ? _c(
            "div",
            { staticClass: "m-btn-wrapper m-share-wrap" },
            [
              _c(
                "af-button",
                { staticClass: "rebet", on: { click: _vm.goToShare } },
                [_vm._v(_vm._s(_vm.i18n.t("keep:selection:label")))]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      false
        ? _c("div", { staticClass: "m-btn-text" }, [
            _c(
              "a",
              {
                attrs: { "data-action": "close", "data-ret": "close" },
                on: { click: _vm.handleClick }
              },
              [
                _vm._v(_vm._s(_vm.i18n.t("bet:history:label"))),
                _c("i", { staticClass: "m-icon-more" })
              ]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }