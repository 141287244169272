var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.showSkeleton
    ? _c("div", [
        _vm.recommendEvents &&
        _vm.recommendEvents.events &&
        _vm.recommendProductStatus !== 1
          ? _c("div", { staticClass: "recommend-block" }, [
              _vm.recommendProductStatus !== 1
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading:fetchRecommendEvents",
                          value: _vm.recommendLoading,
                          expression: "recommendLoading",
                          arg: "fetchRecommendEvents"
                        }
                      ],
                      class: ["recommend-events"]
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "recommend-wrap",
                          on: {
                            mouseenter: _vm.disableTimer,
                            mouseleave: _vm.generateTimer
                          }
                        },
                        [
                          _c(
                            "ul",
                            {
                              ref: "nav",
                              staticClass: "recommend-list",
                              style:
                                "transform: translateX(-" + _vm.moveX + "px)"
                            },
                            _vm._l(_vm.recommendEvents.eventOrder, function(
                              eventId
                            ) {
                              return _c("EventRecommend", {
                                key: eventId,
                                attrs: {
                                  event: _vm.recommendEvents.events[eventId]
                                }
                              })
                            })
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              true || _vm.total > 1
                ? _c(
                    "div",
                    { staticClass: "recommend-page" },
                    _vm._l(_vm.total, function(num, i) {
                      return _c("div", {
                        key: i,
                        class: ["page-point", { active: i === _vm.pageNum }],
                        on: {
                          click: function($event) {
                            _vm.goPage(i)
                          }
                        }
                      })
                    })
                  )
                : _vm._e()
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }