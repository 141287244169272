<template>
  <div>
  <template v-if="!showDetailLoading">
  <div
    v-for="(item, i) in cashoutDetail"
    :key="item.id"
    :class="['sel-detail-wrap', {
      last: i === cashoutDetail.length - 1
      }]"
    >
    <div class="flex-row">
      <img class="sport-icon" :src="getSportIcon(item.sportId)">
      <div class="left" :title="`${item.outcomeDesc}@${item.odds}`">{{item.outcomeDesc}}@{{item.odds}}</div>
      <div class="right">{{item.showResult}}</div>
    </div>
    <div class="flex-row">
      <div class="left">{{item.marketDesc}}</div>
      <div class="banker-icon" v-if="item.banker">B</div>
    </div>
    <div class="vs-wrap">
      <span class="date" v-if="!item.isLiveEvent">{{item.showPeriod}}</span>
      <span class="live-icon" v-else>Live</span>
      <span class="vs-text">{{item.home}} - {{item.away}}</span>
    </div>
  </div>
  <div class="detail-option">
    <i :class="{
      'm-icon-refresh': !refreshLoading,
      'm-icon-loading': refreshLoading
      }" @click="refreshDetail">{{$t('refresh:label')}}</i>
    <div class="m-blank-block"/>
    <div class="hide-detail" @click="hideDetail">{{$t('hide:detail:label')}}</div>
  </div>
  </template>
  <div class="detail-loading-wrap" v-else>
    <i class="m-icon-loading">{{$t('loading:label1')}}</i>
  </div>
  <div class="cashout-list-wrap" v-if="betInfo.cashOutHistory && betInfo.cashOutHistory.length > 0">
    <div class="cashout-detail-title">{{$t('cashout:details:label')}}</div>
    <template v-for="cashout in betInfo.cashOutHistory">
      <div class="flex-row">
        <div class="left">{{$t('time:label')}}</div>
        <div class="right">{{cashout.showTime}}</div>
      </div>
      <div class="flex-row">
        <div class="left">{{$t('used:stake:label')}}</div>
        <div class="right">{{cashout.usedStake}}</div>
      </div>
      <div class="flex-row">
        <div class="left">{{$t('cashout:return:label')}}</div>
        <div class="right">{{cashout.amount}}</div>
      </div>
    </template>
  </div>
  <div class="m-bet-money">
    <div class="flex-row seperate">
      <div class="left">{{betInfo.hasCashouted ? $t('remain:stake:label') : $t('stake:label')}}</div>
      <div class="right">{{betInfo.hasCashouted ? $t('remain:pot:win:label') : $t('potential:win:label')}}</div>
    </div>
    <div class="flex-row stake">
      <div class="left">{{betInfo.showStake}}</div>
      <div class="right">{{betInfo.showPotWinnings}}</div>
    </div>
  </div>
</div>
</template>

<script>
import { sportsIconColor } from 'config/sportsIcon';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
	props: {
		betInfo: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	data() {
		return {
			refreshLoading: false
		};
	},
	created() {

	},
	computed: {
		...mapState('cashout', [
			'cashoutDetail',
			'errorInfo',
			'detailLoading',
			'showDetailLoading'
		]),
	},
	methods: {
		...mapActions('cashout', {
			getBetInfo: 'fetchCashoutDetailNoLoading',
		}),
		...mapMutations('cashout', ['updateShowDetail']),
		getSportIcon(sportId) {
			return sportsIconColor[sportId];
		},
		hideDetail() {
			this.updateShowDetail('');
		},
		refreshDetail() {
			this.refreshLoading = true;
			this.getBetInfo({
				id: this.betInfo.id
			}).then(res => {
				this.refreshLoading = false;
			});
		}
	}
};
</script>

<style lang="less">
@import 'base/style/variable.less';
@import 'base/style/icon.less';

.sel-detail-wrap {
  border-bottom: 1px solid @grayTextColor;
  &.last {
    border: none;
  }
  .flex-row {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 22px;
    font-size: 12px;
    color: @c7E7F84toFFFFFF;
    .left {
      flex: 1 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 6px;
    }
    .sport-icon {
      height: 12px;
      width: 12px;
      flex: 0 0 auto;
      margin-right: 3px;
    }
    .banker-icon {
      height: 14px;
      line-height: 14px;
      width: 14px;
      color: @white;
      background: @c09D5DEtoE1FF2F;
      border-radius: 7px;
      text-align: center;
    }
    .right {
    }
  }
  .vs-wrap {
    font-size: 12px;
    color: @c7E7F84to9CA0AB;
    line-height: 22px;
    .date {
      vertical-align: middle;
      margin-right: 6px;
    }
    .live-icon {
      display: inline-block;
      line-height: 14px;
      height: 14px;
      text-align: center;
      vertical-align: middle;
      color: @white;
      padding: 0 3px;
      background: @c09D5DEtoE1FF2F;
      margin-right: 6px;
    }
    .vs-text {
      vertical-align: middle;
    }
  }
}

.detail-option {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  font-size: 12px;
  color: @c7E7F84to9CA0AB;
  .m-icon-refresh {
    cursor: pointer;
    .m-icon-refresh();
    &::before {
      display: inline-block;
      width: 16px;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      margin-right: 6px;
      color: @c67686Dto9CA0AB;
    }
  }
  .m-icon-loading {
    .m-icon-loading();
  	&::before {
  		width: 16px;
  		height: 20px;
      line-height: 20px;
      margin-right: 6px;
  		display: inline-block;
  		font-size: 14px;
  		text-align: center;
  		color: @darkGray;
  		// vertical-align: middle;
  		animation: loading 1.5s infinite linear;
  	}
  }
  .m-blank-block {
    flex: 1 1 auto;
    min-width: 1%;
  }
  .hide-detail {
    margin-left: 6px;
    cursor: pointer;
    color: @c09D5DEtoE1FF2F;
  }
}
.detail-loading-wrap {
  min-height: 80px;
  text-align: center;
  padding-top: 60px;
  .m-icon-loading {
    color: @c7E7F84to9CA0AB;
    font-size: 12px;
    .m-icon-loading();
  	&::before {
  		width: 32px;
  		height: 32px;
      line-height: 32px;
  		display: inline-block;
  		font-size: 14px;
  		text-align: center;
  		color: @darkGray;
  		vertical-align: middle;
  		animation: loading 1.5s infinite linear;
  	}
  }
}
</style>
