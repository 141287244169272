import { i18n } from 'locale/setup';
import { pagePath } from 'config/pageConfig';

export const getTopNavs = () => [{
	title: i18n.t('in-play:label'),
	name: 'In Play',
	link: pagePath.liveOverview,
	// subNav: [{
	// 	title: 'Overview',
	// 	name: 'Overview',
	// 	link: pagePath.liveOverview,
	// }, {
	// 	title: 'Event View',
	// 	name: 'Event View',
	// 	link: pagePath.live,
	// }]
}, {
	title: i18n.t('today:label'),
	name: 'Today',
	link: pagePath.home
}, {
	title: i18n.t('future:label'),
	name: 'Future',
	link: pagePath.preSoccer
}, {
	title: i18n.t('livescore:label'),
	name: 'Live Score',
	link: pagePath.liveScore
}, {
	title: i18n.t('statistics:label'),
	name: 'Statistics',
	link: pagePath.statistics
}, {
	title: i18n.t('result:key'),
	name: 'Result',
	link: pagePath.result
}, {
	title: i18n.t('bet-history:label'),
	name: 'Bet history',
	link: pagePath.sportOrder
}, {
	title: i18n.t('my-transactions:label'),
	name: 'Transactions',
	link: pagePath.transaction
}];

export default {
	getTopNavs
};
