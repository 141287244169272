var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "betslip-setting" }, [
    _c(
      "div",
      { staticClass: "m-flex confirm-setting" },
      [
        _c("RadioButton", {
          staticClass: "left",
          attrs: { isActive: _vm.needConfirm === 1 },
          on: { change: _vm.changeConfirm }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "right" }, [
          _vm._v(_vm._s(_vm.$t("confirm:after:bet:label")))
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "other-setting-header m-flex",
        on: {
          click: function($event) {
            _vm.isHide = !_vm.isHide
          }
        }
      },
      [
        _c("div", { staticClass: "right" }, [
          _vm._v(_vm._s(_vm.$t("other:option:label")))
        ]),
        _vm._v(" "),
        !_vm.isHide
          ? _c("i", { staticClass: "m-icon arrow--up" })
          : _c("i", { staticClass: "m-icon arrow--down" })
      ]
    ),
    _vm._v(" "),
    !_vm.isHide
      ? _c("div", { staticClass: "setting-wrap" }, [
          _c("div", { staticClass: "setting-title" }, [
            _vm._v(_vm._s(_vm.$t("when:odds:change:label")))
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "setting-list" },
            _vm._l(_vm.autoChangeConfig, function(item, i) {
              return _c("li", { key: i, staticClass: "setting-item" }, [
                _c("i", {
                  class: [
                    "m-icon-check",
                    {
                      "m-icon-check--checked":
                        _vm.autoAcceptChange === item.value
                    }
                  ],
                  on: {
                    click: function($event) {
                      _vm.changeAutoAcceptChange(item.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "setting-text" }, [
                  _vm._v(_vm._s(item.text))
                ])
              ])
            })
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }