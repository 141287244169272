<template>
  <div class="m-specifier-list-wrap" ref="container">
    <div class="m-specifier-value" @click="openList" v-if="value">
      <span>{{value.name}}</span>
      <i class="m-icon-arrow-down" v-if="!showList"/>
      <i class="m-icon-arrow-up" v-else/>
    </div>
    <div class="m-overlay" v-if="showList" @click="closeList"/>
    <div :class="['m-list-modal-wrap', {'top': inTop}, {'bottom': !inTop}]" v-if="showList">
      <img class="m-list-edge" v-if="isDark" :src="require('./img/edge-dark.png')">
      <img class="m-list-edge" v-else :src="require('./img/edge-light.png')">
      <ul :class="['m-specifier-list', {
        live: isLive
        }]">
        <li :class="['m-specifier-item', { 'selected': value === specifiers[index] }]" v-for="(row, index) in markets" :key="index">
          <span :class="['specifier-value']" @click="onSelectOutcome(index, true)">{{specifiers[index].name}}</span>
          <OutcomeCell
      			v-for="(id, i) in row.outcomeOrder" :key="i"
      			:sportId="sportId"
      			:market="row"
      			:event="event"
      			:disabled="row.productId !== productId"
      			:outcome="row.outcomes[id]"
            @click="val => onSelectOutcome(index, val)">
      			<!-- suspended显示 -->
      			<span :class="['m-outcome-odds', {
      				'm-icon-lock': row.status !== 0 || row.outcomes[id].isActive !== 1
      			}]">{{row.status !== 0 || row.outcomes[id].isActive !== 1 ? '' : oddsTrans(row.outcomes[id].odds, oddsType)}}</span>
      		</OutcomeCell>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import OutcomeCell from './outcomeCell.vue';

export default {
	components: {
		OutcomeCell
	},
	props: {
		value: {
			type: Object
		},
		markets: {
			type: Array,
			'default': []
		},
		specifiers: {
			type: Array
		},
		sportId: {
			type: String
		},
		event: {
			type: Object
		},
		productId: {
			type: [String, Number]
		}
	},
	data() {
		return {
			showList: false,
			inTop: false // specifier列表是否在上方弹出
		};
	},
	computed: {
		...mapState('betslip', ['oddsType']),
		currentMarket() {
			return this.markets[this.value];
		},
		isLive() {
			return +this.productId === 1;
		},
		isDark() {
			return +this.productId === 1 || window.theme === 'theme-dark';
		}
	},
	methods: {
		openList() {
			this.showList = true;
		},
		closeList() {
			this.showList = false;
		},
		onSelectOutcome(index, val) {
			if (val) {
				this.$emit('input', this.specifiers[index]);
				this.closeList();
			}
		}
	}
};
</script>

<style lang="less">
@import '../../base/style/variable.less';
@import '../../base/style/icon.less';

.m-specifier-list-wrap {
  width: 100%;
  text-align: center;
  .m-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
  .m-specifier-value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4px;
    .m-icon-arrow-up {
      .m-icon-arrow--up();
      &::before {
        font-size: 12px;
      }
    }
    .m-icon-arrow-down {
      .m-icon-arrow--down();
      &::before {
        font-size: 12px;
      }
    }
  }
  .m-list-modal-wrap {
    position: absolute;
    left: 0;
    width: 100%;
    border: 1px solid @darkGrayTextColor;
    z-index: 10;
    background: @WhiteDark;
    &.top {
      bottom: 40px;
    }
    &.bottom {
      top: 40px;
    }
    .m-list-edge {
      position: absolute;
      width: 100%;
      height: 14px;
      left: 0;
      bottom: 0;
      z-index: 50;
    }
    .m-specifier-list {
      max-height: 250px;
      overflow-y: auto;
      list-style: none;
      padding-bottom: 6px;
      .m-specifier-item {
        padding: 6px 12px;
        line-height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        div:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        div:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          margin-right: 0;
        }
        div {
          margin-right: 1px;
        }
        .specifier-value {
          color: @darkGrayTextColor;
          flex: 1;
          min-width: 1%;
        }
        &.selected {
          background-color: @whiteNormalGraySelectBgColor;
          .specifier-value {
            color: @c09D5DEtoE1FF2F;
          }
        }
      }

      &.live {
        background: @liveDark;
        .m-specifier-item {
          &.selected {
            background-color: #626973;
            .specifier-value {
              color: @c09D5DEtoE1FF2F;
            }
          }
        }
      }
    }
  }
}
</style>
