/**
 * 根据传入的time参数处理成给后台的time参数
 */
export function getTimeParam (timeData) {
	const param = {};
	if (timeData && timeData.value) {
		const value = timeData.value;
		if (value === 'today') {
			const date = new Date();
			const todayEndTime = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 1, 4)); // 写死第二天中午十二点
			param.startTime = date.getTime();
			param.endTime = todayEndTime.getTime();
			return param;
		}
		if (value === 'future') {
			const date = new Date();
			const todayEndTime = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 1, 4)); // 写死第二天中午十二点
			param.startTime = todayEndTime.getTime();
			return param;
		}
		if (value === 'all') {
			return param;
		}
		if (value.indexOf('h') > -1) { // n小时之内
			const date = new Date();
			const hours = +value.split('h')[0];
			const todayEndTime = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 1, 4)); // 写死第二天中午十二点
			param.startTime = date.getTime();
			param.endTime = date.getTime() + hours * 60 * 60 * 1000;
			if (param.endTime > todayEndTime.getTime()) { // 最多筛选到第二天十二点，
				param.endTime = todayEndTime.getTime();
			}
			return param;
		}
		if (value.indexOf('d') > -1) { // 天
			const date = new Date();
			const days = +value.split('d')[0];
			const dayStart = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + days, 4));
			const dayEnd = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + days + 1, 4));
			param.startTime = dayStart.getTime();
			param.endTime = dayEnd.getTime();
			return param;
		}
	}
	return param;
}

export default {
	getTimeParam
};
