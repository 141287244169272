const showOpMap = {
	lottonow: "AcingGaming"
};
const uppercaseOpMap = {
	acinggaming: "ACING GAMING"
};

const siteOpMap = {

};
// 主题设置
const themeSetting = {
	'default': 'theme-default',
	'dark': 'theme-dark'
};
// 头部配置
const headShowSetting = {
	'lottonowicebrl': false,
	'lottonowkoousdt': false,
	'lottonowkonekrw': false,
	'lottonowponeclubphp': false,
	'lottonowmoneclubmxn': false,
	'lottonow12winmyr': false,
	'lottonowwinspaceksh': false,
	'lottonowioneclubidr': false,
	'lottonowarsbetars': false,
	'tidemo': true,
}
// 是否展示常规底部betslip/cashout
const BeCaShowSetting = {
	'lottonowicebrl': false,
	'lottonowkoousdt': false,
	'lottonowkonekrw': false,
	'lottonowponeclubphp': false,
	'lottonowmoneclubmxn': false,
	'lottonow12winmyr': false,
	'lottonowwinspaceksh': false,
	'lottonowioneclubidr': false,
	'lottonowarsbetars': false,
	'tidemo': false,
}

// 是否是骨架屏
const skeletonLoadingSetting = {
	'lottonowicebrl': true,
	'lottonowkoousdt': true,
	'lottonowkonekrw': true,
	'lottonowponeclubphp': true,
	'lottonowmoneclubmxn': true,
	'lottonow12winmyr': true,
	'lottonowwinspaceksh': true,
	'lottonowioneclubidr': true,
	'lottonowarsbetars': true,
	'tidemo': true,
}

// Quick Bet展示规则 - 当BeCaShowSetting 为false时生效
// one  => Quick Bet = On，则和竞品1的流程一致 blaze.com 0->1 show quick
// two => Quick Bet = Off，则和竞品2的流程一致 stake.com 0->1 un show quick
const QuickBetSetting = {
	'lottonowicebrl': 'one',
	'lottonowkoousdt': 'one',
	'lottonowkonekrw': 'one',
	'lottonowponeclubphp': 'one',
	'lottonowmoneclubmxn': 'one',
	'lottonow12winmyr': 'one',
	'lottonowwinspaceksh': 'one',
	'lottonowioneclubidr': 'one',
	'lottonowarsbetars': 'one',
	'tidemo': 'one',
}

// 返回头部图片
const TopImgSetting = {
	'lottonowicebrl': 'scroll-top-dark.png',
	'lottonowkoousdt': 'scroll-top-dark.png',
	'lottonowkonekrw': 'scroll-top-dark.png',
	'lottonowponeclubphp': 'scroll-top-dark.png',
	'lottonowmoneclubmxn': 'scroll-top-dark.png',
	'lottonow12winmyr': 'scroll-top-dark.png',
	'lottonowwinspaceksh': 'scroll-top-dark.png',
	'lottonowioneclubidr': 'scroll-top-dark.png',
	'lottonowarsbetars': 'scroll-top-dark.png',
	'tidemo': 'scroll-top-dark.png',
}
/**
 * 根据路由参数和域名判断当前op（operator）
 * @returns string
 */
function getOp() {
	let op = "";
	const urlParams = new URLSearchParams(window.location.search);
	if (urlParams.has("op")) {
		op = urlParams.get("op");
	} else if (sessionStorage.getItem("op")) {
		op = sessionStorage.getItem("op");
	}
	op = op || "acinggaming";
	if (op) {
		window.op = op;
		sessionStorage.setItem("op", op);
	}
	let theme = 'default'
	if (urlParams.has("theme")) {
		theme = urlParams.get("theme")
		sessionStorage.setItem("theme", theme);
	} else if (sessionStorage.getItem("theme")) {
		theme = sessionStorage.getItem("theme");
	}
	window.upOp = uppercaseOpMap[op] || 'ACING GAMING';
	window.siteOp = siteOpMap[op] || '';
	window.theme = themeSetting[theme] || 'theme-default';
	window.headShow = headShowSetting.hasOwnProperty(op) ? headShowSetting[op] : true;
	window.becaShow = BeCaShowSetting.hasOwnProperty(op) ? BeCaShowSetting[op] : true; // 是否展示Betslip / Cashout
	window.skeletonShow = skeletonLoadingSetting.hasOwnProperty(op) ? skeletonLoadingSetting[op] : false; // 是否是骨架屏
	window.quickBetType = QuickBetSetting.hasOwnProperty(op) ? QuickBetSetting[op] : 'one'; // Quick Bet展示规则
	window.scrollTopImg = TopImgSetting.hasOwnProperty(op) ? TopImgSetting[op] : 'scroll-top.png'; // scrollTop 图片
	window.agent = 'pc'
	return op;
}
const opConfig = getOp();
export default opConfig;
export const showOp = showOpMap[opConfig] || "AcingGaming";
