var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-winning-wrapper" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "m-pop-main" }, [
      _vm.contentData.showWinnings
        ? _c("div", { staticClass: "m-money-wrapper" }, [
            _c("div", { staticClass: "m-currency" }, [
              _vm._v(_vm._s(_vm.showCurrency))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "m-money" }, [
              _vm._v(_vm._s(_vm.contentData.showWinnings))
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "m-pop-bottom" }, [
      _c("div", { staticClass: "m-desc-wrapper" }, [
        _c("span", [
          _vm._v(
            "From " +
              _vm._s(_vm.contentData.sportType) +
              " ／Ticket ID " +
              _vm._s(_vm.contentData.shortId)
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "m-btn-wrapper" },
        [
          _c(
            "af-Button",
            {
              attrs: {
                extraType: "text",
                "data-action": "close",
                "data-ret": "close"
              },
              on: { click: _vm.goDetail }
            },
            [_vm._v("Details")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-pop-header" }, [
      _c("i", {
        staticClass: "m-icon-close",
        attrs: { "data-action": "close", "data-ret": "close" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }