<template>
	<div class="m-sport-event">
		<AfTableRow :class="['m-content-row', 'title-row', {
			'live': productId === 1
			}] ">
			<div class="clock-time" v-if="isShowDate">{{event.monthDate}} {{event.hourMinute}}</div>
			<div class="clock-time" v-else>{{event.hourMinute}}</div>
			<div class="game-id">ID: {{event.gameId || ""}}</div>
			<div class="tournament-name" v-if="isShowTournament">{{event.categoryName}} - {{event.tournamentName}}</div>
		</AfTableRow>
		<AfTableRow class="m-content-row match-row">
			<AfTableCell class="left-team-cell">
				<div class="left-team-table">
					<div class="teams" @click="jump(event)" :title="event.homeTeamName + ' vs ' + event.awayTeamName">
						<!-- 直播比赛显示 当比赛状态是upcoming时显示 upcoming 否则不现实，赛前比赛显示gameId-->
						<div class="home-team">{{event.homeTeamName}}</div>
						<div class="away-team">{{event.awayTeamName}}</div>
					</div>
					<div class="icon-wrap">
						<img class="inplay-icon" v-if="isShowInplay" :src="require('./img/inplay.png')">
						<img class="prelive-icon" v-if="isShowTV" :src="require('./img/tv.png')">
					</div>
				</div>
			</AfTableCell>
			<!-- market显示 -->
			<!-- sportId获取做一个兼容 -->
			<!-- 如果是直播比赛，并且是第一行就显示title -->
			<AfTableCell :class="[
					'market-cell',
					{
						'two-markets': currentMarketIds.length === 2,
					}
				]">
				<!--这里Market组件使用index性能优化-->
				<Market v-for="(marketId, index) in currentMarketIds" :key="index"
					:productId="productId"
					:sportId="event.sportId || sportId"
					:marketId="marketId"
					:event="event"
					:isHideSelectSpecifiers="isHideSelectSpecifiers(marketId)"
					class="market"
					>
				</Market>
			</AfTableCell>
			<AfTableCell @click.native="jump(event)" class="market-size">+{{event.totalMarketSize}}<i class="icon-arrow-right"/></AfTableCell>
		</AfTableRow>
	</div>
</template>

<script>
	/**
	 * 针对一个events的列表做显示
	 */
	import { AfTable, AfTableRow, AfTableCell } from 'packages/tableLayout';
	import { getSportConfigLowerCase, trackerSports } from 'config/sportsType';
	import { baseUrl } from 'config/pageConfig';
	// import { marketConfig } from 'config/marketConfig';
	import Market from './market';

	export default {
		components: {
			Market,
			AfTable,
			AfTableRow,
			AfTableCell
		},
		props: {
			// 里面有所有的比赛的map，应该与eventOrder相匹配
			event: {
				required: true,
				type: Object,
				default () {
					return {};
				}
			},
			// 当前需要显示的marketId数组-因为后台可能传递多个market，我们不一定显示，我们这里定义要显示的market
			currentMarketIds: {
				required: true,
				type: Array
			},
			sportId: {
				type: String
			},
			// 1(直播),3(赛前)
			productId: {
				type: [String, Number],
				required: true
			},
			eventId: String,
			isShowTournament: {
				type: Boolean,
				'default': true
			},
			isShowDate: {
				type: Boolean,
				'default': true
			}
		},
		computed: {
			isShowInplay() {
				return this.event.bookingStatus && this.event.bookingStatus === 'Booked';
			},
			isShowTV() {
				return this.event && this.event.liveChannel;
			},
			isH2H() {
				const trackers = trackerSports.map(item => `sr:sport:${item}`);
				return trackers.includes(this.event.sportId);
			},
			sportConfigLowerCase() {
				return getSportConfigLowerCase();
			}
		},
		methods: {
			isHideSelectSpecifiers (marketId) {
				// 足球为36的market不显示下拉列表选择
				if (this.sportId === 'sr:sport:1' && +marketId === 36) {
					return true;
				}
				return false;
			},
			// 跳转到对应的比赛详情页面
			jump (event) {
				// ${baseUrl}sport/:sportName/:categoryId/:tournamentId/:eventId/
				const sportName = this.sportConfigLowerCase[event.sportId.slice(9)];
				let url = `${baseUrl}sport/${sportName}/${event.categoryId}/${event.tournamentId}/${event.eventId}`;
				if (this.productId === 1) {
					url = `${baseUrl}sport/${sportName}/live/${event.categoryId}/${event.tournamentId}/${event.eventId}`;
					if (event.status === 0) {
						url = `${baseUrl}sport/${sportName}/${event.categoryId}/${event.tournamentId}/${event.eventId}`;
					}
				}
				window.location.href = url;
			},
			// 切换head to head 隐藏or显示
			handleToggleH2h() {
				this.$emit('toggleH2h', this.eventId);
			}
		}
	};
</script>

<style lang="less" scoped>
@import '~base/style/variable.less';
@import '~base/style/mixin.less';
@import 'base/style/icon.less';

.m-sport-event {
	border-bottom: 1px solid @lightGrayDarkGray;
	padding: 9px 0;
	
	.market-size {
		cursor: pointer;
		text-align: right;
		.icon-arrow-right {
			.m-icon-arrow--right();
			&:before {
				font-size: 12px;
				margin-left: 2px;
			}
		}
	}
}
.title-row {
	margin-bottom: 6px;
	font-size: 12px;
	line-height: 16px;
	color: @lightGrayLightWhite;
	&.live {
		color: @e9Gray;
	}
	.clock-time {
		flex: 0 0 auto;
		margin-right: 12px;
	}
	.game-id {
		margin-right: 12px;
		white-space: nowrap;
	}
	.tournament-name {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin-right: 6px;
	}
}

.left-team-table {
	display: flex;
	align-items: center;
	justify-content: center;
	.teams {
		flex: 1 1 auto;
		overflow: hidden;
		margin-right: 6px;
		div {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			text-align: left;
		}
	}
	.icon-wrap {
		flex: 0 0 auto;
		margin-right: 12px;
		.inplay-icon, .prelive-icon {
			display: block;
			height: 16px;
		}
	}
	.lmt-icon {
		flex: 0 0 auto;
	}
}
</style>

<style lang="less">
@import '~base/style/variable.less';
	.m-sport-event {
		.m-market {
			.m-outcome-specifier, .m-outcome {
				&.m-outcome--disabled {
					//background: #E4E4E4;
					//color: #989898;
					background: @outcomeLockBgColor;
					color: @outcomeLockColor;
				}
			}
		}
	}
</style>
