<template>
  <div class="m-radio">
    <div :class="['m-radio-wrap', {
      active: isActive
      }]" @click="toggle">
      <div class="m-radio-btn"/>
    </div>
</div>
</template>

<script>
export default {
	props: {
		isActive: {
			type: Boolean,
			'default': false
		}
	},
	methods: {
		toggle() {
			this.$emit('change', !this.isActive);
		}
	}
};
</script>

<style lang="less">
@import 'base/style/variable.less';

.m-radio {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
}
.m-radio-wrap {
  // position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 14px;
  background: @white;
  border: 1px solid @c09D5DEtoF5D37E;
  border-radius: 7px;
  padding: 0 1px;

  .m-radio-btn {
    display: inline-block;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    border: 1px solid @c09D5DEtoF5D37E;
  }
  &.active {
    background: @c09D5DEtoF5D37E;
    text-align: left;
    justify-content: flex-end;

    .m-radio-btn {
      border-color: @c09D5DEtoF5D37E;
      background: @white;
    }
  }
}
</style>
