// import { isEmptyObject } from 'utils';
import { convertEventsToMap, updateMarketOutCome, updateEventByEvt } from 'components/eventList/util';
import clearPushData from 'store/eventDetail/clearPushData';
import clearTopicInfo from 'store/eventDetail/clearTopicInfo';
import * as mutationTypes from './mutationTypes';

export default {
	// 获取推荐比赛
	[mutationTypes.FETCH_RECOMMEND_EVENTS](state, data = {}) {
		if (data && data.events && data.events.length) {
			const events = data.events.filter(x => [0, 1, 2].includes(x.status));
			const res = convertEventsToMap(events);
			state.recommendEvents = Object.assign({}, data, {
				events: res.map,
				eventOrder: res.originOrder,
				eventSize: data.eventSize // 注意：这里必须要用后台返回的字段，不能默认用 events.length来补充
			});
		}
	},
	// 获取 important match 列表
	[mutationTypes.UPDATE_IMPORTANT_MATCH](state, data) {
		if (!data) {
			return;
		}
		const events = { order: {}, map: {}};
		convertEventsToMap(data, events);
		state.sport = {
			events: events.map,
			eventOrder: events.order
		};
	},
	[mutationTypes.UPDATE_RECOMMEND_EVENT](state, data = []) {
		const eventInfo = clearPushData(data, 'event');
		const topicInfo = clearTopicInfo(data.topic, 'event');

		const recommendEvents = state.recommendEvents;
		const { events = {}, eventOrder = [] } = recommendEvents;

		// 由于这里可能是preMatch/live，所以手动判断状态删除
		if (eventInfo.status !== 0 && eventInfo.status !== 1 && eventInfo.status !== 2) {
			const eventId = topicInfo.eventId;
			Vue.delete(events, eventId);

			const index = eventOrder.findIndex(cur => cur === eventId);
			if (index > -1) {
				eventOrder.splice(index, 1);
			}
		} else {
			let productId = 3;
			if (eventInfo.status === 1 || eventInfo.status === 2) {
				productId = 1;
			}

			updateEventByEvt(events, eventOrder, eventInfo, topicInfo, productId, false, true);
		}
	},
	[mutationTypes.UPDATE_RECOMMEND_MARKET](state, data = []) {
		const pushMarketInfo = clearPushData(data, 'market');
		const topicInfo = clearTopicInfo(data[0], 'market');
		const eventId = topicInfo.eventId;

		let marketId = topicInfo.marketId;

		if (!marketId) {
			return;
		}
		marketId = marketId.split('?');
		marketId = marketId[0];

		const recommendEvents = state.recommendEvents;
		if (recommendEvents && recommendEvents.events) {
			const events = recommendEvents.events;
			if (events) {
				const event = events[eventId];
				if (event) {
					const market = event.markets ? event.markets[marketId] : undefined;
					updateMarketOutCome(event, market, pushMarketInfo, topicInfo);
				}
			}
		}
	},
	[mutationTypes.UPDATE_RECOMMEND_ODDS](state, data = []) {
		const pushMarketInfo = clearPushData(data, 'market');
		const topicInfo = clearTopicInfo(data[0], 'market');
		const pushOutcomeInfos = clearPushData(data, 'odds') || {};

		const eventId = topicInfo.eventId;
		let marketId = topicInfo.marketId;
		if (!marketId) {
			return;
		}
		marketId = marketId.split('?');
		marketId = marketId[0];

		const recommendEvents = state.recommendEvents;
		if (recommendEvents && recommendEvents.events) {
			const events = recommendEvents.events;
			if (events) {
				const event = events[eventId];
				if (event) {
					const market = event.markets ? event.markets[marketId] : undefined;
					updateMarketOutCome(event, market, pushMarketInfo, topicInfo, pushOutcomeInfos);
				}
			}
		}
	},
	// 获取 live match 列表
	[mutationTypes.UPDATE_LIVE_MATCH](state, data) {
		// 此处最多显示10场比赛，但是后台跟live的列表页面公用一个接口所有数据
		if (!data) {
			return;
		}
		const events = { order: {}, map: {}};
		convertEventsToMap(data, events);
		state.live = {
			events: events.map,
			eventOrder: events.order
		};
	},
	// sport push更新event
	[mutationTypes.UPDATE_SPORT_EVENT] (state, data = []) {
		const eventInfo = clearPushData(data, 'event');
		const topicInfo = clearTopicInfo(data.topic, 'event');
		const sport = state.sport;
		if (!sport.eventOrder) {
			Vue.set(sport, 'eventOrder', {});
		}
		if (!sport.events) {
			Vue.set(sport, 'events', {});
		}
		// 不可以插入比赛
		updateEventByEvt(sport.events, sport.eventOrder, eventInfo, topicInfo, 1, false, true);
	},
	// sport push 更新market
	[mutationTypes.UPDATE_SPORT_MARKET] (state, data = []) {
		const pushMarketInfo = clearPushData(data, 'market');
		const topicInfo = clearTopicInfo(data[0], 'market');
		const sport = state.sport;
		const eventId = topicInfo.eventId;
		let marketId = topicInfo.marketId;
		if (!marketId) {
			return;
		}
		marketId = marketId.split('?');
		marketId = marketId[0];
		if (sport && sport.events) {
			const events = sport.events;
			if (events) {
				const event = events[eventId];
				if (event) {
					const market = event.markets ? event.markets[marketId] : undefined;
					updateMarketOutCome(event, market, pushMarketInfo, topicInfo);
				}
			}
		}
	},
	// sport push 更新outcome
	[mutationTypes.UPDATE_SPORT_OUTCOME] (state, data = []) {
		const pushMarketInfo = clearPushData(data, 'market');
		const topicInfo = clearTopicInfo(data[0], 'market');
		const pushOutcomeInfos = clearPushData(data, 'odds') || {};
		const sport = state.sport;
		const eventId = topicInfo.eventId;
		let marketId = topicInfo.marketId;
		if (!marketId) {
			return;
		}
		marketId = marketId.split('?');
		marketId = marketId[0];
		if (sport && sport.events) {
			const events = sport.events;
			if (events) {
				const event = events[eventId];
				if (event) {
					const market = event.markets ? event.markets[marketId] : undefined;
					updateMarketOutCome(event, market, pushMarketInfo, topicInfo, pushOutcomeInfos);
				}
			}
		}
	},
	// live push更新event
	[mutationTypes.UPDATE_LIVE_EVENT] (state, data = []) {
		const eventInfo = clearPushData(data, 'event');
		const topicInfo = clearTopicInfo(data.topic, 'event');
		const live = state.live;
		if (!live.eventOrder) {
			Vue.set(live, 'eventOrder', {});
		}
		if (!live.events) {
			Vue.set(live, 'events', {});
		}
		// 不可以插入比赛
		updateEventByEvt(live.events, live.eventOrder, eventInfo, topicInfo, 1, false, true);
	},
	// live push 更新market
	[mutationTypes.UPDATE_LIVE_MARKET] (state, data = []) {
		const pushMarketInfo = clearPushData(data, 'market');
		const topicInfo = clearTopicInfo(data[0], 'market');
		const live = state.live;
		const eventId = topicInfo.eventId;
		let marketId = topicInfo.marketId;
		if (!marketId) {
			return;
		}
		marketId = marketId.split('?');
		marketId = marketId[0];
		if (live && live.events) {
			const events = live.events;
			if (events) {
				const event = events[eventId];
				if (event) {
					const market = event.markets ? event.markets[marketId] : undefined;
					updateMarketOutCome(event, market, pushMarketInfo, topicInfo);
				}
			}
		}
	},
	// live push 更新outcome
	[mutationTypes.UPDATE_LIVE_OUTCOME] (state, data = []) {
		const pushMarketInfo = clearPushData(data, 'market');
		const topicInfo = clearTopicInfo(data[0], 'market');
		const pushOutcomeInfos = clearPushData(data, 'odds') || {};
		const live = state.live;
		const eventId = topicInfo.eventId;
		let marketId = topicInfo.marketId;
		if (!marketId) {
			return;
		}
		marketId = marketId.split('?');
		marketId = marketId[0];
		if (live && live.events) {
			const events = live.events;
			if (events) {
				const event = events[eventId];
				if (event) {
					const market = event.markets ? event.markets[marketId] : undefined;
					updateMarketOutCome(event, market, pushMarketInfo, topicInfo, pushOutcomeInfos);
				}
			}
		}
	},
	// -1 表示加载出错， true表示加载中，false表示加载完成
	[mutationTypes.RECOMMEND_LOADING](state, data = false) {
		state.recommendLoading = data;
	},
	// 更新推荐比赛的productStatus
	[mutationTypes.UPDATE_RECOMMEND_PRODUCT_STATUS] (state, data = 0) {
		state.recommendProductStatus = data;
	},
	// 更新premath的productStatus
	[mutationTypes.UPDATE_SPORT_PRODUCT_STATUS] (state, data = 0) {
		state.sportProductStatus = data;
	},
	// 更新live的productStatus
	[mutationTypes.UPDATE_LIVE_PRODUCT_STATUS] (state, data = 0) {
		state.liveProductStatus = data;
	},

	[mutationTypes.UPDATE_CURRENT_GROUP] (state, data = '') {
		state.currentGroup = data;
	},

	[mutationTypes.UPDATE_CURRENT_LIVE_GROUP] (state, data = '') {
		state.currentLiveGroup = data;
	},
};
