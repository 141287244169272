var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-coupon-wrapper" },
    [
      _c(
        "div",
        { staticClass: "m-coupon-title", on: { click: _vm.togglePack } },
        [
          _c("span", { staticClass: "m-label" }, [
            _vm._v("Gifts "),
            _c("span", { class: _vm.getShowDesc.class }, [
              _vm._v("(" + _vm._s(_vm.getShowDesc.text) + ")")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "m-value" }, [
            _c(
              "span",
              { staticClass: "m-text", class: _vm.getUsedText.class },
              [_vm._v(_vm._s(_vm.getUsedText.text))]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "m-icon",
                  {
                    "m-icon-pack": _vm.isPack,
                    "m-icon-unpack": !_vm.isPack
                  }
                ]
              },
              [
                _c(
                  "div",
                  {
                    class: [
                      "g-pop-tips",
                      {
                        on: _vm.showGiftTips
                      }
                    ]
                  },
                  [_c("p", [_vm._v(_vm._s(_vm.giftsTips))])]
                )
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      !_vm.isPack
        ? [
            _c("div", { staticClass: "m-coupon-opt" }, [
              _vm.showCashoutTips
                ? _c("p", { staticClass: "m-coupon-tips" }, [
                    _vm._v("Cashout unavailable with bets using gifts")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isLogin
                ? _c(
                    "div",
                    {
                      class: [
                        "m-refresh",
                        {
                          "is-disabled": _vm.loading
                        }
                      ],
                      on: { click: _vm.handleRefresh }
                    },
                    [_vm._v("Refresh")]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.hasData ? _c("CouponsList") : _c("CouponsError")
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }