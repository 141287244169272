var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-main m-layout-main" },
    [
      _c("div", { staticClass: "m-main-top" }, [_vm._t("top")], 2),
      _vm._v(" "),
      _c(
        "AfTable",
        { staticClass: "m-main-bottom" },
        [
          _c(
            "AfTableRow",
            [
              _vm.hasLeft
                ? _c(
                    "AfTableCell",
                    { staticClass: "m-main-left", style: _vm.getLeftStyle },
                    [_vm._t("left")],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "AfTableCell",
                { staticClass: "m-main-mid", style: _vm.getMainStyle },
                [_vm._t("mid", [_c("router-view")])],
                2
              ),
              _vm._v(" "),
              _vm.hasRight
                ? _c(
                    "AfTableCell",
                    { staticClass: "m-main-right", style: _vm.getRightStyle },
                    [_vm._t("right")],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }