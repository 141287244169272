var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-pagination-wrapper" }, [
    _c("ul", [
      _vm.hasPrev
        ? _c("li", [
            _c("i", { staticClass: "m-icon-prev", on: { click: _vm.goPrev } })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("li", [
        _c("span", [_vm._v(_vm._s(_vm.current))]),
        _c("span", { staticClass: "m-total" }, [
          _vm._v(" / " + _vm._s(_vm.total))
        ])
      ]),
      _vm._v(" "),
      _vm.hasNext
        ? _c("li", [
            _c("i", { staticClass: "m-icon-next", on: { click: _vm.goNext } })
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }