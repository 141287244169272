<template>
	<div :class="['nav-bar-wrap', { showMore: showMore}]">
		<div class="nav-bar">
			<div class="nav-list">
				<div :class="['nav-item', { active: +item.id === +sportId }]" v-for="(item, index) in navList" @mouseleave="leaveItem" @mouseenter="hoverItem(item)" @click="select(item)">
					<div class="icon-wrap">
						<img v-show="+item.id === +sportId" class="nav-icon" :src="item.colorIcon">
						<img v-show="+item.id !== +hoverId && +item.id !== +sportId" class="nav-icon" :src="item.icon">
						<img v-show="+item.id === +hoverId && +item.id !== +sportId" class="nav-icon" :src="item.hoverIcon">
						<span v-if="item.id !== 'popular' && !sizeLoading">{{sportNums[`sr:sport:${item.id}`]}}</span>
					</div>
					<div class="nav-title">{{item.title}}</div>
				</div>
				<div class="nav-more-game" @mouseenter="enterMore" @mouseleave="leaveMore">
					<div :class="['nav-item', { active: inMoreList }]" @mouseenter="hoverItem({id: '99999'})" @mouseleave="leaveItem">
						<div class="icon-wrap">
							<img v-show="inMoreList" class="nav-icon" :src="itemInMoreList.colorIcon">
							<img v-show="!inMoreList && +hoverId !== 99999" class="nav-icon" :src="itemInMoreList.icon">
							<img v-show="!inMoreList && +hoverId === 99999" class="nav-icon" :src="itemInMoreList.hoverIcon">
							<span v-if="inMoreList && !sizeLoading">{{sportNums[`sr:sport:${itemInMoreList.id}`]}}</span>
						</div>
						<div class="nav-title">{{itemInMoreList.title}}</div>
					</div>
					<i class="m-icon-arrow-up" v-if="showMore"/>
					<i class="m-icon-arrow-down" v-else/>
					<div class="more-game-list" v-if="showMore">
						<div :class="['nav-item', { active: +item.id === +sportId }]" v-for="(item, index) in moreList" @click="select(item)" @mouseleave="leaveItem" @mouseenter="hoverItem(item)">
							<div class="icon-wrap">
								<img v-show="+item.id === +sportId" class="nav-icon" :src="item.colorIcon">
								<img v-show="+item.id !== +hoverId && +item.id !== +sportId" class="nav-icon" :src="item.icon">
								<img v-show="+item.id === +hoverId && +item.id !== +sportId" class="nav-icon" :src="item.hoverIcon">
								<span v-if="item.id !== 'popular' && !sizeLoading">{{sportNums[`sr:sport:${item.id}`]}}</span>
							</div>
							<div class="nav-title">{{item.title}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getSportListUpperCase, foldThreshold, getSportType2Id, getSportConfigLowerCase } from 'config/sportsType';
import { pagePath, baseUrl } from 'config/pageConfig';

const popularId = 100000;
export default {
	name: 'navBar',
	props: {
		fetchSizeParams: {
			type: Object,
			'default': () => ({})
		}
	},
	data() {
		return {
			foldThreshold,
			sportId: 'sr:sport:1', // 默认写个足球, 然后根据路由去解析
			showMore: false,
			sportNums: {},
			sizeLoading: false,
			hoverId: ''
		};
	},
	created() {
		this.initNums();
		this.fetchSportsNum();
		setInterval(() => {
			this.fetchSportsNum(true);
		}, 15000);
	},
	computed: {
		sportListUpperCase() {
			return getSportListUpperCase();
		},
		navList() { // 靠前直接展示的体育项目
			const navList = this.sportListUpperCase.slice(0, foldThreshold);
			// if (!this.$route && location.pathname === pagePath.home) {
			// 	// 首页多一个popular选项
			// 	navList.unshift({
			// 		name: 'Popular',
			// 		title: 'Popular',
			// 		id: 0,
			// 		icon: require('../img/popular.png'),
			// 		hoverIcon: require('../img/popular-hover.png'),
			// 		colorIcon: require('../img/popular-color.png')
			// 	});
			// }
			// 改为各个页面都有popular
			navList.unshift({
				name: 'popular',
				title: this.$t('popular:label'),
				id: popularId, // 这里为了适应体育使用数字id
				icon: require('../img/popular.png'),
				hoverIcon: require('../img/popular-hover.png'),
				colorIcon: require('../img/popular-color.png')
			});
			return navList;
		},
		moreList() {
			return this.sportListUpperCase.slice(foldThreshold);
		},
		navSportIdArr() {
			return this.navList.map(nav => nav.id);
		},
		moreSportIdArr() {
			return this.moreList.map(nav => nav.id);
		},
		inMoreList() {
			return this.navSportIdArr.indexOf(this.sportId) < 0 && this.moreSportIdArr.indexOf(this.sportId) > -1;
		},
		itemInMoreList() { // more game位置占位的运动
			const moreItem = {
				title: this.$t('more:label'),
				name: 'more',
				id: 'more',
				icon: require('../img/more.png'),
				hoverIcon: require('../img/more-hover.png')
			};
			return this.inMoreList ? this.moreList[this.moreSportIdArr.indexOf(this.sportId)] : moreItem;
		},
		sportConfigLowerCase() {
			return getSportConfigLowerCase();
		},
		sportType2Id() {
			return getSportType2Id();
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler() {
				this.detectCurrentId();
			}
		},
		fetchSizeParams() {
			this.fetchSportsNum();
		}
	},
	methods: {
		hoverItem(item) {
			if (this.hoverId !== item.id) {
				this.hoverId = item.id;
			}
		},
		leaveItem() {
			this.hoverId = '';
		},
		enterMore() {
			if (!this.showMore) {
				this.showMore = true;
			}
		},
		leaveMore() {
			if (this.showMore) {
				this.showMore = false;
			}
		},
		select(item) {
			this.jump2Sport(item.id);
		},
		jump2Sport(id) {
			const isPopular = +id === popularId;
			const inSport = location.pathname.indexOf(`${baseUrl}sport`) === 0;
			if (!this.$route && location.pathname === pagePath.home && !isPopular) {
        // 首页跳sport
				if (this.sportConfigLowerCase[id]) {
					window.location = `${baseUrl}sport/${this.sportConfigLowerCase[id]}/today`;
				}
			} else if (!inSport) {
				// 其他页面， sport之外的页面跳sport
				if (this.sportConfigLowerCase[id]) {
					window.location = `${baseUrl}sport/${this.sportConfigLowerCase[id]}/today`;
				}
			} else if (this.$route && !isPopular) {
        // sport间路由跳转
				if (this.sportConfigLowerCase[id]) {
					this.$router.replace({
						name: this.$route.name,
						params: {
							sportName: this.sportConfigLowerCase[id]
						}
					});
				}
				const sportName = this.sportConfigLowerCase[id || '1'];
				if (this.$route.meta.productId === 1) {
					this.$router.replace({
						name: 'overview',
						params: {
							sportName
						}
					});
					return;
				}
				if (this.$route.meta.productId === 3) {
					let name;
					// today列表跳today列表, 其他的都应跳早盘列表
					if (this.$route.name !== 'eventListToday') {
						name = 'eventListDefault';
					}
					this.$router.replace({
						name,
						params: {
							sportName
						}
					});
				}
			} else if (this.$route && this.$route.params.sportName && isPopular) {
        // sport跳首页
				window.location.href = pagePath.home;
			}
		},
		detectCurrentId() {
			let value;
			if (!this.$route && location.pathname === pagePath.home) {
				value = popularId; // 0 代表popular
			}
			if (this.$route) { // 列表页和详情页路由中必有sportName
				const sportName = this.$route.params.sportName;
				if (sportName && this.sportType2Id[sportName]) {
					value = this.sportType2Id[sportName];
				} else {
					value = 1;
				}
			}
			this.sportId = value;
		},
		initNums() {
			const nums = {};
			this.sportListUpperCase.forEach(item => {
				nums[`sr:sport:${item.id}`] = 0; // 这里原始id不带前缀， 拼一下
			});
			this.sportNums = nums;
		},
		fetchSportsNum(silent) {
			const param = this.fetchSizeParams || {};
			param.type = 1; // 只取比赛数量
			if (!silent) {
				this.sizeLoading = true;
			}
			fetch('/data/sportNumber', {
				body: param
			}).then(res => res.json()).then(res => {
				if (res.code === 10000) {
					if (!silent) {
						this.sizeLoading = false;
					}
					res.data.events.forEach(item => {
						this.sportNums[item.id] = item.eventSize || 0;
					});
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
@import 'base/style/variable.less';
@import 'base/style/icon.less';

.nav-bar-wrap {
	width: 1000px;
	margin: auto;
	background-color: @white;
	box-shadow: 0 3px 3px @cEAECEFto67686D;
	&.showMore {
		border-left: 1px solid @cEAECEFto67686D;
		border-right: 1px solid @cEAECEFto67686D;
	}
	.nav-bar {
		position: relative;
		width: 1000px;
		min-width: 1000px;
		margin: 0 auto;
		background-color: @navBgColor;
		.nav-list {
			display: flex;
			align-items: center;
			justify-content: center;
			.nav-item {
				cursor: pointer;
				flex: 0 0 auto;
				width: 88px;
				padding: 10px 0;
				text-align: center;
				vertical-align: middle;
                background-color: @navBgColor;
                color: @blackWhiteColor;
				.icon-wrap {
					position: relative;
					width: 30px;
					height: 30px;
					margin: 0 auto;
					.nav-icon {
						width: 30px;
						height: 30px;
					}
					span {
						position: absolute;
						display: inline-block;
						top: 0px;
						right: -24px;
						width: 24px;
						text-align: left;
						line-height: 12px;
						font-size: 12px;
					}
				}

				.nav-title {
					margin-top: 10px;
					line-height: 16px;
					font-size: 14px;
				}
				&:hover {
					.nav-title {
						color: @navHoverTitleColor;
					}
					.icon-wrap {
						span {
							color: @navHoverTitleColor;
						}
					}
				}
				&.active {
					.nav-title {
						color: @navSelectTitleColor;
						font-weight: bold;
					}
					.icon-wrap {
						span {
							color: @navSelectTitleColor;
							font-weight: bold;
						}
					}
				}
			}
		}
		.nav-more-game {
			flex: 1 1 auto;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			position: relative;
            background-color: @navBgColor;
            color: @blackWhiteColor;
			.more-game-list {
				position: absolute;
				top: 76px;
				right: -1px;
				width: 1000px;
				background-color: @navBgColor;
				z-index: 889;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				border-left: 1px solid @cEAECEFto67686D;
				border-right: 1px solid @cEAECEFto67686D;
				box-shadow: 0 3px 3px @cEAECEFto67686D;
			}
			.m-icon-arrow-up {
				.m-icon-arrow--up();
				&:before {
					font-size: 16px;
					color: @secondGray5White;
				}
			}
			.m-icon-arrow-down {
				.m-icon-arrow--down();
				&:before {
					font-size: 16px;
					color: @secondGray5White;
				}
			}
		}
	}
}
</style>
