var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sk-wrapper" }, [
    _c(
      "div",
      { staticClass: "sk-row sk-pt8rl12 sk-bk" },
      _vm._l(7, function(item, index) {
        return _c("div", {
          staticClass: "sk-w80 sk-h22 sk-mr30 skeleton-loading"
        })
      })
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "sk-row sk-between sk-pt8rl12 sk-bk" },
      _vm._l(10, function(item, index) {
        return _c("div", { staticClass: "sk-w50 sk-h60 skeleton-loading" })
      })
    ),
    _vm._v(" "),
    _c("div", { staticClass: "sk-gap16" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "sk-row sk-between" },
      _vm._l(4, function(item, index) {
        return _c("div", { staticClass: "sk-column sk-bk sk-pt8rl12" }, [
          _c("div", { staticClass: "sk-bk sk-w180 sk-h20 skeleton-loading" }),
          _vm._v(" "),
          _vm._m(0, true),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "sk-row sk-between sk-mt20 sk-bk" },
            _vm._l(3, function(item, index) {
              return _c("div", {
                staticClass: "sk-row__one sk-w70 sk-h30 skeleton-loading"
              })
            })
          )
        ])
      })
    ),
    _vm._v(" "),
    _c("div", { staticClass: "sk-gap24" }),
    _vm._v(" "),
    _c("div", { staticClass: "sk-row-nocenter sk-between" }, [
      _c(
        "div",
        { staticClass: "sk-w745" },
        [
          _c("div", { staticClass: "sk-column sk-bk sk-pt8rl12" }, [
            _c("div", { staticClass: "sk-w200 sk-h30 skeleton-loading" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "sk-row sk-bk sk-mt12" },
              _vm._l(4, function(item, index) {
                return _c("div", {
                  staticClass:
                    "sk-row__one sk-mr30 sk-w80 sk-h30 skeleton-loading"
                })
              })
            )
          ]),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._l(8, function(item, index) {
            return _c(
              "div",
              { staticClass: "sk-sport sk-column sk-bk sk-pt8rl12" },
              [
                _c("div", { staticClass: "sk-w200 sk-h8 sk skeleton-loading" }),
                _vm._v(" "),
                _c("div", { staticClass: "sk-row sk-between" }, [
                  _vm._m(2, true),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "sk-row sk-between sk-full" },
                    _vm._l(3, function(item, index) {
                      return _c(
                        "div",
                        { staticClass: "sk-ml12 sk-mr15 sk-mt12 sk-full" },
                        [
                          _c(
                            "div",
                            { staticClass: "sk-row sk-between" },
                            _vm._l(3, function(item, index) {
                              return _c("div", {
                                staticClass:
                                  "sk-row__one sk-mr15 sk-w50 sk-h40 skeleton-loading"
                              })
                            })
                          )
                        ]
                      )
                    })
                  )
                ])
              ]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm._m(3)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sk-pk__mid sk-row sk-between" }, [
      _c("div", { staticClass: "sk-pk__mid__left skeleton-loading" }),
      _vm._v(" "),
      _c("div", { staticClass: "sk-pk__mid__mid skeleton-loading" }),
      _vm._v(" "),
      _c("div", { staticClass: "sk-pk__mid__right skeleton-loading" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sk-bk" }, [
      _c("div", { staticClass: "sk-wp100 sk-h20 skeleton-loading" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sk-column sk-pt12" }, [
      _c("div", {
        staticClass: "sk-column__one sk-w80 sk-h16 skeleton-loading"
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "sk-column__one sk-w80 sk-h16 sk-mt6 skeleton-loading"
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "sk-column sk-bk sk-pt8rl12 sk-ml12 sks-betslip" },
      [
        _c("div", { staticClass: "sk-row sk-between" }, [
          _c("div", { staticClass: "sk-w80 sk-h40 sk-mr15 skeleton-loading" }),
          _vm._v(" "),
          _c("div", { staticClass: "sk-w80 sk-h40 skeleton-loading" })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sk-w218 sk-h40 sk-mt12 skeleton-loading" }),
        _vm._v(" "),
        _c("div", { staticClass: "sk-row sk-between sk-mt12" }, [
          _c("div", { staticClass: "sk-w80 sk-h40 sk-mr15 skeleton-loading" }),
          _vm._v(" "),
          _c("div", { staticClass: "sk-w80 sk-h40 skeleton-loading" })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sk-w218 sk-h40 sk-mt12 skeleton-loading" })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }