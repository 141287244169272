var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "min-height": "100%" } },
    [
      _vm.showSkeleton
        ? _c("div", { staticClass: "skeleton" }, [_vm._t("skeleton")], 2)
        : _vm._e(),
      _vm._v(" "),
      [
        !_vm.showSkeleton
          ? _c(
              "div",
              { staticClass: "s-header" },
              [
                _vm.hadHead ? _c("Header") : _vm._e(),
                _vm._v(" "),
                _c("TopNav", { attrs: { value: _vm.navValue } }),
                _vm._v(" "),
                _c("NavBar", {
                  attrs: { fetchSizeParams: _vm.fetchSizeParams }
                })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              "s-page",
              { "hide-page": !_vm.showPage, "show-page": _vm.showPage }
            ]
          },
          [_vm._t("main-pagelet")],
          2
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }