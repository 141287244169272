<template lang="html">
  <div class="m-table-row">
	  <slot></slot>
  </div>
</template>

<script>
export default {
	name: 'AfTableRow'
};
</script>
