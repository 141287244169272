import { i18n } from 'locale/setup';
// 这是个全局配置， 所有的market配置信息汇总到这里， 不区分体育运动
// 因为国际化文件异步加载，js文件中无法拿到翻译数据， 改为延后执行
export const marketConfig = () => ({
	1: {
		// titleArr: ['1', 'X', '2'],
		titleArr: i18n.t('market:title:1').split(','),
		outcomeSize: 3,
		name: i18n.t('market:name:1')
		// name: '1X2'
	},
	8: {
		// titleArr: ['1', 'No goal', '2'],
		titleArr: i18n.t('market:title:8').split(','),
		outcomeSize: 3,
		name: i18n.t('market:name:8')
		// name: '1st Goal'
	},
	10: {
		// titleArr: ['1X', '12', '2X'],
		titleArr: i18n.t('market:title:10').split(','),
		outcomeSize: 3,
		name: i18n.t('market:name:10')
		// name: 'Double Chance'
	},
	11: {
		// titleArr: ['1', '2'],
		titleArr: i18n.t('market:title:11').split(','),
		outcomeSize: 2,
		name: i18n.t('market:name:11')
		// name: 'Draw No Bet'
	},
	15: {
		// itleArr: ['1 by 6+', '2 by 6+', 'Other'],
		titleArr: i18n.t('market:title:15').split(','),
		outcomeSize: 3,
		name: i18n.t('market:name:15')
		// name: 'Winning Margin'
	},
	16: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:16').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:16')
		// name: 'Handicap'
	},
	18: {
		// titleArr: ['Goals', 'Over', 'Under'],
		titleArr: i18n.t('market:title:18').split(','),
		outcomeSize: [i18n.t('market:specifier:goal'), 2],
		name: i18n.t('market:name:18')
		// name: 'Total'
	},
	19: {
		// titleArr: ['Points', 'Over', 'Under'],
		titleArr: i18n.t('market:title:19').split(','),
		outcomeSize: [i18n.t('market:specifier:point'), 2],
		name: i18n.t('market:name:19')
		// name: 'Home Total'
	},
	20: {
		// titleArr: ['Points', 'Over', 'Under'],
		titleArr: i18n.t('market:title:20').split(','),
		outcomeSize: [i18n.t('market:specifier:point'), 2],
		name: i18n.t('market:name:20')
		// name: 'Away Total'
	},
	25: {
		titleArr: ['', ''],
		outcomeSize: 2,
		name: i18n.t('market:name:25')
		// name: 'Goal Range'
	},
	26: {
		// titleArr: ['Odd', 'Even'],
		titleArr: i18n.t('market:title:26').split(','),
		outcomeSize: 2,
		name: i18n.t('market:name:26')
		// name: 'Odd/Even'
	},
	29: {
		// titleArr: ['Yes', 'No'],
		titleArr: i18n.t('market:title:29').split(','),
		outcomeSize: 2,
		name: i18n.t('market:name:29')
		// name: 'Both Teams to Score'
	},
	45: {
		titleArr: ['', '', ''],
		outcomeSize: 3,
		name: i18n.t('market:name:45')
		// name: 'Correct Score'
	},
	47: {
		titleArr: ['', '', ''],
		outcomeSize: 3,
		name: i18n.t('market:name:47')
		// name: 'Halftime/Fulltime'
	},
	52: {
		// titleArr: ['1st Half', '2nd Half', 'Equal'],
		titleArr: i18n.t('market:title:52').split(','),
		outcomeSize: 3,
		name: i18n.t('market:name:52')
		// name: 'Highest Scoring Half'
	},
	60: {
		// titleArr: ['1', 'X', '2'],
		titleArr: i18n.t('market:title:60').split(','),
		outcomeSize: 3,
		name: i18n.t('market:name:60')
		// name: '1st Half 1X2'
	},
	62: {
		// titleArr: ['1', 'No goal', '2'],
		titleArr: i18n.t('market:title:62').split(','),
		outcomeSize: 3,
		name: i18n.t('market:name:62')
		// name: '1st Half 1st Goal'
	},
	66: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:66').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:66')
		// name: '1st Half Handicap'
	},
	68: {
		// titleArr: ['Goals', 'Over', 'Under'],
		titleArr: i18n.t('market:title:68').split(','),
		outcomeSize: [i18n.t('market:specifier:goal'), 2],
		name: i18n.t('market:name:68')
		// name: '1st Half Total'
	},
	71: {
		titleArr: ['', ''],
		outcomeSize: 2,
		name: i18n.t('market:name:71')
		// name: '1st Half Goal Range'
	},
	74: {
		// titleArr: ['Odd', 'Even'],
		titleArr: i18n.t('market:title:74').split(','),
		outcomeSize: 2,
		name: i18n.t('market:name:74')
		// name: '1st Half Odd/Even'
	},
	81: {
		titleArr: ['', '', ''],
		outcomeSize: 3,
		name: i18n.t('market:name:81')
		// name: '1st Half Correct Score'
	},
	83: {
		// titleArr: ['1', 'X', '2'],
		titleArr: i18n.t('market:title:83').split(','),
		outcomeSize: 3,
		name: i18n.t('market:name:83')
		// name: '2nd Half 1X2'
	},
	88: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:88').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:88')
		// name: '2nd Half Handicap'
	},
	90: {
		// titleArr: ['Goals', 'Over', 'Under'],
		titleArr: i18n.t('market:title:90').split(','),
		outcomeSize: [i18n.t('market:specifier:goal'), 2],
		name: i18n.t('market:name:90')
		// name: '2nd Half Total'
	},
	163: {
		// titleArr: ['1', 'None', '2'],
		titleArr: i18n.t('market:title:163').split(','),
		outcomeSize: 3,
		name: i18n.t('market:name:163')
		// name: 'Xth Corner'
	},
	186: {
		// titleArr: ['1', '2'],
		titleArr: i18n.t('market:title:186').split(','),
		outcomeSize: 2,
		name: i18n.t('market:name:186')
		// name: 'Winner'
	},
	187: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:187').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:187')
		// name: 'Game Handicap'
	},
	188: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:188').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:188')
		// name: 'Set Handicap'
	},
	189: {
		// titleArr: ['Games', 'Over', 'Under'],
		titleArr: i18n.t('market:title:189').split(','),
		outcomeSize: [i18n.t('market:specifier:game'), 2],
		name: i18n.t('market:name:189')
		// name: 'Total Games'
	},
	199: {
		titleArr: ['', ''], // 这里标明199两列的样式
		outcomeSize: 2,
		name: i18n.t('market:name:199')
		// name: 'Correct Score'
	},
	202: {
		// titleArr: ['1', '2'],
		titleArr: i18n.t('market:title:202').split(','),
		outcomeSize: 2,
		name: i18n.t('market:name:202')
		// name: '1st Set Winner'
	},
	203: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:203').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:203')
		// name: 'Xth Set Game Handicap'
	},
	204: {
		// titleArr: ['Games', 'Over', 'Under'],
		titleArr: i18n.t('market:title:204').split(','),
		outcomeSize: [i18n.t('market:specifier:game'), 2],
		name: i18n.t('market:name:204')
		// name: '1st Set Total Games'
	},
	210: {
		// titleArr: ['1', '2'],
		titleArr: i18n.t('market:title:210').split(','),
		outcomeSize: 2,
		name: i18n.t('market:name:210')
		// name: 'Xth Set Game Y Winner'
	},
	218: {
		// titleArr: ['1', '2'],
		titleArr: i18n.t('market:title:218').split(','),
		outcomeSize: 2,
		name: i18n.t('market:name:218')
		// name: 'Xth Set Game Y - Zth Point'
	},
	219: {
		// titleArr: ['1', '2'],
		titleArr: i18n.t('market:title:219').split(','),
		outcomeSize: 2,
		name: i18n.t('market:name:219')
		// name: 'Winner(incl. OT)'
	},
	223: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:223').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:223')
		// name: 'Handicap(incl. OT)'
	},
	225: {
		// titleArr: ['Points', 'Over', 'Under'],
		titleArr: i18n.t('market:title:225').split(','),
		outcomeSize: [i18n.t('market:specifier:point'), 2],
		name: i18n.t('market:name:225')
		// name: 'Total(incl. OT)'
	},
	227: {
		// titleArr: ['Points', 'Over', 'Under'],
		titleArr: i18n.t('market:title:227').split(','),
		outcomeSize: [i18n.t('market:specifier:point'), 2],
		name: i18n.t('market:name:227')
		// name: 'Home Total(incl. OT)'
	},
	228: {
		// titleArr: ['Points', 'Over', 'Under'],
		titleArr: i18n.t('market:title:228').split(','),
		outcomeSize: [i18n.t('market:specifier:point'), 2],
		name: i18n.t('market:name:228')
		// name: 'Away Total(incl. OT)'
	},
	229: {
		// titleArr: ['Odd', 'Even'],
		titleArr: i18n.t('market:title:229').split(','),
		outcomeSize: 2,
		name: i18n.t('market:name:229')
		// name: 'Odd/Even'
	},
	230: {
		// titleArr: ['1', '2'],
		titleArr: i18n.t('market:title:230').split(','),
		outcomeSize: 2,
		name: i18n.t('market:name:230')
		// name: 'Race to X Points(incl. OT)'
	},
	235: {
		// titleArr: ['1', 'X', '2'],
		titleArr: i18n.t('market:title:235').split(','),
		outcomeSize: 3,
		name: i18n.t('market:name:235')
		// name: '1st Quarter 1X2'
	},
	236: {
		// titleArr: ['Points', 'Over', 'Under'],
		titleArr: i18n.t('market:title:236').split(','),
		outcomeSize: [i18n.t('market:specifier:point'), 2],
		name: i18n.t('market:name:236')
		// name: '1st Quarter Total'
	},
	237: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:237').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:237')
		// name: 'Point Handicap'
	},
	238: {
		// titleArr: ['Points', 'Over', 'Under'],
		titleArr: i18n.t('market:title:238').split(','),
		outcomeSize: [i18n.t('market:specifier:point'), 2],
		name: i18n.t('market:name:238')
		// name: 'Total Points'
	},
	245: {
		// titleArr: ['1', '2'],
		titleArr: i18n.t('market:title:245').split(','),
		outcomeSize: 2,
		name: i18n.t('market:name:245')
		// name: '1st Game Winner'
	},
	246: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:246').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:246')
		// name: '1st Game Point Handicap'
	},
	247: {
		// titleArr: ['Points', 'Over', 'Under'],
		titleArr: i18n.t('market:title:247').split(','),
		outcomeSize: [i18n.t('market:specifier:point'), 2],
		name: i18n.t('market:name:247')
		// name: '1st Game Total Points'
	},
	251: {
		// titleArr: ['1', '2'],
		titleArr: i18n.t('market:title:251').split(','),
		outcomeSize: 2,
		name: i18n.t('market:name:251')
		// name: 'Winner(incl. OT)'
	},
	256: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:256').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:256')
		// name: 'Handicap(incl. OT)'
	},
	258: {
		// titleArr: ['Runs', 'Over', 'Under'],
		titleArr: i18n.t('market:title:258').split(','),
		outcomeSize: [i18n.t('market:specifier:run'), 2],
		name: i18n.t('market:name:258')
		// name: 'Total(incl. OT)'
	},
	274: {
		// titleArr: ['1', 'X', '2'],
		titleArr: i18n.t('market:title:274').split(','),
		outcomeSize: 3,
		name: i18n.t('market:name:274')
		// name: '1X2(1-5 Innings)'
	},
	275: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:275').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:275')
		// name: 'Handicap(1-5 Innings)'
	},
	276: {
		// titleArr: ['Runs', 'Over', 'Under'],
		titleArr: i18n.t('market:title:276').split(','),
		outcomeSize: [i18n.t('market:specifier:run'), 2],
		name: i18n.t('market:name:276')
		// name: 'Total(1-5 Innings)'
	},
	287: {
		// titleArr: ['1', 'X', '2'],
		titleArr: i18n.t('market:title:287').split(','),
		outcomeSize: 3,
		name: i18n.t('market:name:287')
		// name: '1st Inning 1X2'
	},
	288: {
		// titleArr: ['Runs', 'Over', 'Under'],
		titleArr: i18n.t('market:title:288').split(','),
		outcomeSize: [i18n.t('market:specifier:run'), 2],
		name: i18n.t('market:name:288')
		// name: '1st Inning Total'
	},
	303: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:303').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:303')
		// name: '1st Quarter Handicap'
	},
	309: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:309').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:309')
		// name: '1st Set Point Handicap'
	},
	310: {
		// titleArr: ['Points', 'Over', 'Under'],
		titleArr: i18n.t('market:title:310').split(','),
		outcomeSize: [i18n.t('market:specifier:point'), 2],
		name: i18n.t('market:name:310')
		// name: '1st Set Total Points'
	},
	314: {
		// titleArr: ['Sets', 'Over', 'Under'],
		titleArr: i18n.t('market:title:314').split(','),
		outcomeSize: [i18n.t('market:specifier:set'), 2],
		name: i18n.t('market:name:314')
		// name: 'Total Sets'
	},
	315: {
		// titleArr: ['1', 'X', '2'],
		titleArr: i18n.t('market:title:315').split(','),
		outcomeSize: 3,
		name: i18n.t('market:name:315')
		// name: '1st Set 1X2'
	},
	316: {
		// titleArr: ['1', '2'],
		titleArr: i18n.t('market:title:316').split(','),
		outcomeSize: 2,
		name: i18n.t('market:name:316')
		// name: 'Xth Set Draw No Bet'
	},
	318: {
		// titleArr: ['Points', 'Over', 'Under'],
		titleArr: i18n.t('market:title:318').split(','),
		outcomeSize: [i18n.t('market:specifier:point'), 2],
		name: i18n.t('market:name:318')
		// name: 'Xth Set Home Total'
	},
	319: {
		// titleArr: ['Points', 'Over', 'Under'],
		titleArr: i18n.t('market:title:319').split(','),
		outcomeSize: [i18n.t('market:specifier:point'), 2],
		name: i18n.t('market:name:319')
		// name: 'Xth Set Away Total'
	},
	340: {
		// titleArr: ['1', '2'],
		titleArr: i18n.t('market:title:340').split(','),
		outcomeSize: 2,
		name: i18n.t('market:name:340')
		// name: 'Winner(incl. Super Over)'
	},
	352: {
		// titleArr: ['Runs', 'Over', 'Under'],
		titleArr: i18n.t('market:title:352').split(','),
		outcomeSize: [i18n.t('market:specifier:run'), 2],
		name: i18n.t('market:name:352')
		// name: '1st Inning Overs 0-6 Home Total'
	},
	353: {
		// titleArr: ['Runs', 'Over', 'Under'],
		titleArr: i18n.t('market:title:353').split(','),
		outcomeSize: [i18n.t('market:specifier:run'), 2],
		name: i18n.t('market:name:353')
		// name: '1st Inning Overs 0-6 Away Total'
	},
	371: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:371').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:371')
		// name: '1st Set Leg Handicap'
	},
	372: {
		// titleArr: ['Legs', 'Over', 'Under'],
		titleArr: i18n.t('market:title:372').split(','),
		outcomeSize: [i18n.t('market:specifier:leg'), 2],
		name: i18n.t('market:name:372')
		// name: '1st Set Total Legs'
	},
	406: {
		// titleArr: ['1', '2'],
		titleArr: i18n.t('market:title:406').split(','),
		outcomeSize: 2,
		name: i18n.t('market:name:406')
		// name: 'Winner(incl. OT)'
	},
	443: {
		// titleArr: ['1', 'X', '2'],
		titleArr: i18n.t('market:title:443').split(','),
		outcomeSize: 3,
		name: i18n.t('market:name:443')
		// name: '1st Period 1X2'
	},
	446: {
		// titleArr: ['Goals', 'Over', 'Under'],
		titleArr: i18n.t('market:title:446').split(','),
		outcomeSize: [i18n.t('market:specifier:goal'), 2],
		name: i18n.t('market:name:446')
		// name: '1st Period Total'
	},
	460: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:460').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:460')
		// name: '1st Period Handicap'
	},
	493: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:493').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:493')
		// name: 'Frame Handicap'
	},
	494: {
		// titleArr: ['Frames', 'Over', 'Under'],
		titleArr: i18n.t('market:title:494').split(','),
		outcomeSize: [i18n.t('market:specifier:frame'), 2],
		name: i18n.t('market:name:494')
		// name: 'Total Frames'
	},
	499: {
		// titleArr: ['1', '2'],
		titleArr: i18n.t('market:title:499').split(','),
		outcomeSize: 2,
		name: i18n.t('market:name:499')
		// name: '1st Frame Winner'
	},
	500: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:500').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:500')
		// name: 'Xth Frame Point Handicap'
	},
	501: {
		// titleArr: ['Points', 'Over', 'Under'],
		titleArr: i18n.t('market:title:501').split(','),
		outcomeSize: [i18n.t('market:specifier:point'), 2],
		name: i18n.t('market:name:501')
		// name: 'Xth Frame Total Points'
	},
	527: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:527').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:527')
		// name: '1st Set Handicap'
	},
	528: {
		// titleArr: ['Points', 'Over', 'Under'],
		titleArr: i18n.t('market:title:528').split(','),
		outcomeSize: [i18n.t('market:specifier:point'), 2],
		name: i18n.t('market:name:528')
		// name: '1st Set Total'
	},
	605: {
		// titleArr: ['Runs', 'Over', 'Under'],
		titleArr: i18n.t('market:title:605').split(','),
		outcomeSize: [i18n.t('market:specifier:run'), 2],
		name: i18n.t('market:name:605')
		// name: '1st Inning Total'
	},
	746: {
		// titleArr: ['Line', '1', '2'],
		titleArr: i18n.t('market:title:746').split(','),
		outcomeSize: [i18n.t('market:specifier:line'), 2],
		name: i18n.t('market:name:746')
		// name: 'Xth Inning Handicap'
	}
});
// 赛前赛中都有该market，且需要展示不同market name的
const productMapFun = () => ({
	8: i18n.t('market:name:8:live'),
	202: i18n.t('market:name:202:live'),
	204: i18n.t('market:name:204:live'),
	235: i18n.t('market:name:235:live'),
	236: i18n.t('market:name:236:live'),
	245: i18n.t('market:name:245:live'),
	246: i18n.t('market:name:246:live'),
	247: i18n.t('market:name:247:live'),
	287: i18n.t('market:name:287:live'),
	288: i18n.t('market:name:288:live'),
	303: i18n.t('market:name:303:live'),
	309: i18n.t('market:name:309:live'),
	310: i18n.t('market:name:310:live'),
	315: i18n.t('market:name:315:live'),
	352: i18n.t('market:name:352:live'),
	353: i18n.t('market:name:353:live'),
	371: i18n.t('market:name:371:live'),
	372: i18n.t('market:name:372:live'),
	443: i18n.t('market:name:443:live'),
	446: i18n.t('market:name:446:live'),
	460: i18n.t('market:name:460:live'),
	499: i18n.t('market:name:499:live'),
	527: i18n.t('market:name:527:live'),
	528: i18n.t('market:name:528:live'),
	605: i18n.t('market:name:605:live'),
});

// 针对各种特殊逻辑的market做分类

// AType
// 羽毛球、网球固定盘数的比分market
export const a1 = [199];
// 半场/全场胜负
export const a2 = [47];
// goal range
export const a3 = [25, 71];

// BType
// 足球正确比分
export const b1 = [45, 81];

// CType
// 含有额外specifier的（只要有xyz都算）
export const c1 = [8, 15, 62, 163, 202, 203, 204, 210, 218, 230, 235, 236, 245, 246, 247, 287, 288, 303, 309, 310, 315, 316, 318, 319, 352, 353, 371, 372, 443, 446, 460, 499, 500, 501, 527, 528, 605, 746];

// c1中， 含有需要下拉展示specifier的marketId以及对应的specifier字段
export const c1WithSpecifier = {
	203: 'hcp',
	204: 'total',
	236: 'total',
	246: 'hcp',
	247: 'total',
	288: 'total',
	303: 'hcp',
	309: 'hcp',
	310: 'total',
	318: 'total',
	319: 'total',
	352: 'total', // 352和353没查到数据, 因为也是Total玩法所以猜想specifier字段是total
	353: 'total',
	371: 'hcp',
	372: 'total', // 371 372也是没数据猜的字段
	446: 'total',
	460: 'hcp',
	500: 'hcp',
	501: 'total',
	527: 'hcp',
	528: 'total',
	605: 'total',
	746: 'hcp'
};

// 需要根据体育类型改变specifier name 的情况
const sport2SpecifierMapFun = () => ({
	18: {
		'sr:sport:1': i18n.t('market:specifier:goal'),
		'sr:sport:12': i18n.t('market:specifier:point'),
		'sr:sport:13': i18n.t('market:specifier:point'),
		'sr:sport:2': i18n.t('market:specifier:point'),
		'sr:sport:24': i18n.t('market:specifier:goal'),
		'sr:sport:29': i18n.t('market:specifier:goal'),
		'sr:sport:4': i18n.t('market:specifier:goal'),
		'sr:sport:6': i18n.t('market:specifier:goal'),
	},
	68: {
		'sr:sport:1': i18n.t('market:specifier:goal'),
		'sr:sport:12': i18n.t('market:specifier:point'),
		'sr:sport:2': i18n.t('market:specifier:point'),
		'sr:sport:29': i18n.t('market:specifier:goal'),
		'sr:sport:6': i18n.t('market:specifier:goal'),
	},
	236: {
		'sr:sport:13': i18n.t('market:specifier:point'),
		'sr:sport:16': i18n.t('market:specifier:point'),
		'sr:sport:2': i18n.t('market:specifier:point'),
		'sr:sport:24': i18n.t('market:specifier:goal'),
	}
});

export const isAType = function(id) {
	return a1.indexOf(id) > -1 || a2.indexOf(id) > -1 || a3.indexOf(id) > -1;
};

export const isBType = function(id) {
	return b1.indexOf(id) > -1;
};

export const isCType = function(id) {
	return c1.indexOf(id) > -1;
};
// console.log(marketConfig);
export const getMarketConfig = (marketId = '', productId = null, sportId = '') => {
	if (!marketId) {
		console.error('marketId is required.');
		return;
	}

	const market = marketConfig()[+marketId];
	if (!productId && !sportId) {
		return market;
	}

	// 处理特殊market name, 针对特定marketId, 并且只有直播需要处理
	if (+productId === 1) {
		const productMap = productMapFun();
		const marketName = productMap[marketId];
		if (marketName) {
			market.name = marketName;
		}
	}

	// 某些marketId下不同体育类型展示不同的specifier name
	const sport2SpecifierMap = sport2SpecifierMapFun();
	if (sportId && sport2SpecifierMap[marketId] && sport2SpecifierMap[marketId][sportId]) {
		market.titleArr[0] = sport2SpecifierMap[marketId][sportId];
		market.outcomeSize[0] = sport2SpecifierMap[marketId][sportId];
	}

	return market;
};

// 根据marketId解析specifier中的额外信息，这个方法只能live比赛调用
export const getMarketXYZ = (event, marketId) => {
	if (c1.indexOf(+marketId) < 0) {
		return '';
	}
	if (+marketId === 15) { // winning margin有specifier，但是既不属于选择的specifier也不属于XYZ
		return '';
	}
	let targetMarkets = event.markets;
	targetMarkets = targetMarkets ? targetMarkets[marketId] || [] : [];
	if (targetMarkets.length) {
		targetMarkets = targetMarkets.filter(cur => cur.status === 0 || cur.status === 1);
	} else {
		targetMarkets = [];
	}
	// markets有可能不存在为{}, 或者被过滤成空数组
	const {
		markets
	} = sortMarketsByKey(targetMarkets, c1WithSpecifier[marketId]);
	let specifier = '';
	if (markets.length > 0) {
		specifier = markets[0].specifier;
	} else {
		// markets为空对象或者空数组
		return '';
	}

	if (specifier) {
		const arr = specifier.split('|');
		const values = [];
		const labels = ['X', 'Y', 'Z'];
		arr.forEach(item => {
			if (item.indexOf(c1WithSpecifier[marketId]) < 0) {
				const tmp = item.split('=');
				values.push(tmp[1]);
			}
		});
		let str = '';
		values.slice(0, labels.length).forEach((val, i) => {
			str += `${labels[i]}=${val},`;
		});
		return str.substr(0, str.length - 1);
	}
	return '';
};

// market specifier排序相关
// 解析 specifier 字符串
const parseSpecifier = (specifier, key) => {
	const arr = specifier.split('|');
	let target = arr[0];
	if (key) {
		arr.forEach(item => {
			if (item.indexOf(key) > -1) {
				target = item;
			}
		});
	}

	return target.split('=');
};

const sortBySpecifier = (markets, key) => markets.sort((a, b) => {
	if (a.specifier && b.specifier) {
		const tmp = parseSpecifier(a.specifier, key);
		const tmp1 = parseSpecifier(b.specifier, key);
		return tmp[1] - tmp1[1];
	}
	return false;
});

export const sortMarkets = markets => {
	const specifierList = [];
	let name;
	const sortedMarkets = sortBySpecifier(markets);
	// 对market排序
	for (let i = 0; i < sortedMarkets.length; i++) {
		const market = sortedMarkets[i];
		// 锁哥specifier用|隔开，每个speifier是用=隔开
		let tmp = market.specifier.split('|');
		tmp = tmp[0];
		const ss = tmp.split('=');
		specifierList.push({
			index: i,
			name: ss[1]
		});
		// 每个specifier都是类似  goals=1.5这种,所以多次赋值也可以
		name = ss[0];
	}
	return {
		markets: sortedMarkets,
		specifierList,
		name
	};
};

// 根据指定的specifier字段排序， 而不是默认的第一个
export const sortMarketsByKey = (markets, key) => {
	const specifierList = [];
	let name;
	const sortedMarkets = sortBySpecifier(markets, key);
	// 对market排序
	for (let i = 0; i < sortedMarkets.length; i++) {
		const market = sortedMarkets[i];

		const tmp = parseSpecifier(market.specifier, key);
		specifierList.push({
			index: i,
			name: tmp[1]
		});
		// 每个specifier都是类似  goals=1.5这种,所以多次赋值也可以
		name = tmp[0];
	}
	return {
		markets: sortedMarkets,
		specifierList,
		name
	};
};

export default {
	marketConfig,
	getMarketConfig,
	sortMarkets,
	sortMarketsByKey,
};
