var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      ref: "wrap",
      class: [
        "m-slider-wrapper",
        _vm.flowDirection,
        _vm.disabledClass,
        { "m-slider-has-label": _vm.piecewiseLabel }
      ],
      style: _vm.wrapStyles,
      on: { click: _vm.wrapClick }
    },
    [
      _c(
        "div",
        {
          ref: "elem",
          staticClass: "m-slider",
          style: [_vm.elemStyles, _vm.bgStyle],
          attrs: { "aria-hidden": "true" }
        },
        [
          _vm.isRange
            ? [
                _c("div", {
                  ref: "dot0",
                  class: [_vm.tooltipStatus, "m-slider-dot"],
                  style: [_vm.dotStyles, _vm.sliderStyles[0]],
                  on: {
                    mousedown: function($event) {
                      _vm.moveStart(0)
                    },
                    touchstart: function($event) {
                      _vm.moveStart(0)
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", {
                  ref: "dot1",
                  class: [_vm.tooltipStatus, "m-slider-dot"],
                  style: [_vm.dotStyles, _vm.sliderStyles[1]],
                  on: {
                    mousedown: function($event) {
                      _vm.moveStart(1)
                    },
                    touchstart: function($event) {
                      _vm.moveStart(1)
                    }
                  }
                })
              ]
            : [
                _c("div", {
                  ref: "dot",
                  class: [_vm.tooltipStatus, "m-slider-dot"],
                  style: [_vm.dotStyles, _vm.sliderStyles],
                  on: { mousedown: _vm.moveStart, touchstart: _vm.moveStart }
                })
              ],
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "m-slider-piecewise" },
            _vm._l(_vm.piecewiseDotWrap, function(piecewiseObj, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "m-slider-piecewise-item",
                  style: [_vm.piecewiseDotStyle, piecewiseObj.style]
                },
                [
                  _vm._t(
                    "piecewise",
                    [
                      _vm.piecewise
                        ? _c("span", {
                            staticClass: "m-slider-piecewise-dot",
                            style: [
                              _vm.piecewiseStyle,
                              piecewiseObj.inRange
                                ? _vm.piecewiseActiveStyle
                                : null
                            ]
                          })
                        : _vm._e()
                    ],
                    {
                      label: piecewiseObj.label,
                      index: index,
                      first: index === 0,
                      last: index === _vm.piecewiseDotWrap.length - 1
                    }
                  ),
                  _vm._v(" "),
                  _vm._t(
                    "label",
                    [
                      _vm.piecewiseLabel
                        ? _c(
                            "span",
                            {
                              staticClass: "m-slider-piecewise-label",
                              style: [
                                _vm.labelStyle,
                                piecewiseObj.inRange
                                  ? _vm.labelActiveStyle
                                  : null
                              ]
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(piecewiseObj.label) +
                                  "\n\t\t\t\t\t"
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    {
                      label: piecewiseObj.label,
                      index: index,
                      first: index === 0,
                      last: index === _vm.piecewiseDotWrap.length - 1
                    }
                  )
                ],
                2
              )
            })
          ),
          _vm._v(" "),
          _c("div", {
            ref: "process",
            staticClass: "m-slider-process",
            style: _vm.processStyle
          })
        ],
        2
      ),
      _vm._v(" "),
      !_vm.isRange && !_vm.data
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.val,
                expression: "val"
              }
            ],
            staticClass: "m-slider-sr-only",
            attrs: { type: "range", min: _vm.min, max: _vm.max },
            domProps: { value: _vm.val },
            on: {
              __r: function($event) {
                _vm.val = $event.target.value
              }
            }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }