import Vue from 'vue';
import { pushTopic } from 'components/eventList/util';
import detailPush from 'push/betPush';
import * as mutationTypes from './mutationTypes';
import '../../../mockData/home/home?debug';
import { getProductStatus } from './commonFun';

const mt = [
	/**
	 * sport 比赛event 监听
	 */
	function eventListener (commit, data) {
		data = JSON.parse(data);
		commit(mutationTypes.UPDATE_SPORT_EVENT, data);
	},
	function marketListener (commit, data) {
		// console.log('market change', data);
		data = JSON.parse(data);
		commit(mutationTypes.UPDATE_SPORT_MARKET, data);
	},
	/**
	 * sport比赛odds push监听
	 */
	function oddsListener (commit, data) {
		data = JSON.parse(data);
		commit(mutationTypes.UPDATE_SPORT_OUTCOME, data);
	},
];
const liveMt = [
	/**
	 * live比赛event push监听
	 */
	function liveEventListener (commit, data) {
		data = JSON.parse(data);
		commit(mutationTypes.UPDATE_LIVE_EVENT, data);
	},
	function liveMarketListener (commit, data) {
		data = JSON.parse(data);
		commit(mutationTypes.UPDATE_LIVE_MARKET, data);
	},
	/**
	 * live比赛odds push监听
	 }*/
	function liveOddsEventListener (commit, data) {
		data = JSON.parse(data);
		commit(mutationTypes.UPDATE_LIVE_OUTCOME, data);
	},
];
let importantRequestId = 0; // 这里加一个id手动处理请求抖动， fetch中的不符合要求

export default {
	// 获取推荐比赛
	fetchRecommendEvents ({ commit, state, dispatch }) {
		dispatch('unsubRecommendEvents');
		return fetch('/data/featureMatches')
		.then(res => res.json())
		.then(res => {
			const { code, data, message, abort } = res;
			if (code === 10000) {
				// 判断是否product down, 不区分live还是prematch product down了，都不显示。
				const productStatus = getProductStatus(data, 'all');
				commit(mutationTypes.UPDATE_RECOMMEND_PRODUCT_STATUS, productStatus);

				const d = data || {};
				Vue.nextTick(() => commit(mutationTypes.RECOMMEND_LOADING, false));
				return d;
			// abort认为还在加载中
			} else if (abort) {
				return null;
			}
			return Promise.reject(new Error(message));
		})
		.then(data => commit(mutationTypes.FETCH_RECOMMEND_EVENTS, data))
		.then(() => {
			dispatch('subRecommendEvents');
		})
		.catch(err => {
			commit(mutationTypes.RECOMMEND_LOADING, -1);
			return Promise.reject(err);
		});
	},
	// 3. 获取 important match 列表
	fetchPcImportantEvents({
		commit,
		state,
		dispatch
	}, params) {
		dispatch('unsub');
		importantRequestId++;
		const currentId = importantRequestId;
		return new Promise((resolve, reject) => {
			// 先重置数据
			state.sport = {};
			// const { sportId } = params;
			commit(mutationTypes.UPDATE_CURRENT_GROUP, 0);
			fetch('/data/highlightMatches', {
				method: 'GET',
				body: params
			}, '@fetchPcImportantEvents')
			.then(res => res.json())
			.then(res => {
				if (currentId !== importantRequestId) {
					reject({
						abort: true
					});
					return;
				}
				const code = res.code || '';
				if (code === 10000) {
					if (res.data) {
						// let productStatus = res.message.split('#');
						// if (productStatus.length === 2) {
							// productStatus = +productStatus[0];
							// sia-20200928：这里的判断改为调公共的状态方法 看原来的代码逻辑，这里传值应该是 preMatch 因为原代码只取了数组的第一个值进行判断
						const productStatus = getProductStatus(res.data, 'preMatch');
							// 1表示不能投注 0表示可以投注
						if (productStatus === 1 || productStatus === 0) {
							commit(mutationTypes.UPDATE_SPORT_PRODUCT_STATUS, productStatus);
						}
						// }
					}
					commit(mutationTypes.UPDATE_IMPORTANT_MATCH, res.data.events || []);
					resolve(res.data.events || []);
				} else {
					reject(res);
				}
			})
			.then(data => {
				dispatch('sub');
				return data;
			})
			.catch(err => reject(err));
		});
	},

	// 4. 获取 live 比赛或者五场 prematch，请求接口
	fetchLiveOrPrematchEvents({
		commit,
		state,
		dispatch
	}, params) {
		dispatch('unsubLive');
		return new Promise((resolve, reject) => {
			// const { sportId } = params;
			commit(mutationTypes.UPDATE_CURRENT_LIVE_GROUP, 0);
			fetch('/data/mHomeLiveMatches', {
				method: 'GET',
				body: params
			}, '@fetchLiveOrPrematchEvents')
			.then(res => res.json())
			.then(res => {
				const code = res.code || '';
				if (code === 10000) {
					if (res.data) {
						// let productStatus = res.message.split('#');
						// if (productStatus.length === 2) {
							// productStatus = +productStatus[1];
							// sia-20200928：这里的判断改为调公共的状态方法 看原来的代码逻辑，这里传值应该是 live 因为原代码只取了数组的第二个值进行判断
						const productStatus = getProductStatus(res.data, 'live');
							// 1表示不能投注 0表示可以投注
						if (productStatus === 1 || productStatus === 0) {
							commit(mutationTypes.UPDATE_LIVE_PRODUCT_STATUS, productStatus);
						}
						// }
					}
					commit(mutationTypes.UPDATE_LIVE_MATCH, res.data.events || []);
					resolve(res.data.events || []);
				} else {
					reject(res);
				}
			})
			.then(data => {
				dispatch('subLive');
				return data;
			})
			.catch(err => reject(err));
		});
	},
	subRecommendEvents ({ commit, state, getters }) {
		if (!state.recommendEvents || !state.recommendEvents.events) {
			return;
		}

		const cb = (data, type) => {
			const result = JSON.parse(data);
			if (type === 1) {
				commit(mutationTypes.UPDATE_RECOMMEND_EVENT, result);
			} else if (type === 2) {
				commit(mutationTypes.UPDATE_RECOMMEND_MARKET, result);
			} else {
				commit(mutationTypes.UPDATE_RECOMMEND_ODDS, result);
			}
		};

		const { events } = state.recommendEvents;
		if (events) {
			for (const key in events) { // eslint-disable-line
				const event = events[key];
				// 订阅事件 1表示event 2表示market status 3表示market odds
				[1, 2, 3].forEach(x => {
					pushTopic({
						sportId: event.sportId,
						categoryId: event.categoryId,
						tournamentId: event.tournamentId,
						eventId: event.eventId,
						marketIds: ['1']
					}, x, data => cb(data, x));
				});
			}
		}
	},
	unsubRecommendEvents ({ commit, state }) {
		if (!state.recommendEvents || !state.recommendEvents.events) {
			return;
		}
		const { events } = state.recommendEvents;
		if (events) {
			for (const key in events) { // eslint-disable-line
				const event = events[key];
				// 订阅事件 1表示event 2表示market status 3表示market odds
				[1, 2, 3].forEach(x => {
					pushTopic({
						sportId: event.sportId,
						categoryId: event.categoryId,
						tournamentId: event.tournamentId,
						eventId: event.eventId,
						marketIds: ['1']
					}, x, undefined, false);
				});
			}
		}
	},
	subProductStatus({ commit, state }) {
		detailPush.sub({
			topic: 'product^1^status',
			pushType: 'GROUP',
			listener: data => {
				if (typeof data === 'string') {
					data = JSON.parse(data);
					if (data && data.length) {
						const code = +data[0];
						if (code === 0 || code === 1) {
							commit(mutationTypes.UPDATE_LIVE_PRODUCT_STATUS, code);
						}
					}
				}
			}
		});
		detailPush.sub({
			topic: 'product^3^status',
			pushType: 'GROUP',
			listener: data => {
				if (typeof data === 'string') {
					data = JSON.parse(data);
					if (data && data.length) {
						const code = +data[0];
						if (code === 0 || code === 1) {
							commit(mutationTypes.UPDATE_SPORT_PRODUCT_STATUS, code);
							commit(mutationTypes.UPDATE_RECOMMEND_PRODUCT_STATUS, code);
						}
					}
				}
			}
		});
	},
	unSubProductStatus ({ commit, state }) {
		detailPush.unsub({
			topic: 'product^1^status',
			pushType: 'GROUP'
		});
		detailPush.unsub({
			topic: 'product^3^status',
			pushType: 'GROUP'
		});
	},
	sub ({ commit, state }) {
		if (!state.sport || !state.sport.events) {
			return;
		}
		const events = state.sport.events;
		if (events) {
			for (const key in events) { // eslint-disable-line
				const event = events[key];
				const marketConfig = state.getSportMarketIds()[event.sportId];
				let marketIds = [];
				marketConfig && marketConfig.forEach(market => {
					marketIds = marketIds.concat(market.marketIds);
				});
				// 订阅事件 1表示event 2表示market status 3表示market odds
				mt.forEach((current, index) => {
					pushTopic({
						sportId: event.sportId,
						productId: 3,
						categoryId: event.categoryId,
						tournamentId: event.tournamentId,
						eventId: event.eventId,
						marketIds,
					}, index + 1, data => current(commit, data));
				});
			}
		}
	},
	unsub ({ commit, state }) {
		if (!state.sport || !state.sport.events) {
			return;
		}
		const events = state.sport.events;
		if (events) {
			for (const key in events) { // eslint-disable-line
				const event = events[key];
				const marketConfig = state.getSportMarketIds()[event.sportId];
				let marketIds = [];
				marketConfig && marketConfig.forEach(market => {
					marketIds = marketIds.concat(market.marketIds);
				});
				// 订阅事件 1表示event 2表示market status 3表示market odds
				mt.forEach((current, index) => {
					pushTopic({
						sportId: event.sportId,
						productId: 3,
						categoryId: event.categoryId,
						tournamentId: event.tournamentId,
						eventId: event.eventId,
						marketIds,
					}, index + 1, undefined, false);
				});
			}
		}
	},
	subLive ({ commit, state }) {
		if (!state.live || !state.live.events) {
			return;
		}
		const events = state.live.events;
		if (events) {
			for (const key in events) { // eslint-disable-line
				const event = events[key];
				const marketConfig = state.getLiveMarketIds()[event.sportId];
				let marketIds = [];
				marketConfig && marketConfig.forEach(market => {
					marketIds = marketIds.concat(market.marketIds);
				});
				// 订阅事件 1表示event 2表示market status 3表示market odds
				liveMt.forEach((current, index) => {
					pushTopic({
						sportId: event.sportId,
						productId: 1,
						categoryId: event.categoryId,
						tournamentId: event.tournamentId,
						eventId: event.eventId,
						marketIds,
					}, index + 1, data => current(commit, data));
				});
			}
		}
	},
	unsubLive ({ commit, state }) {
		if (!state.live || !state.live.events) {
			return;
		}
		const events = state.live.events;
		if (events) {
			for (const key in events) { // eslint-disable-line
				const event = events[key];
				const marketConfig = state.getLiveMarketIds()[event.sportId];
				let marketIds = [];
				marketConfig && marketConfig.forEach(market => {
					marketIds = marketIds.concat(market.marketIds);
				});
				// 订阅事件 1表示event 2表示market status 3表示market odds
				liveMt.forEach((current, index) => {
					pushTopic({
						sportId: event.sportId,
						productId: 1,
						categoryId: event.categoryId,
						tournamentId: event.tournamentId,
						eventId: event.eventId,
						marketIds,
					}, index + 1, undefined, false);
				});
			}
		}
	},
};
