var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-sport-event" },
    [
      _c(
        "AfTableRow",
        {
          class: [
            "m-content-row",
            "title-row",
            {
              live: _vm.productId === 1
            }
          ]
        },
        [
          _vm.isShowDate
            ? _c("div", { staticClass: "clock-time" }, [
                _vm._v(
                  _vm._s(_vm.event.monthDate) +
                    " " +
                    _vm._s(_vm.event.hourMinute)
                )
              ])
            : _c("div", { staticClass: "clock-time" }, [
                _vm._v(_vm._s(_vm.event.hourMinute))
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "game-id" }, [
            _vm._v("ID: " + _vm._s(_vm.event.gameId || ""))
          ]),
          _vm._v(" "),
          _vm.isShowTournament
            ? _c("div", { staticClass: "tournament-name" }, [
                _vm._v(
                  _vm._s(_vm.event.categoryName) +
                    " - " +
                    _vm._s(_vm.event.tournamentName)
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "AfTableRow",
        { staticClass: "m-content-row match-row" },
        [
          _c("AfTableCell", { staticClass: "left-team-cell" }, [
            _c("div", { staticClass: "left-team-table" }, [
              _c(
                "div",
                {
                  staticClass: "teams",
                  attrs: {
                    title:
                      _vm.event.homeTeamName + " vs " + _vm.event.awayTeamName
                  },
                  on: {
                    click: function($event) {
                      _vm.jump(_vm.event)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "home-team" }, [
                    _vm._v(_vm._s(_vm.event.homeTeamName))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "away-team" }, [
                    _vm._v(_vm._s(_vm.event.awayTeamName))
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "icon-wrap" }, [
                _vm.isShowInplay
                  ? _c("img", {
                      staticClass: "inplay-icon",
                      attrs: { src: require("./img/inplay.png") }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isShowTV
                  ? _c("img", {
                      staticClass: "prelive-icon",
                      attrs: { src: require("./img/tv.png") }
                    })
                  : _vm._e()
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "AfTableCell",
            {
              class: [
                "market-cell",
                {
                  "two-markets": _vm.currentMarketIds.length === 2
                }
              ]
            },
            _vm._l(_vm.currentMarketIds, function(marketId, index) {
              return _c("Market", {
                key: index,
                staticClass: "market",
                attrs: {
                  productId: _vm.productId,
                  sportId: _vm.event.sportId || _vm.sportId,
                  marketId: marketId,
                  event: _vm.event,
                  isHideSelectSpecifiers: _vm.isHideSelectSpecifiers(marketId)
                }
              })
            })
          ),
          _vm._v(" "),
          _c(
            "AfTableCell",
            {
              staticClass: "market-size",
              nativeOn: {
                click: function($event) {
                  _vm.jump(_vm.event)
                }
              }
            },
            [
              _vm._v("+" + _vm._s(_vm.event.totalMarketSize)),
              _c("i", { staticClass: "icon-arrow-right" })
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }