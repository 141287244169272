var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-flexibet" },
    [
      _c("div", { staticClass: "m-flexi-desc" }, [
        _c("div", { staticClass: "m-flexi-tips" }, [
          _c("i", { staticClass: "m-icon-flexibet" }),
          _vm._v(" "),
          !_vm.betslipLoading && _vm.isInit && _vm.showFlexiTips
            ? _c("div", { staticClass: "m-pop" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "m-pop-main" }, [
                  _vm._v(_vm._s(_vm.flexiTips))
                ]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "m-icon-close",
                  on: { click: _vm.hideFlexiTips }
                })
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("span", [_vm._v("Options to be Correct")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "m-icon--tips" },
          [
            _c(
              "PopOver",
              {
                attrs: { position: "top", arrowCenter: false, isCenter: false }
              },
              [_c("p", [_vm._v(_vm._s(_vm.flexiDesc))])]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "m-flexi-help" }, [
        _c("p", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isMax,
              expression: "!isMax"
            }
          ],
          domProps: { innerHTML: _vm._s(_vm.getFlexiHelp) }
        })
      ]),
      _vm._v(" "),
      _vm.flexiUnavaliable
        ? [
            _c("div", { staticClass: "m-flexi-switch" }, [
              _c("span", { staticClass: "m-btn m-btn-sub m-btn--disabled" }),
              _vm._v(" "),
              _c("span", { staticClass: "m-current m-t" }, [
                _vm._v(_vm._s(_vm.flexLimit))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "m-btn m-btn-add m-btn--disabled" })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "m-flexi-note" }, [
              _c("p", { staticClass: "m-t-tips" }, [
                _vm._v(_vm._s(_vm.lockChgTips))
              ])
            ])
          ]
        : [
            _c("div", { staticClass: "m-flexi-switch" }, [
              _c("span", {
                class: [
                  "m-btn",
                  "m-btn-sub",
                  {
                    "m-btn--disabled": _vm.isMinDisabled
                  }
                ],
                on: {
                  click: function($event) {
                    _vm.handleChg("sub")
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  class: [
                    "m-current",
                    "m-t",
                    {
                      "m-t-light": _vm.isFlexiAutoChg
                    }
                  ]
                },
                [_vm._v(_vm._s(_vm.n) + _vm._s(_vm.isMax ? "" : "+"))]
              ),
              _vm._v(" "),
              _c("span", {
                class: [
                  "m-btn",
                  "m-btn-add",
                  {
                    "m-btn--disabled": _vm.isMaxDisabled
                  }
                ],
                on: {
                  click: function($event) {
                    _vm.handleChg("add")
                  }
                }
              }),
              _vm._v(" "),
              !_vm.isMax
                ? _c(
                    "span",
                    {
                      class: [
                        "m-max",
                        "m-t",
                        {
                          "m-t-light": _vm.isFlexiAutoChg
                        }
                      ]
                    },
                    [_vm._v("of " + _vm._s(_vm.flexiThreshold))]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "m-flexi-note" }, [
              _vm.errMsg
                ? _c("p", { staticClass: "m-t-err" }, [
                    _vm._v(_vm._s(_vm.errMsg))
                  ])
                : _vm.isFlexi && !_vm.isMax
                ? _c("p", { staticClass: "m-t-tips" }, [
                    _vm._v(_vm._s(_vm.flexiNote))
                  ])
                : _vm._e()
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-pop-title" }, [
      _c("i", { staticClass: "m-icon-flexibet" }),
      _vm._v(" "),
      _c("span", [_vm._v("Flex Your Bet !")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }