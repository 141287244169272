<template>
	<div class="betslip-setting">
		<div class="m-flex confirm-setting">
			<RadioButton class="left" :isActive="needConfirm === 1" @change="changeConfirm"/>
			<div class="right">{{$t('confirm:after:bet:label')}}</div>
		</div>
		<div class="other-setting-header m-flex" @click="isHide = !isHide">
			<div class="right">{{$t('other:option:label')}}</div>
			<i v-if="!isHide" class="m-icon arrow--up"/>
			<i v-else class="m-icon arrow--down"/>
		</div>
		<div class="setting-wrap" v-if="!isHide">
			<div class="setting-title">{{$t('when:odds:change:label')}}</div>
			<ul class="setting-list">
				<li class="setting-item" v-for="(item, i) in autoChangeConfig" :key="i">
					<i :class="['m-icon-check', {
						'm-icon-check--checked': autoAcceptChange === item.value,
						}]" @click="changeAutoAcceptChange(item.value)"/>
					<p class="setting-text">{{item.text}}</p>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import RadioButton from './radioButton.vue';

export default {
	components: {
		RadioButton
	},
	data() {
		return {
			autoChangeConfig: [{
				text: this.$t('accept:change1:text'),
				value: 2
			}, {
				text: this.$t('accept:change2:text'),
				value: 1
			}, {
				text: this.$t('accept:change3:text'),
				value: 0
			}],
			isHide: true
		};
	},
	computed: {
		...mapState('betslip', [
			'needConfirm',
			'autoAcceptChange'
		]),
		...mapState('betslipStake', [
			'showCurrency'
		])
	},
	methods: {
		...mapMutations('betslip', [
			'changeNeedConfirm',
			'changeAutoAcceptChange'
		]),
		changeConfirm(val) {
			this.changeNeedConfirm(val ? 1 : 0);
		}
	}
};
</script>

<style lang="less">
@import 'base/style/mixin.less';
@import 'base/style/variable.less';
@import 'base/style/icon.less';

.betslip-setting {
	font-size: 12px;
	border-top: 1px solid @cF2F3F5to67686D;
	.m-flex {
		display: flex;
		align-items: center;
		justify-content: center;
		.left {
			flex: 0 0 auto;
			margin-right: 6px;
		}
		.right {
			flex: 1 1 auto;
			color: @c353A45toFFFFFF;
		}
	}
	.confirm-setting {
		padding: 0 8px;
		line-height: 26px;
	}
	.other-setting-header {
		background: @cF2F3F5to3D4655;
		padding: 0 8px;
		line-height: 26px;
		color: @c000000toE9E9E9;
		.m-icon {
			&:before {
				font-size: 12px;
				color: @c000000toE9E9E9;
			}
			&.arrow--up {
				.m-icon-arrow--up();
			}
			&.arrow--down {
				.m-icon-arrow--down();
			}
		}
	}
	.setting-wrap {
		padding: 0 8px;
		color: @c000000toFFFFFF;
		.setting-title {
			line-height: 28px;
		}
		.setting-list {
			list-style: none;
			.setting-item {
				margin-top: 8px;
				display: flex;
				align-items: flex-start;
				justify-content: center;
				line-height: 16px;
				.m-icon-check {
					margin-right: 6px;
					flex: 0 0 auto;
					width: 13px;
					height: 13px;
					border: 1px solid @midGray;
					background: @white;
					border-radius: 2px;
					display: inline-block;

					&:hover {
						cursor: pointer;
					}
				}

				.m-icon-check--checked {
					background: @c09D5DEtoE1FF2F;
					border-color: @c09D5DEtoE1FF2F;
					.m-icon-success();

					&::before {
						color: @cFFFFFFto000000;
						// font-size: 12px;
						font-size: 11px;
						line-height: 13px;
						display: inline-block;
						vertical-align: top;
						font-weight: bold;
					}
				}
				.setting-text {
					flex: 1 1 auto;
				}
			}
		}
	}
}
</style>
