var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "m-betslips",
        {
          "m-betslips--green": _vm.isFlexi
        }
      ]
    },
    [
      _vm.betslipLoading
        ? [
            _c("div", { staticClass: "m-betslips-loading" }, [
              _c("div", [
                _c("i", { staticClass: "m-icon-loading" }),
                _vm._v(" "),
                _c("p", { staticClass: "m-text-msg" }, [
                  _vm._v(_vm._s(_vm.$t("loading:dot:label")))
                ])
              ])
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.hasBet
        ? [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showBetTab,
                    expression: "showBetTab"
                  }
                ],
                staticClass: "m-list-nav"
              },
              [
                _c(
                  "AfTable",
                  [
                    _c(
                      "AfTableRow",
                      _vm._l(_vm.betType, function(type) {
                        return _c(
                          "AfTableCell",
                          {
                            key: type,
                            class: {
                              "m-table-cell--active": _vm.currentType === type,
                              "m-table-cell-disabled":
                                (type === "multiple" && !_vm.supportMultiple) ||
                                (type === "system" && !_vm.supportSystem)
                            },
                            nativeOn: {
                              click: function($event) {
                                _vm.handleChangeTab(type)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(type === "single" ? "singles" : type) +
                                "\n\t\t\t\t\t\t"
                            ),
                            _vm.showFlexiLable(type)
                              ? _c("span", { staticClass: "m-label--flex" })
                              : _vm._e()
                          ]
                        )
                      })
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "m-opertaion",
                  {
                    "m-opertaion-fix": !_vm.showBetTab
                  }
                ]
              },
              [
                _c(
                  "AfTable",
                  [
                    _c(
                      "AfTableRow",
                      [
                        _c(
                          "AfTableCell",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showBankerTip,
                                expression: "showBankerTip"
                              }
                            ]
                          },
                          [
                            _c("RadioButton", {
                              attrs: { isActive: _vm.hasBanker },
                              on: { change: _vm.changeBankerStatus }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                class: [
                                  "m-banker-text",
                                  {
                                    on: _vm.hasBanker
                                  }
                                ]
                              },
                              [_vm._v(_vm._s(_vm.$t("banker:label")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "m-icon--tips" },
                              [
                                _c(
                                  "PopOver",
                                  { attrs: { position: "bottom" } },
                                  [
                                    _c("h4", { staticClass: "popover-title" }, [
                                      _vm._v(_vm._s(_vm.$t("banker:mean:text")))
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(_vm.$t("banker:mean1:text"))
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("AfTableCell", { staticClass: "m-align--right" }, [
                          _c(
                            "span",
                            {
                              staticClass: "m-text-min",
                              on: { click: _vm.handleClear }
                            },
                            [_vm._v(_vm._s(_vm.$t("delete:all:label")))]
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "m-list",
                  {
                    "m-list-fix": _vm.showBankerBar
                  }
                ]
              },
              _vm._l(_vm.getBetslips, function(slip) {
                return _c("BetslipsList", {
                  key: slip.key,
                  attrs: {
                    slip: slip,
                    showBankerBar: _vm.showBankerBar,
                    isBanker: _vm.isBanker(slip),
                    showCheckBox: _vm.showCheckbox,
                    checked: _vm.getOutcomeChecked(slip)
                  }
                })
              })
            ),
            _vm._v(" "),
            _c("div", { staticClass: "m-stake" }, [_c("BetslipsStake")], 1),
            _vm._v(" "),
            _c("div", { staticClass: "m-share--wrapper" }, [
              _c("div", [
                _c("span", { on: { click: _vm.handleShare } }, [
                  _vm._v(_vm._s(_vm.$t("betslip:share:label")))
                ])
              ])
            ])
          ]
        : [_c("SearchBet")]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }