<template lang="html">
	<div style="min-height: 100%">
		<div class="skeleton" v-if="showSkeleton">
			<slot name="skeleton"></slot>
		</div>
		<template>
			<div class="s-header" v-if="!showSkeleton">
				<Header v-if="hadHead"/>
				<TopNav :value="navValue"/>
				<NavBar :fetchSizeParams="fetchSizeParams"/>
			</div>
			<div :class="['s-page', {'hide-page':!showPage, 'show-page':showPage}]">
				<slot name="main-pagelet"></slot>
				<!-- <GoTop v-if="showGoTop" /> -->
			</div>
		</template>
	</div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import Header from './pagelet/header.vue';
import TopNav from './pagelet/topNav.vue';
import NavBar from './pagelet/navBar.vue';

import opConfig from 'config/opConfig';
import { opLogin } from 'core/js/loginBar';
import { invokeLoginFailed, handleMessage, readyLogin } from 'login/index'

import { LS } from 'storage';

import { EventBus } from 'kernel/js/eventBus';
import commonEvent from 'config/eventConfig/commonEvent';

import {
	CHANGE_SKELETON_LOADING
} from 'store/layout/mutationTypes';

export default {
	name: 'Page',
	componentName: 'Page',
	components: {
		Header,
		TopNav,
		NavBar
	},
	props: {
		showGoTop: {
			type: Boolean,
			'default': true
		},
		navValue: {
			type: String,
			'default': ''
		},
		fetchSizeParams: {
			type: Object,
			'default': () => ({})
		}
	},
	data() {
		return {
			opConfig,
			childComponents:[], //所有需要记录状态的child 都需要注册到这里 {'组件名+随机数': 状态值【false/true】}
			loadedChildComponents: [],
		};
	},
	computed: {
		...mapState('layout', [
			'showSkeleton'
		]),
		hadHead() { // 是否展示头部
			return window.headShow;
		},
		allChildComponentsLoaded() {
			console.log(this.loadedChildComponents, this.childComponents);
			if (this.loadedChildComponents.length === 0 || this.childComponents.length === 0){
				return false;
			}
			return this.loadedChildComponents.length === this.childComponents.length;
		},
		showPage() {
			return !this.showSkeleton;
		}
	},
	watch: {
		allChildComponentsLoaded(value) {
			if (value) {
				// 所有子组件都已经加载完毕，隐藏骨架屏
				this.skeletonLoading(false)
			} else {
				this.skeletonLoading(true)
			}
		}
	},
	created() {
		this.setTheme();
		//默认执行骨架屏loading状态初始化
		this.skeletonLoading(true);
		EventBus.$on(commonEvent.SKELETON_COMPONENT_REGISTER, () => {
			// 子组件注册
			this.childComponents.push(true)
		})
		EventBus.$on(commonEvent.SKELETON_COMPONENT_LOADED, () => {
			// 子组件加载完成记录
			this.loadedChildComponents.push(true)
		})
	},
	mounted() {
		window.onmessage = (e) => {
			handleMessage(e, this)
		}
		setTimeout(()=>{
			readyLogin()
		}, 300)
	},
	methods:{
		...mapMutations('layout', {
			skeletonLoading: CHANGE_SKELETON_LOADING
		}),
		setTheme() {
			document.body.className = `${window.theme}`
		},
		handleLogin(loginData) {
			if (!loginData.uid || !loginData.token) {
				return
			}
			opLogin(window.op, loginData.token, loginData.uid).then(data => {
				if(!data.login){
					invokeLoginFailed()
				}
			});
		},
		handleLoginOut(){
			fetch('/user/logout', {
				method: 'DELETE'
			}).then(res => res.json()).then(data => {
				const code = data.code;
				if (code === 10000) {
					LS.remove('accessToken');
					LS.remove('refreshToken');
					LS.remove('userId');
					LS.remove('phone');
				}
			});
		}
	}
};
</script>

<style lang="less">
@import 'base/style/variable.less';
@media print{
	.s-page-header,.s-page-bottom{
		display: none !important;
	}
}
.hide-page{
	display: none;
}
.show-page{
	display: block;
}
.skeleton {
	z-index: 999;
	background-color: @cFFFFFFto06182F;
}
.s-page{
	min-height: 100%;
	min-height: 100vh;

	.s-page__main{
		min-height: 80%;
		min-height: 80vh;
		height: 80%;
	}
}
</style>
