export function invokeLogin(callback) {
	switch (window.op) {
	case 'lottonowicebrl':
	case 'lottonowkoousdt':
	case 'lottonowkonekrw':
	case 'lottonowponeclubphp':
	case 'lottonowmoneclubmxn':
	case 'lottonow12winmyr':
	case 'lottonowwinspaceksh':
	case 'lottonowarsbetars':
	case 'lottonowioneclubidr':
		console.log('invokeLogin');
		window.parent.postMessage('GoLogin', '*');
		break;
	default:
		callback();

	}
}

export function invokeLoginFailed() {
	// eslint-disable-next-line default-case
	switch (window.op) {
	case 'lottonowicebrl':
	case 'lottonowkoousdt':
	case 'lottonowkonekrw':
	case 'lottonowponeclubphp':
	case 'lottonowmoneclubmxn':
	case 'lottonow12winmyr':
	case 'lottonowwinspaceksh':
	case 'lottonowarsbetars':
	case 'lottonowioneclubidr':
		console.log('invokeLoginFailed');
		window.parent.postMessage('LoginFailed', '*');
		break;
	}
}

export function invokeResetUser() {
	// eslint-disable-next-line default-case
	switch (window.op) {
	case 'lottonowicebrl':
	case 'lottonowkoousdt':
	case 'lottonowkonekrw':
	case 'lottonowponeclubphp':
	case 'lottonowmoneclubmxn':
	case 'lottonow12winmyr':
	case 'lottonowwinspaceksh':
	case 'lottonowarsbetars':
	case 'lottonowioneclubidr':
		console.log('invokeResetUser');
		window.parent.postMessage('ReSetUser', '*');
		break;
	}
}

export function readyLogin() {
	// eslint-disable-next-line default-case
	switch (window.op) {
	case 'lottonowicebrl':
	case 'lottonowkoousdt':
	case 'lottonowkonekrw':
	case 'lottonowponeclubphp':
	case 'lottonowmoneclubmxn':
	case 'lottonow12winmyr':
	case 'lottonowwinspaceksh':
	case 'lottonowarsbetars':
	case 'lottonowioneclubidr':
		window.parent.postMessage('ReadyLogin', '*');
		break;
	}
}

export function handleMessage(e, that) {
	if (!(e && e.data)) {
		return;
	}
	if (e.origin === 'http://staging.acinggaming.net') return;

	let data = e.data;
	try {
		data = JSON.parse(e.data);
		// eslint-disable-next-line no-empty
	} catch (e) {}

	if (data instanceof Object) {
		console.log('doLogin');
		that.handleLogin(data);
	}
	// eslint-disable-next-line default-case
	switch (e.data) {
	case 'Logout':
		that.handleLoginOut();
		break;
	}
}

export default {
	invokeLogin
};

