var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-error-wrapper" }, [
    _vm.loading
      ? _c("div", [
          _c("i", { staticClass: "m-icon-load" }),
          _vm._v(" "),
          _c("p", { staticClass: "m-text-msg" }, [_vm._v("Loading…")])
        ])
      : _vm.loadError
      ? _c("div", [
          _c("p", { staticClass: "m-text-msg" }, [
            _vm._v("Loading failed, please try again later..")
          ])
        ])
      : _vm.isEmpty
      ? _c("div", [
          _c("p", { staticClass: "m-text-msg" }, [
            _vm._v("You have no available gifts at this time.")
          ])
        ])
      : _vm.loginError
      ? _c("div", [
          _c("p", { staticClass: "m-text-msg" }, [
            _vm._v("Please "),
            _c(
              "span",
              {
                staticClass: "m-text-highlight",
                on: { click: _vm.handleLogin }
              },
              [_vm._v("Login")]
            ),
            _vm._v(" to check available gifts.")
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }