<template>
<div class="m-header-bar">
	<div class="m-header-content">
		<div class="m-logo-title"><span @click="goHome">{{$t('home:label')}}</span></div>
		<i class="m-icon-search" @click="goToUrl()" v-if="false"/>
		<div class="m-divier" v-if="false"/>
		<div class="m-user-wrap" @click="toggleMe">
			<div class="left">
				<div>{{isLogin ? showPhone : $t('welcome:visitor')}}</div>
				<div>{{isLogin ? balance : 0}} {{currency}}</div>
			</div>
			<i class="m-icon-arrow-down" v-if="!isShowMe" />
			<i class="m-icon-arrow-up" v-else />
			<div class="me-wrap" v-if="isShowMe">
				<div class="overlay" @click.stop="close" />
				<div class="menu-wrap">
					<ul class="menu-list">
						<li v-if="!isLogin" class="menu-item link" @click="goLogin">
							<div class="left">{{$t('login:label')}}</div>
							<i class="right" />
						</li>
						<li class="menu-item link" @click="goToUrl(betHistoryUrl, true)">
							<div class="left">{{$t('bet-history:label')}}</div>
							<i class="right" />
						</li>
						<li v-if="false" class="menu-item link" @click="goToUrl()">
							<div class="left">Transaction History</div>
							<i class="right" />
						</li>
						<li v-if="false" class="menu-item link" @click="goToUrl()">
							<div class="left">{{$t('setting:label')}}</div>
							<i class="right" />
						</li>
						<li v-if="isLogin" class="menu-item link" @click="goLogout">
							<div class="left">{{$t('logout:label')}}</div>
							<i class="right" />
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { LS } from 'storage';
import { EventBus } from 'kernel/js/eventBus';
import commonEvent from 'config/eventConfig/commonEvent';
import { getbalance, opLogin } from 'core/js/loginBar';
import {
	pagePath
} from 'config/pageConfig';

export default {
	name: 'Header',
	componentName: 'Header',
	data() {
		return {
			isShowMe: false,
			isLogin: window.loginStatus,
			currency: window.currency,
			homeUrl: pagePath.home,
			betHistoryUrl: pagePath.sportOrder,
			showPhone: '',
			balance: 0
		};
	},
	created() {
		let urlParams = new URLSearchParams(window.location.search);
		if (urlParams.has('op') && urlParams.has('token') && urlParams.has('uid')) {
			this.isLogin = false;
			window.loginStatus = false;
			opLogin(urlParams.get('op'), urlParams.get('token'), urlParams.get('uid')).then(data => {
				this.isLogin = data.login;
			});
		}
		if (this.isLogin) {
			this.fetchBalance();
			this.showPhone = LS.get('phone') || '';
		}
		EventBus.$on(commonEvent.UPDATE_LOGIN_STATUS, () => {
			this.isLogin = window.loginStatus;
			if (this.isLogin) {
				this.showPhone = LS.get('phone') || '';
				this.fetchBalance();
			}
		});
		EventBus.$on(commonEvent.SYNC_ACCOUNT_BALANCE, res => {
			this.balance = res.showVal || 0;
		});
		EventBus.$on(commonEvent.UPDATE_ACCOUNT_BALANCE, () => {
			this.fetchBalance();
		});
	},
	methods: {
		fetchBalance() {
			getbalance().then(res => {
				this.balance = res.showVal || 0;
			});
		},
		goLogin() {
			window.login({ activeTab: 'Login' });
		},
		goLogout() {
			fetch('/user/logout', {
				method: 'DELETE'
			}).then(res => res.json()).then(data => {
				const code = data.code;
				if (code === 10000) {
					LS.remove('accessToken');
					LS.remove('refreshToken');
					LS.remove('userId');
					LS.remove('phone');
					location.href = pagePath.home;
				}
			});
		},
		close() {
			this.isShowMe = false;
		},
		toggleMe() {
			this.isShowMe = !this.isShowMe;
		},
		goHome() {
			window.location.href = this.homeUrl;
		},
		goToUrl(url, needLogin) { // 第二个参数登录校验
			if (url) {
				if (needLogin && !this.isLogin) {
					window.login({ activeTab: 'Login' });
				} else {
					window.location.href = url;
				}
			} else {
				this.$dialog({
					title: null,
					width: 400,
					content: 'Coming soon...',
					button: ['OK']
				});
			}
			// window.location.href = URL.addPara(url || this.homeUrl, {
			// 	source: 'me'
			// });
		}
	}
};
</script>

<style lang="less" scoped>
@import 'base/styles/variable.less';
@import 'base/styles/icon.less';
@keyframes slide-out {
	0% {
		transform: scale(1, 0);
	}
	75% {
		transform: scale(1, 1.2);
	}
	100% {
		transform: scale(1, 1);
	}
}

.m-header-bar {
    height: 46px;
    background-color: #F98500;
    color: @white;
    .m-header-content {
        width: 1000px;
        margin: 0 auto;
        min-width: 1000px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .m-logo-title {
        flex: 1;
        text-align: left;
        font-size: 26px;
        line-height: 46px;
        span {
            cursor: pointer;
        }
    }
    .m-icon-search {
        cursor: pointer;
        .m-icon-search();
        &:before {
            font-size: 16px;
            color: @white;
        }
    }
		.m-divier {
			margin-left: 20px;
			width: 1px;
			height: 24px;
			background-color: @white;
		}
    .m-user-wrap {
        position: relative;
        cursor: pointer;
        box-sizing: content-box;
        // flex: 0 0 140px;
        width: 140px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        .left {
            flex: 1;
            overflow: hidden;
            div {
                text-align: right;
                font-size: 12px;
                line-height: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &:first-of-type {
                    margin-bottom: 6px;
                }
            }
        }
        .m-icon-arrow-down {
            flex: 0;
            margin-left: 12px;
            .m-icon-arrow--down();
            &:before {
                font-size: 12px;
                color: @white;
            }
        }
        .m-icon-arrow-up {
            flex: 0;
            margin-left: 12px;
            .m-icon-arrow--up();
            &:before {
                font-size: 12px;
                color: @white;
            }
        }

        .me-wrap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: transparent;
            z-index: 100;
            animation: slide-out 0.3s;
            transform-origin: top;

            .overlay {
                height: 100%;
                width: 100%;
                z-index: -1;
                opacity: 0;
                top: 0;
                left: 0;
                position: fixed;
                cursor: initial;
            }
            .menu-list {
                position: absolute;
                top: 46px;
                right: 0;
                width: 140px;
                background-color: #F98500;;
                color: @white;
                list-style: none;
                text-align: left;
				border: 1px solid @white;
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
                .menu-title {
					padding: 0 8px;
                    white-space: nowrap;
                    text-align: left;
                    text-overflow: ellipsis;
                    overflow: hidden;
					&.last {
						border-bottom: 1px solid #EEEEEE;
						}
                }
                .menu-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
					line-height: 32px;
					padding: 0 8px;
					border-bottom: 1px solid #EEEEEE;
					&:last-of-type {
						border: none;
					}
					&:hover {
						background: #ED7534;
					}
                    &.link {
                        cursor: pointer;
                    }
                    .left {
                        flex: 1 1 auto;
                        white-space: nowrap;
                        text-align: left;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .right {
                        .m-icon-arrow--right();
                    }
                }
            }
        }
    }
}
</style>
