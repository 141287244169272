var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "af-select",
        { disable: _vm.disable },
        { "af-select-open": _vm.isShowList }
      ],
      on: { blur: _vm.close }
    },
    [
      _c("div", { staticClass: "af-select-title", on: { click: _vm.toggle } }, [
        !_vm.readonly
          ? _c("input", {
              ref: "input",
              staticClass: "af-select-input",
              attrs: { type: "input", placeholder: _vm.placeholder },
              domProps: { value: _vm.value.name || _vm.value },
              on: { input: _vm.inputChange }
            })
          : _c("span", { ref: "readInput", staticClass: "af-select-input" }, [
              _vm._v(_vm._s(_vm.value.name || _vm.value || "")),
              _vm.placeholder !== "" && !(_vm.value.name || _vm.value)
                ? _c("em", { staticClass: "placeholder" }, [
                    _vm._v(_vm._s(_vm.placeholder))
                  ])
                : _vm._e()
            ]),
        _vm._v(" "),
        _c("span", { staticClass: "af-select-icon-item" }, [
          _c("i", {
            class: [_vm.isShowList ? "m-icon-arrow-up" : "m-icon-arrow-down"]
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowList,
            expression: "isShowList"
          }
        ],
        staticClass: "overlay",
        on: { click: _vm.close }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }