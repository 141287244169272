
// 获取首页配置 (Popular 部分)
// export const UPDATE_HOME_PC = 'updateHomePC';

// 获取 important match 列表
export const UPDATE_IMPORTANT_MATCH = 'updateImportantMatch';

// 获取 live match 列表
export const UPDATE_LIVE_MATCH = 'updateLiveMatch';

export const UPDATE_CURRENT_GROUP = 'updateCurrentGroup';
export const UPDATE_CURRENT_LIVE_GROUP = 'updateCurrentLiveGroup';
// 获取推荐比赛
export const FETCH_RECOMMEND_EVENTS = 'fetchRecommendEvents';
// 推荐比赛推送
export const UPDATE_RECOMMEND_EVENT = 'UPDATE_RECOMMEND_EVENT';
export const UPDATE_RECOMMEND_ODDS = 'UPDATE_RECOMMEND_ODDS';
export const UPDATE_RECOMMEND_MARKET = 'UPDATE_RECOMMEND_MARKET';

// 推荐比赛loading
export const RECOMMEND_LOADING = 'recommendLoading';

// 推送相关
export const UPDATE_EVENT = 'updateEvent';
export const UPDATE_OUTCOME = 'updateOutcome';
export const UPDATE_MARKET = 'updateMarket';

// 更新sport和live的推送
export const UPDATE_SPORT_EVENT = 'updateSportEvent';
export const UPDATE_SPORT_OUTCOME = 'updateSportOutcome';
export const UPDATE_SPORT_MARKET = 'updateSportMarket';
export const UPDATE_LIVE_EVENT = 'updateLiveEvent';
export const UPDATE_LIVE_OUTCOME = 'updateLiveOutcome';
export const UPDATE_LIVE_MARKET = 'updateLiveMarket';

// productstatus 更新
export const UPDATE_SPORT_PRODUCT_STATUS = 'updateSportProductStatus';
export const UPDATE_LIVE_PRODUCT_STATUS = 'updateLiveProductStatus';
export const UPDATE_RECOMMEND_PRODUCT_STATUS = 'updateRecommendProductStatus';
