<template>
	<div class="m-event-recommend">
		<a :href="sportLink" :class="['m-sport-link']">
			<div class="m-header">
				<img v-if="event.oddsBoost" class="m-sport-icon hot" src="./image/best-odds.png"/>
				<img v-else class="m-sport-icon" :src="sportIconMap[event.sportId]"/>
				<span class="m-tournament">{{event.categoryName}} - {{event.tournamentName}}</span>
<!--				<span class="m-time">{{formateSportDate(event.monthDate)}}</span>-->
<!--				<span class="m-date">{{event.hourMinute}}</span>-->
			</div>
			<div class="m-content">
				<span class="m-span-side m-team">
					<img class="m-team-logo" :src="event.homeTeamIcon">
					<div>{{event.homeTeamName}}</div>
				</span>
				<span class="m-span">
					{{formateSportDate(event.monthDate)}}<br>{{event.hourMinute}}
				</span>
				<span class="m-span-side m-team">
					<img class="m-team-logo" :src="event.awayTeamIcon">
					<div>{{event.awayTeamName}}</div>
				</span>
			</div>
		</a>

		<div class="m-recommend-outcomes">
			<template v-if="market">
				<OutcomeCell
					v-for="(id, index) in market.outcomeOrder"
					:key="index"
					:sportId="event.sportId"
					:market="market"
					:event="event"
					:disabled="disabled"
					:outcome="market.outcomes[id]">
					<template v-if="market.status !== 0 || market.outcomes[id].isActive !== 1">
						<span class="m-icon-lock"></span>
					</template>

					<template v-else>
						<div class="desc">{{marketTitles[index]}}</div>
						<div class="odds">{{oddsTrans(market.outcomes[id].odds, oddsType)}}</div>
					</template>
				</OutcomeCell>
			</template>

			<!-- 有一种情况是后台压根没有传递market，但是这个market还是要显示,所以取固定数据显示这个market -->
			<template v-else>
				<template v-if="marketTitles">
					<div class="m-outcome--disabled m-outcome" v-for="one in marketTitles" :key="one">
						<span class="m-icon-lock"></span>
					</div>
				</template>
			</template>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import dateFormat from 'kernel/js/dateFormat';
import { baseUrl } from 'config/pageConfig';
import OutcomeCell from 'components/event/outcomeCell';
import { getSportListUpperCase, getSportConfigLowerCase } from 'config/sportsType';

export default {
	name: 'EventRecommend',
	components: {
		OutcomeCell
	},
	props: {
		event: {
			required: true,
			type: Object
		}
	},
	data() {
		return {
			sportListUpperCase: getSportListUpperCase(),
			sportMarketIdMap: { // 目前只推荐三个体育类型的比赛， 每种体育类型的marketId在这里写死配置
				'sr:sport:1': '1',
				'sr:sport:2': '219',
				'sr:sport:5': '186'
			}
		};
	},
	computed: {
		...mapState('betslip', ['oddsType']),
		sportId() {
			return this.event.sportId;
		},
		market () {
			const { markets = {}} = this.event;
			return markets[this.sportMarketIdMap[this.sportId]];
		},
		sportIconMap() {
			const map = {};
			this.sportListUpperCase.forEach(sport => {
				map[`sr:sport:${sport.id}`] = sport.icon;
			});
			return map;
		},
		marketTitles () {
			if (this.market && this.market.title) {
				return this.market.title.split(',');
			}
			return ['1', 'x', '2'];
		},
		sportConfigLowerCase() {
			return getSportConfigLowerCase();
		},
		sportLink () {
			const { sportId, categoryId, tournamentId, eventId, status } = this.event;
			let url = `${baseUrl}sport/${this.sportConfigLowerCase[sportId]}/live/${categoryId}/${tournamentId}/${eventId}`;
			// 比赛没有开始，跳转到 pre match的详情页面
			if (status === 0) {
				url = `${baseUrl}sport/${this.sportConfigLowerCase[sportId]}/${categoryId}/${tournamentId}/${eventId}`;
			}
			return url;
		},
		disabled () {
			/* 这里跟 components/event/market.vue里的不等于判断（“currentMarket.productId !== productId”）不一样,
				market.vue里的productId实际上为undefined,
				根据outcomeCell组件里的条件判断, 这里应该用等于来赋予其disabled先决条件
			*/
			// const productId = this.market && this.market.product;
			// return this.isLive ? productId === 1 : productId === 3;
			return true;
		},
	},
	methods: {
		formateSportDate (currentDate) {
			const today = dateFormat.format(Date.now(), 'DD/MM');
			if (today === currentDate) {
				return 'Today';
			}
			return currentDate;
		}
	},
	mounted() {
	}
};
</script>


<style lang="less">
@import '~base/style/variable.less';
@import '~base/style/mixin.less';
@import '~base/style/icon.less';

.m-event-recommend {
  display: inline-block;
  width: 240px;
  vertical-align: center;
	padding: 12px 10px 14px 12px;
	margin: 0 5px;
	background-color: @navBgColor;
	box-sizing: border-box;
	border: 1px solid @lightGrayBlue;
	box-shadow: 2px 1px 2px @lightGrayBlue;


	.m-sport-link {
		text-decoration: none;
		.m-header {
			padding: 0 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 16px;
			font-size: 12px;
			color: @darkGrayTextColor;
			text-align: left;
			.m-sport-icon {
				flex: 0 0 auto;
				width: 12px;
				height: 12px;
				margin-right: 4px;
				&.hot{
					width: 80px;
					height: 15px;
					margin-top: -3px;
				}
			}
			.m-tournament {
				margin-right: 6px;
				flex: 1 1 auto;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				color: @navTitleColor;
			}
			.m-date {
				margin-left: 6px;
			}
		}
		.m-content {
			//padding: 0 6px;
			margin: 14px 0;
			color: @dark2White;
			font-size: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			.m-span-side{
				width: 34%;
			}
			.m-span-mid {
				width: 32%;
			}
			.m-span{
				//-webkit-flex: 1;
				//flex: 1;
				-webkit-justify-content: center;
				justify-content: center;
				-webkit-align-items: center;
				align-items: center;
				padding: 0 5px;
			}
			.m-team {
				color:@bannerTitleColor;
				flex: 1 1 auto;
				width: 80px;
				line-height: 16px;
				word-break: break-all;
				overflow : hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				white-space: normal;
			}
			.m-team-logo {
				//flex: 0 0 36px;
				width: 36px;
				height: 36px;
				//margin: 0 12px;
			}
		}
	}
	.m-recommend-outcomes {
		margin-top: 8px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.m-outcome {
			min-width: 33%;
			box-sizing: border-box;
			height: 40px;
			color: @DarkWhite;
			background: @buttonBgColor;
			margin: 0 1px;
			padding: 0 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: visible;

			.desc {
				font-size: 10px;
				font-weight: normal;
				position: absolute;
				top: -4px;
				line-height: 10px;
				left: 50%;
			}

			.odds {
				font-size: 14px;
				font-weight: bold;
			}
			&.m-outcome--checked {
				color: @white;
				background: @buttonActiveBgColor;
				.desc {
					color: @buttonOutcomeActiveColor;
				}
				&:before{
					color: @dark2White;
				}
				.odds {
					color: @buttonRateColor;
				}
			}
			//&:hover {
			//	background: @buttonOutcomeHoverColor;
			//}
			//&:active {
			//	background: @buttonOutcomeActiveColor;
			//}
			&:first-of-type {
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
			&:last-of-type {
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
			&.m-outcome--disabled {
				background: #E4E4E4;
				color: #989898;
				justify-content: center;
			}

			&--up {
				&:before{
					color: @green;
				}
			}
			&--down{
				&:before{
					color: @red;
				}
			}
		}
	}

	.m-icon-lock{
		.m-icon-locked();
		&:before{
			font-size: 16px;
		}
		vertical-align: middle;
	}
}

</style>
