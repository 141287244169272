var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    { attrs: { navValue: "Today", fetchSizeParams: _vm.fetchSizeParams } },
    [
      _c("template", { slot: "skeleton" }, [_c("skeleton")], 1),
      _vm._v(" "),
      _c(
        "MainSection",
        {
          attrs: { slot: "main-pagelet", rightWidth: "235px" },
          slot: "main-pagelet"
        },
        [
          _c(
            "div",
            { attrs: { slot: "top" }, slot: "top" },
            [_c("RecommendEvents")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "mid" }, slot: "mid" },
            [_c("live-match"), _vm._v(" "), _c("important-match")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "right" }, slot: "right" },
            [
              !_vm.showSkeleton
                ? _c("RightPagelet", { attrs: { showRegister: false } })
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }