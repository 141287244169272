var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "simple-select-wrap",
        _vm.showList ? "simple-select-wrap-expand" : ""
      ]
    },
    [
      _c(
        "div",
        {
          staticClass: "select-title",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.onOpenList($event)
            }
          }
        },
        [
          _vm._v(_vm._s(_vm.selectedValue) + "\n\t\t"),
          _c("span", {
            class: [
              "arrow-icon",
              _vm.showList ? "arrow-icon-up" : "arrow-icon-down"
            ]
          })
        ]
      ),
      _vm._v(" "),
      _vm.showList ? _c("div", { staticClass: "select-modal" }) : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showList,
              expression: "showList"
            }
          ],
          staticClass: "select-list"
        },
        _vm._l(_vm.data, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              class: [
                "select-item",
                index === _vm.selectedIndex ? "select-item-active" : ""
              ],
              attrs: { "data-index": index },
              on: { click: _vm.onSelectItem }
            },
            [_vm._v("\n\t\t\t" + _vm._s(item.label) + "\n\t\t")]
          )
        })
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }