<template>
  <div v-if="!showSkeleton" class="live-match">
    <div class="banner">
			<span class="text">
				<img src="./image/live.png"/>
				{{ $t('live-match:label') }}
			</span>
      <div v-show="!loading" class="config">
        <span class="refresh" @click="fetchData">{{ $t('refresh:label') }}</span>
      </div>
    </div>
    <div id="live-match">
      <div v-if="sportSize && sportSize.length" class="sport-name">
        <div
          v-for="sport in preSportSize"
          :key="sport.id"
          :class="['sport-name-item', {
						active: currentSportId === sport.id
					}]"
          @click="onChangeSport(sport.id)">
          <div class="text">{{ sport.name }}<span>{{ sport.eventSize > 99 ? '99+' : sport.eventSize }}</span>
          </div>
        </div>
        <div class="blank-block"/>
        <SimpleSelect v-if="postSportSize.length > 0"
                      ref="select"
                      :class="['sport-simple-select', isSelectedActive ? 'sport-simple-select-active' : '' ]" :data="postSportSize"
                      :placeholder="$t('more-game:label')" @selected="onSelectedSport"></SimpleSelect>
      </div>
      <div v-loading:fetchData.dark="loading">
        <template v-if="!loading">
          <div v-if="liveProductStatus === 1" class="m-sport-bet-error">
            {{ $t('data:load:fail') }}
          </div>
          <div v-else-if="!hasSports" class="m-sport-bet-no-data">
            <p>{{ $t('no:match:available:1') }}</p>
            <p>{{ $t('no:match:available:2') }}</p>
          </div>
          <div v-else class="match">
            <div class="flex-box">
              <div class="left">
                <div v-for="(group, i) in liveMarketIds[currentSportId]" v-if="group.value !== 'default'" :key="i"
                     :class="['market-group', {
									'active': currentLiveGroup === i
									}]" @mouseenter="changeGroup(i)">{{ group.name }}
                </div>
              </div>
              <div class="right">
                <div v-for="(market, i) in liveMarketIds[currentSportId][currentLiveGroup].marketIds"
                     :key="i"
                     class="market-block">
                  <div class="market-name">
                    {{ getMarketConfig(market, productId, currentSportId).name }}
                  </div>
                  <div class="outcome-name-list">
                    <div v-for="(title, i) in getMarketConfig(market, productId, currentSportId).titleArr" v-if="title"
                         :key="i"
                         class="outcome-name">{{ title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template v-for="(dateEvents, key) in live.eventOrder">
              <!-- 循环event -->
              <LiveEvent v-for="(eventId, i) in dateEvents"
                         :key="eventId"
                         :currentMarketIds="currentMarketIds"
                         :event="live.events[eventId]"
                         :productId="productId"
                         :sportId="currentSportId"/>
            </template>
            <div v-if="live" class="view-all-wrap">
              <div class="blank-block"/>
              <div class="view-all" @click="viewAllImportant">
                <i18n path="view:all:sport" tag="p">
                  <span place="sport">{{ $t('in-play:adj') }} {{ currentSportName }}</span>
                  <i place="match">{{ $tc('match:label', sportSizeMap[currentSportId]) }}
                    {{ sportSizeMap[currentSportId] }}</i>
                </i18n>
              </div>
              <i class="icon-arrow--right" @click="viewAllImportant"/>
            </div>
          </div>

        </template>
      </div>
    </div>
  </div>
</template>

<script>
import LiveEvent from 'components/eventList/liveEvent.vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import { isEmptyObject } from 'utils';
import { getSportConfigLowerCase, getIdToSport } from 'config/sportsType';
import { baseUrl } from 'config/pageConfig';
import { getMarketConfig } from 'config/marketConfig';
import { getTimeParam } from 'config/timeConfig';
import SimpleSelect from 'components/simpleSelect';
import 'components/loading';
import commonEvent from 'config/eventConfig/commonEvent';
import { EventBus } from 'kernel/js/eventBus';

export default {
	name: 'LiveMatch',
	components: {
		LiveEvent,
		SimpleSelect
	},
  computed: {
    ...mapState('layout', [
      'showSkeleton'
    ]),
    // 对象展开运算符
    ...mapState('home', [
      'live',
      'isViewAllLive',
      'getLiveMarketIds',
      'liveProductStatus',
      'currentLiveGroup'
    ]),
    ...mapState('liveSportSize', [
      'sportSize'
    ]),
    liveMarketIds () {
      return this.getLiveMarketIds();
    },
    currentMarketIds () {
      return this.liveMarketIds[this.currentSportId][this.currentLiveGroup].marketIds;
    },
    hasSports () {
      return this.live && this.live.eventOrder && !isEmptyObject(this.live.eventOrder);
    },
    sportSizeByOrder () {
      return this.sportSize;
    },
    sportLen () {
      return this.sportSizeByOrder.length;
    },
    preSportSize () {
      return this.sportLen > 6 ? this.sportSizeByOrder.slice(0, 5) : this.sportSizeByOrder;
    },
    postSportSize () {
      const sizes = this.sportLen > 6 ? this.sportSizeByOrder.slice(5) : [];
      return sizes.map(item => ({
        ...item,
        label: item.name
      }));
    },
    postSportId () {
      return this.postSportSize.map(item => item.id);
    },
    isSelectedActive () {
      return this.postSportId.includes(this.currentSportId);
    },
    idToSport () {
      return getIdToSport();
    },
    sportConfigLowerCase () {
      return getSportConfigLowerCase();
    },
    currentSportName () {
      return this.currentSportId ? this.idToSport[this.currentSportId].title : '';
    },
    sportSizeMap () {
      const map = {};
      this.sportSize.forEach(sport => {
        map[sport.id] = sport.eventSize || 0;
      });
      return map;
    }
  },
  data () {
    return {
      productId: 1,
      // 当前运动类型
      currentSportId: '',
      loading: false,
      appLoaded: false, //如果是骨架屏，该值为true，则不触发骨架屏展示
    };
  },
  // 钩子
  mounted () {
    this.fetchData();
  },
  methods: {
    ...mapMutations('home', ['updateCurrentLiveGroup']),
    ...mapActions('liveSportSize', ['fetchSportSize']),
    ...mapActions('home', [
      'fetchLiveOrPrematchEvents',
    ]),
    getMarketConfig,
    hasEvent (res) {
      if (!res) {
        return false;
      }
      return !isEmptyObject(res.events);
    },
    changeSport (id) {
      this.currentSportId = id;
      this.fetchData();
    },
    changeGroup (i) {
      this.updateCurrentLiveGroup(i);
    },
    // importantMatch -- 请求不同的 sportId 下的比赛
    fetchData () {
      if (!this.appLoaded) {
        EventBus.$emit(commonEvent.SKELETON_COMPONENT_REGISTER)
      }
      this.loading = true;
      return this.fetchSportSize({
        productId: this.productId,
        option: 1,
        ...getTimeParam({
          // value: 'today'
        })
      })
        // 默认选中第一个
        .then(data => {
          // 拦截 运动
          // data = data.filter(sport => limitSportId.includes(sport.id));
          if (data && data.length) {
            if (!this.currentSportId || !data.find(cur => cur.id === this.currentSportId)) {
              this.currentSportId = data[0].id;
            }
          } else {
            this.currentSportId = null;
          }
        })
        .then(() => {
          if (this.currentSportId) {
            return this.fetchLiveOrPrematchEvents({
              sportId: this.currentSportId,
              marketId: this.getMarketIdsBySportId(this.currentSportId)
            });
          }
        })
        // 如果请求被打断，则不处理成加载完成
        .then(res => {
          if (!res || !res.abort) {
            this.loading = false;
          }
        })
        .catch(res => {
          if (!res || !res.abort) {
            this.loading = -1;
          }
        }).finally(() => {
          if (!this.appLoaded) {
            EventBus.$emit(commonEvent.SKELETON_COMPONENT_LOADED)
            this.appLoaded = true
          }
        });
    },
    getMarketIdsBySportId (sportId) {
      const markets = this.liveMarketIds[sportId];
      let arr = [];
      markets.forEach(market => {
        arr = arr.concat(market.marketIds);
      });
      return arr.join(',');
    },
    // 跳到 Sports -- Game List页面
    viewAllImportant () {
      location.href = `${baseUrl}sport/${this.sportConfigLowerCase[this.currentSportId.slice(9)]}/live_list/`;
    },
    onSelectedSport (payload) {
      const id = payload.item.id;
      this.changeSport(id);
    },
    onChangeSport (id) {
      this.changeSport(id);
      const $select = this.$refs.select;
      $select && $select.clear();
    }
  }
};
</script>

<style lang="less" scoped>
@import '~base/style/variable.less';
@import '~base/style/mixin.less';

.flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: @e9Gray;
  border-bottom: 1px solid #49525F;

  .left {
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 0;

    .market-group {
      flex: 0 0 auto;
      padding: 0 8px;
      white-space: nowrap;
      line-height: 18px;
      font-size: 12px;
      border-right: 1px solid #49525F;
      cursor: pointer;

      &.active {
        color: @c09D5DEtoE1FF2F;
        border-color: @c09D5DEtoE1FF2F;
      }
    }
  }

  .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding-right: 49px;

    .market-block {
      flex: 1;
      line-height: 24px;

      .market-name {
        width: 100%;
        text-align: center;
      }

      .outcome-name-list {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .outcome-name {
        flex: 1;
        text-align: center;
      }
    }
  }
}
</style>

<style lang="less">
@import '~base/style/variable.less';
@import '~base/style/mixin.less';
@import 'base/style/icon.less';

.live-match {
  #live-match {
    .match {
      .match-row {
        display: flex;
        align-items: center;
        justify-content: center;

        .left-team-cell {
          flex: 0 0 230px;
        }

        .market-size {
          margin-left: 6px;
          flex: 0 0 43px;
          white-space: nowrap;
        }

        .market-cell {
          display: flex;
          align-items: center;
          justify-content: center;

          .m-market {
            flex: 1 1 auto;
            margin-right: 1px;
          }
        }
      }
    }
  }
}

#live-match {
  .sport-name {
    display: flex;
    width: 725px;
    box-sizing: border-box;

    // justify-content: space-around;
    .blank-block {
      flex: 1 1 auto;
    }

    .sport-simple-select {
      height: 48px;
      line-height: 48px;
      color: #FFF;
      width: 158px;
      font-size: 14px;
      text-align: left;
      margin-bottom: -1px;
      background-color: @liveDark;

      &:hover {
        border-bottom: 4px solid #BEEDFF;
      }

      .select-title {
        padding: 0 20px;

        .arrow-icon {
          background-color: @liveDark;
        }

        background-color: @liveDark;
      }

      .select-list {
        background-color: @liveDark;
      }

      .select-item {
        padding: 0 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .sport-simple-select-active {
      border-bottom: 4px solid @c09D5DEtoE1FF2F;
    }
  }
}
</style>
