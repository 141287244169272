import { isAType, isBType, isCType } from 'config/marketConfig';
import AType from './markets/aType';
import BType from './markets/bType';
import CType from './markets/cType';
import Market from './markets/market';

export default {
	functional: true,
	render (h, context) {
		const { props } = context;
		// productId: 1(直播),3(赛前)
		const marketId = +props.marketId;
		// const productId = +props.productId;
		if (isAType(marketId)) {
			return h(AType, context.data);
		}
		if (isBType(marketId)) {
			return h(BType, context.data);
		}
		if (isCType(marketId)) { // 需要特殊specifier逻辑的都放这里
			return h(CType, context.data);
		}
		return h(Market, context.data);
	}
};
