var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-event-recommend" }, [
    _c("a", { class: ["m-sport-link"], attrs: { href: _vm.sportLink } }, [
      _c("div", { staticClass: "m-header" }, [
        _vm.event.oddsBoost
          ? _c("img", {
              staticClass: "m-sport-icon hot",
              attrs: { src: require("./image/best-odds.png") }
            })
          : _c("img", {
              staticClass: "m-sport-icon",
              attrs: { src: _vm.sportIconMap[_vm.event.sportId] }
            }),
        _vm._v(" "),
        _c("span", { staticClass: "m-tournament" }, [
          _vm._v(
            _vm._s(_vm.event.categoryName) +
              " - " +
              _vm._s(_vm.event.tournamentName)
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "m-content" }, [
        _c("span", { staticClass: "m-span-side m-team" }, [
          _c("img", {
            staticClass: "m-team-logo",
            attrs: { src: _vm.event.homeTeamIcon }
          }),
          _vm._v(" "),
          _c("div", [_vm._v(_vm._s(_vm.event.homeTeamName))])
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "m-span" }, [
          _vm._v(
            "\n\t\t\t\t\t" + _vm._s(_vm.formateSportDate(_vm.event.monthDate))
          ),
          _c("br"),
          _vm._v(_vm._s(_vm.event.hourMinute) + "\n\t\t\t\t")
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "m-span-side m-team" }, [
          _c("img", {
            staticClass: "m-team-logo",
            attrs: { src: _vm.event.awayTeamIcon }
          }),
          _vm._v(" "),
          _c("div", [_vm._v(_vm._s(_vm.event.awayTeamName))])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "m-recommend-outcomes" },
      [
        _vm.market
          ? _vm._l(_vm.market.outcomeOrder, function(id, index) {
              return _c(
                "OutcomeCell",
                {
                  key: index,
                  attrs: {
                    sportId: _vm.event.sportId,
                    market: _vm.market,
                    event: _vm.event,
                    disabled: _vm.disabled,
                    outcome: _vm.market.outcomes[id]
                  }
                },
                [
                  _vm.market.status !== 0 ||
                  _vm.market.outcomes[id].isActive !== 1
                    ? [_c("span", { staticClass: "m-icon-lock" })]
                    : [
                        _c("div", { staticClass: "desc" }, [
                          _vm._v(_vm._s(_vm.marketTitles[index]))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "odds" }, [
                          _vm._v(
                            _vm._s(
                              _vm.oddsTrans(
                                _vm.market.outcomes[id].odds,
                                _vm.oddsType
                              )
                            )
                          )
                        ])
                      ]
                ],
                2
              )
            })
          : [
              _vm.marketTitles
                ? _vm._l(_vm.marketTitles, function(one) {
                    return _c(
                      "div",
                      {
                        key: one,
                        staticClass: "m-outcome--disabled m-outcome"
                      },
                      [_c("span", { staticClass: "m-icon-lock" })]
                    )
                  })
                : _vm._e()
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }