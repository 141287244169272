var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-header-bar" }, [
    _c("div", { staticClass: "m-header-content" }, [
      _c("div", { staticClass: "m-logo-title" }, [
        _c("span", { on: { click: _vm.goHome } }, [
          _vm._v(_vm._s(_vm.$t("home:label")))
        ])
      ]),
      _vm._v(" "),
      false
        ? _c("i", {
            staticClass: "m-icon-search",
            on: {
              click: function($event) {
                _vm.goToUrl()
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      false ? _c("div", { staticClass: "m-divier" }) : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "m-user-wrap", on: { click: _vm.toggleMe } }, [
        _c("div", { staticClass: "left" }, [
          _c("div", [
            _vm._v(
              _vm._s(_vm.isLogin ? _vm.showPhone : _vm.$t("welcome:visitor"))
            )
          ]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              _vm._s(_vm.isLogin ? _vm.balance : 0) + " " + _vm._s(_vm.currency)
            )
          ])
        ]),
        _vm._v(" "),
        !_vm.isShowMe
          ? _c("i", { staticClass: "m-icon-arrow-down" })
          : _c("i", { staticClass: "m-icon-arrow-up" }),
        _vm._v(" "),
        _vm.isShowMe
          ? _c("div", { staticClass: "me-wrap" }, [
              _c("div", {
                staticClass: "overlay",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.close($event)
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "menu-wrap" }, [
                _c("ul", { staticClass: "menu-list" }, [
                  !_vm.isLogin
                    ? _c(
                        "li",
                        {
                          staticClass: "menu-item link",
                          on: { click: _vm.goLogin }
                        },
                        [
                          _c("div", { staticClass: "left" }, [
                            _vm._v(_vm._s(_vm.$t("login:label")))
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "right" })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticClass: "menu-item link",
                      on: {
                        click: function($event) {
                          _vm.goToUrl(_vm.betHistoryUrl, true)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "left" }, [
                        _vm._v(_vm._s(_vm.$t("bet-history:label")))
                      ]),
                      _vm._v(" "),
                      _c("i", { staticClass: "right" })
                    ]
                  ),
                  _vm._v(" "),
                  false
                    ? _c(
                        "li",
                        {
                          staticClass: "menu-item link",
                          on: {
                            click: function($event) {
                              _vm.goToUrl()
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "left" }, [
                            _vm._v("Transaction History")
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "right" })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  false
                    ? _c(
                        "li",
                        {
                          staticClass: "menu-item link",
                          on: {
                            click: function($event) {
                              _vm.goToUrl()
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "left" }, [
                            _vm._v(_vm._s(_vm.$t("setting:label")))
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "right" })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isLogin
                    ? _c(
                        "li",
                        {
                          staticClass: "menu-item link",
                          on: { click: _vm.goLogout }
                        },
                        [
                          _c("div", { staticClass: "left" }, [
                            _vm._v(_vm._s(_vm.$t("logout:label")))
                          ]),
                          _vm._v(" "),
                          _c("i", { staticClass: "right" })
                        ]
                      )
                    : _vm._e()
                ])
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }