var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "congratulation-dialog" }, [
    _c("div", { staticClass: "con-content-wrap" }, [
      _c("div", { staticClass: "con-tip" }, [_vm._v("Received a Cash Gift !")]),
      _vm._v(" "),
      _c("div", { staticClass: "con-cash-card" }, [
        _c("div", { staticClass: "cash-amount" }, [
          _c("p", { staticClass: "cash-condition" }, [_vm._v("On Any Stake")]),
          _vm._v(" "),
          _c("span", { staticClass: "cash-currency" }, [
            _vm._v(_vm._s(_vm.currency))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "cash-number" }, [
            _vm._v(_vm._s(_vm.amount))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "cash-type" }, [
            _vm._v("Use it in your Betslip")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "m-gifts-desc-wrapper" }, [
        _c("div", { staticClass: "m-gifts-desc" }, [
          _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("p", { staticClass: "description" }, [_vm._v(_vm._s(_vm.srcCtt))])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "m-btn-wrapper" },
          [
            _c(
              "af-Button",
              {
                staticClass: "m-btn-gift",
                attrs: {
                  extraType: "text",
                  "data-action": "close",
                  "data-ret": "gift"
                },
                on: { click: _vm.goGifts }
              },
              [_vm._v("View")]
            ),
            _vm._v(" "),
            _c(
              "af-Button",
              {
                staticClass: "m-btn-bet",
                attrs: {
                  extraType: "text",
                  "data-action": "close",
                  "data-ret": "bet"
                },
                on: { click: _vm.goBet }
              },
              [_vm._v("Bet Now")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }