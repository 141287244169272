import { i18n } from 'locale/setup';
// 当前支持的market
export const getSportMarketIds = () => ({
	// 足球 (soccer)
	'sr:sport:1': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [1, 16, 18]
	}, {
		name: i18n.t('market:group:1h'),
		value: '1half',
		marketIds: [60, 66, 68]
	}, {
		name: i18n.t('market:group:2h'),
		value: '2half',
		marketIds: [83, 88, 90]
	}, {
		name: i18n.t('market:group:score'),
		value: 'score',
		marketIds: [45, 81]
	}, {
		name: i18n.t('market:group:hot'),
		value: 'hot',
		marketIds: [10, 29]
	}],
	// 篮球
	'sr:sport:2': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [219, 223, 225]
	}, {
		name: i18n.t('market:group:1quarter'),
		value: '1quarter',
		marketIds: [235, 303, 236]
	}, {
		name: i18n.t('market:group:hot'),
		value: 'hot',
		marketIds: [47]
	}],
	// 棒球 (Baseball)
	'sr:sport:3': [{
		name: i18n.t('market:group:default'),
		value: 'default',
		marketIds: [251, 256, 258]
	}], // 这种只有一个分组的代表没有分组逻辑
	// 网球 (Tennis)
	'sr:sport:5': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [186, 187, 189]
	}, {
		name: i18n.t('market:group:hot'),
		value: 'hot',
		marketIds: [188, 202, 204]
	}],
	// volleyball
	'sr:sport:23': [{
		name: i18n.t('market:group:default'),
		value: 'default',
		marketIds: [186, 237, 238]
	}],
	// 橄榄球 (Rugby)
	'sr:sport:12': [{
		name: i18n.t('market:group:default'),
		value: 'default',
		marketIds: [1, 16, 18]
	}],
	// 板球 (Cricket)
	'sr:sport:21': [{
		name: i18n.t('market:group:default'),
		value: 'default',
		marketIds: [340, 605]
	}],
});
export const getLiveMarketIds = () => ({
	// 足球
	'sr:sport:1': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [1, 16, 18]
	}, {
		name: i18n.t('market:group:1h'),
		value: '1half',
		marketIds: [60, 66, 68]
	}, {
		name: i18n.t('market:group:2h'),
		value: '2half',
		marketIds: [83, 90]
	}, {
		name: i18n.t('market:group:score'),
		value: 'score',
		marketIds: [45, 81],
	}, {
		name: i18n.t('market:group:hot'),
		value: 'hot',
		marketIds: [8, 163]
	}],
	// 篮球
	'sr:sport:2': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [219, 223, 225]
	}, {
		name: i18n.t('market:group:hot'),
		value: 'hot',
		marketIds: [236, 19, 20]
	}],
	// 棒球 (Baseball)
	'sr:sport:3': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [251, 256, 258]
	}, {
		name: i18n.t('market:group:hot'),
		value: 'hot',
		marketIds: [287, 746, 288]
	}], // 这种只有一个分组的代表没有分组逻辑
	// 网球 (Tennis)
	'sr:sport:5': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [186, 187, 189]
	}, {
		name: i18n.t('market:group:set'),
		value: 'set',
		marketIds: [202, 203, 204]
	}, {
		name: i18n.t('market:group:hot'),
		value: 'hot',
		marketIds: [210, 218]
	}],
	// 排球 (volleyball)
	'sr:sport:23': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [186, 237, 238]
	}, {
		name: i18n.t('market:group:set'),
		value: 'set',
		marketIds: [202, 309, 310]
	}],
	// 羽毛球 (Badminton)
	'sr:sport:31': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [186, 237, 238]
	}, {
		name: i18n.t('market:group:game'),
		value: 'game',
		marketIds: [245, 246, 247]
	}],
	// 乒乓球 (table-tennis)
	'sr:sport:20': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [186, 237, 238]
	}, {
		name: i18n.t('market:group:game'),
		value: 'game',
		marketIds: [245, 246, 247]
	}],
	// 橄榄球 (Rugby)
	'sr:sport:12': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [1, 16, 18]
	}, {
		name: i18n.t('market:group:1half'),
		value: '1sthalf',
		marketIds: [60, 66, 68]
	}],
	// 冰球 (ice-hockey)
	'sr:sport:4': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [1, 16, 18]
	}, {
		name: i18n.t('market:group:period'),
		value: 'period',
		marketIds: [443, 460, 446]
	}, {
		name: i18n.t('market:group:hot'),
		value: 'hot',
		marketIds: [406, 11]
	}],
	// 室内足球 (Futsal)
	'sr:sport:29': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [1, 16, 18]
	}, {
		name: i18n.t('market:group:1half'),
		value: '1sthalf',
		marketIds: [60, 66, 68]
	}],
	// 美式足球 (Football)
	'sr:sport:16': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [1, 223, 225]
	}, {
		name: i18n.t('market:group:quarter'),
		value: 'quarter',
		marketIds: [235, 236]
	}, {
		name: i18n.t('market:group:hot'),
		value: 'hot',
		marketIds: [219, 10, 11]
	}],
	// 板球 (Cricket)
	'sr:sport:21': [{
		name: i18n.t('market:group:hot'),
		value: 'hot',
		marketIds: [340, 605]
	}, {
		name: i18n.t('market:group:inning'),
		value: 'inning',
		marketIds: [352, 353]
	}],
	// 手球 (handball)
	'sr:sport:6': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [1, 16, 18]
	}, {
		name: i18n.t('market:group:hot'),
		value: 'hot',
		marketIds: [11, 10]
	}, {
		name: i18n.t('market:group:1half'),
		value: '1sthalf',
		marketIds: [60, 66, 68]
	}],
	// 沙滩排球 (beach Volley)
	'sr:sport:34': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [186, 237, 238]
	}, {
		name: i18n.t('market:group:score'),
		value: 'score',
		marketIds: [199]
	}, {
		name: i18n.t('market:group:set'),
		value: 'set',
		marketIds: [202, 309, 310]
	}],
	// 澳洲橄榄球 (aussie rules)
	'sr:sport:13': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [1, 16, 18]
	}, {
		name: i18n.t('market:group:hot'),
		value: 'hot',
		marketIds: [11, 10]
	}, {
		name: i18n.t('market:group:quarter'),
		value: 'quarter',
		marketIds: [235, 303, 236]
	}],
	// 曲棍球 (field hockey)
	'sr:sport:24': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [1, 16, 18]
	}, {
		name: i18n.t('market:group:hot'),
		value: 'hot',
		marketIds: [11, 10]
	}, {
		name: i18n.t('market:group:quarter'),
		value: 'quarter',
		marketIds: [235, 303, 236]
	}],
	// 壁球(squash)
	'sr:sport:37': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [186, 237, 238]
	}, {
		name: i18n.t('market:group:game'),
		value: 'game',
		marketIds: [245, 246, 247]
	}],
	// 保龄球(bowls)
	'sr:sport:32': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [186, 188, 314]
	}, {
		name: i18n.t('market:group:set'),
		value: 'set',
		marketIds: [315, 527, 528]
	}, {
		name: i18n.t('market:group:hot'),
		value: 'hot',
		marketIds: [316, 318, 319]
	}],
	// 斯诺克(snooker)
	'sr:sport:19': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [186, 493, 494]
	}, {
		name: i18n.t('market:group:frame'),
		value: 'frame',
		marketIds: [499, 500, 501]
	}],
	// 飞镖(Dart)
	'sr:sport:22': [{
		name: i18n.t('market:group:fulltime'),
		value: 'fulltime',
		marketIds: [186, 188, 314]
	}, {
		name: i18n.t('market:group:set'),
		value: 'set',
		marketIds: [202, 371, 372]
	}],
});
