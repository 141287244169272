<template>
<div :class="['m-market', 'm-event-market-table']" @click.stop="handlerStop">
	<template v-if="formatTable">
		<!-- 如果market上获取的productId
		和预先设置的不一样，认为market不可以投注 -->
		<div class="outcome-row" v-for="(row, i) in formatTable">
			<OutcomeCell :class="['show-title', , {
				a2: isA2Type
				}]" v-for="(id, index) in row" :key="index" :sportId="sportId" :productId="productId" :market="currentMarket" :event="event" :disabled="currentMarket.productId !== productId" :outcome="currentMarket.outcomes[id]">
				<span class="m-outcome-inner-title">{{getDesc(id)}}</span>
				<!-- suspended显示 -->
				<span :class="['m-outcome-odds', {
					'm-icon-lock': currentMarket.status !== 0 || currentMarket.outcomes[id].isActive !== 1
				}]">{{currentMarket.status !== 0 || currentMarket.outcomes[id].isActive !== 1 ? '' : oddsTrans(currentMarket.outcomes[id].odds, oddsType)}}</span>
			</OutcomeCell>
		</div>
	</template>
	<!-- 有一种情况是后台压根没有传递market，但是这个market还是要显示,所以取固定数据显示这个market -->
	<template v-else>
		<div class="outcome-row" v-if="currentMarketsOutComeSize">
			<div class="m-outcome--disabled m-outcome m-event-outcome-default m-icon-lock" v-for="one in currentMarketsOutComeSize" :key="one">
				<span class="m-icon-lock"></span>
			</div>
		</div>
	</template>
</div>
</template>

<script>
import {
	mapState
} from 'vuex';
import { getMarketConfig, a1, a2, a3 } from 'config/marketConfig';
import OutcomeCell from '../outcomeCell.vue';

// 根据不同的id去显示不同的market
// 记住这里只显示market
export default {
	components: {
		OutcomeCell
	},
	name: 'Markets',
	props: {
		// 只是marketId，不带specifier
		marketId: {
			type: [String, Number]
		},
		event: {
			type: Object,
		},
		// 是否只显示某个置顶的specifiers，这个只对带specifiers起作用
		showOnlyOneSpecifiers: {
			type: [String, Number],
			'default': ''
		},
		// 是否隐藏掉下拉列表的选择specifiers, 如果这个marketId在这个数组里面，则这个market不显示 select，选择specifiers
		// 仅对带specifiers的market管用
		isHideSelectSpecifiers: {
			type: Boolean,
			'default': false
		},
		// title用这里传递的，暂时无用
		title: {
			type: Array,
		},
		// 1(直播),3(赛前)
		productId: {
			type: [String, Number]
		},
		// 为什么需要sportId是因为在不同的sport下的marketId可能是一样的
		sportId: {
			type: String,
			required: true
		},
		// 是否显示Next Goal market下拉列表的选择specifiers
		isShowNextGoalSpecifiers: {
			type: Boolean,
			'default': false
		}
	},
	data() {
		return {
			currentMarket: null,
			formatTable: '',
			descMap: {}, // 针对halfTime/Fulltime这种desc过长无法使用的， 手动截取title作为outcome的desc
		};
	},
	watch: {
		markets: {
			immediate: true,
			handler(val) {
				if (!val) {
					this.reset();
					return;
				}
				// 如果是一个Object证明是一个普通的makerts, array就是一组specifier的market
				// 这里要注意如果是combo的maket，也有可能带specifier，目前列表页面没有这种情况
				// 199的market会带一个specifier说明自身的outcome数量，这里不需要使用
				const market = val.length ? val[0] : val;
				if (market) {
					this.currentMarket = market;
					this.parseMarket(market);
				} else {
					this.reset();
					console.error('market parse error');
				}
			}
		},
		currentSpecifier(val) {
			if (val && this.markets) {
				this.currentMarket = this.markets[val.index];
			}
		}
	},
	computed: {
		...mapState('betslip', ['oddsType']), // 这里应保证引入了betslip store
		currentMarketsOutComeSize() {
			const marketConf = getMarketConfig(this.marketId, this.productId, this.sportId) || {};
			return marketConf.titleArr ? marketConf.titleArr.length : 0;
		},
		isA2Type() { // 是否是halfTime/FullTime
			return a2.indexOf(+this.marketId) > -1;
		},
		markets() {
			let markets = this.event.markets;
			markets = markets ? markets[this.marketId] || {} : {};
			if (markets.length) {
				markets = markets.filter(cur => cur.status === 0 || cur.status === 1);
				// 全部过滤掉了，这个时候就认为没有market
				if (markets.length === 0) {
					return null;
				}
			}
			return markets;
		}
	},
	methods: {
		handlerStop() {},
		reset() {
			this.currentMarket = null;
		},
		getDesc(id) {
			if (a2.indexOf(+this.marketId) > -1) {
				return this.descMap[id] || '';
			}
			return this.currentMarket ? this.currentMarket.outcomes[id].desc : '';
		},
		parseMarket(market) {
			const table = [];
			const marketId = +market.id || '';
			const {
				outcomeOrder,
				outcomes
			} = market;
			if (a1.indexOf(marketId) > -1) {
				// correct score  两列
				const homeWin = [],
					awayWin = [];
				outcomeOrder.forEach(key => {
					const {
						desc
					} = outcomes[key];
					const scores = desc.split(':');
					const val = scores[0] - scores[1];
					if (val > 0) {
						// homeWin
						homeWin[val - 1] = key;
					} else {
						awayWin[-val - 1] = key;
					}
				});

				homeWin.forEach((key, i) => {
					const row = [];
					row.push(key);
					row.push(awayWin[i]);
					table.push(row);
				});
			} else if (a2.indexOf(marketId) > -1) {
				// 半场/全场胜负   3x3
				const { title } = market;
				const titleArr = title.replace(/\s/g, '').split(',');
				let row = [];
				outcomeOrder.forEach((key, i) => {
					this.descMap[key] = titleArr[i];
					row.push(key);
					if (row.length >= 3) {
						table.push(row);
						row = [];
					}
				});
			} else if (a3.indexOf(marketId) > -1) {
				// goal range   两列
				let row = [];
				outcomeOrder.forEach((key, i) => {
					row.push(key);
					if (row.length >= 2) {
						table.push(row);
						row = [];
					}
				});
			}
			if (table.length > 0) {
				this.formatTable = table;
			}
		}
	}
};
</script>
<style lang="less">
@import '~base/style/variable.less';
@import '~base/style/icon.less';

.m-event-market-table {
	.outcome-row {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 1px;
		&:last-child {
			margin-bottom: 0px;
		}
		.show-title {
			display: flex;
			align-items: center;
			justify-content: space-around;
			padding-bottom: 0px;
			padding: 0 24px;
			.m-outcome-inner-title {
				flex: 1 1 auto;
				text-align: left;
				font-weight: normal;
				font-size: 12px;
				color: @DarkWhite;
			}
			&.a2 {
				padding: 0 12px;
			}
		}
		&.live {
			.m-outcome-inner-title {
				color: @white;
			}
		}
		&.m-outcome--disabled {
			.m-outcome-inner-title {
				color: @darkGray;
			}
		}
	}
}
</style>
