import { getShowCurrency, showCurrencyOrigin } from 'config/currencyConfig';
import { showStakeTax, showWinTax } from "config/taxConfig";
const sportsCfg = window.sportsCfg || {};
const currency = window.currency || 'KES';
const showCurrency = showCurrencyOrigin;

const state = {
	// stake
	singleStake: {
		union: ""
	},
	bonusRatios: [],
	multipleStake: "",
	systemStake: {
		union: ""
	},
	minStake: +sportsCfg.min || 50,
	maxStake: +sportsCfg.max || 500000,

	// 货币类型
	currency,
	showCurrency: getShowCurrency(),
	showCurrencyFix: showCurrency,

	// odds 上限
	// 12/22 从10000000改成1000000
	// 改成动态配置
	maxPotWin: sportsCfg.potWinMax || 1000000,
	// 订单信息
	orderInfo: null,
	// 算bonus时候赔率大于多少
	qualifyingOddsLimit: 1.2,

	// 生单相关错误信息
	errorInfo: null,
	// place bet loading 状态
	betLoading: false,

	// 税率配置
	showStakeTax,
	showWinTax
};

export default state;
