<template lang="html">
	<div class="m-table">
		<slot></slot>
	</div>
</template>
<script>
export default {
	name: 'AfTable'
};
</script>
