<template lang="html">
	<div>
		<div class="m-stake-tips" v-if="hasMutexGroup && !isSingle">
			<i class="m-icon--tips"></i>
			<span>{{ $t('contingent:label') }}</span>
		</div>
		<div class="m-plays-wrapper">
			<div
				v-if="isSystem && hasSingle && has1MoreSingle"
				class="m-opt-assist"
			>
				<div class="m-label">{{ $t('play:all:single:label') }}</div>
				<div class="m-content">
					<span class="m-s-unit">{{ showCurrency }}</span>
					<AfInput
						v-model="unionStake"
						:error="!!unionErrorInfo"
						:placeholder="$t('min:stake', [minStake])"
						@change="unionifyStake"
						id="j_unionStake"
					/>
				</div>
				<p
					v-if="unionErrorInfo"
					class="m-text-error"
				>{{ unionErrorInfo }}</p>
			</div>
			<div class="m-plays">
				<template v-if="showTitle">
					<div class="m-plays-title" v-if="false && showComboTitle">
						<div class="m-type">{{ $t('type:label') }}</div>
						<div class="m-num">{{ $t('number:label') }}</div>
						<div class="m-money">{{ $t('stake:label') }}({{ showCurrencyFix }})</div>
					</div>
					<template v-for="(stakeItem, index) in stakeUnion">
						<div
							v-if="!isSingles(stakeItem)"
							:key="stakeItem.name"
							class="m-plays-item"
							:id="`j_stake_${index}`"
						>
							<div class="m-line-wrapper">
								<div class="m-type">{{ getStakeNameI18n(stakeItem.name) }}</div>
								<div class="m-num">{{ stakeItem.count || '' }}</div>
								<div class="m-money">
									<AfInput
										v-model="stake[index]"
										:placeholder="$t('min:stake', [minStake])"
										@change="modifyStake"
										:data-index="index"
										:error="showUniqueStakeError(index)"
										@blur="checkUniqueStake($event, index)"
										@input="setCheckStatus"
										@focus="changeActiveIn('stake_'+index)"
									/>
								</div>
							</div>
							<p
								v-show="showUniqueStakeError(index) && !errorInfo.sumMsg"
								class="m-error-tips"
							>{{ getUniqueErrorMsg(index) }}</p>
						</div>
					</template>
				</template>

				<template v-else>
					<div class="m-plays-item">
						<div class="m-line-wrapper">
							<div class="m-label">{{ $t('total:stake:label') }}</div>
							<div class="m-value">
								<span>{{ showCurrency }}</span>
								<AfInput
									v-model="stake[0]"
									:placeholder="$t('min:stake', [minStake])"
									@change="modifyStake"
									:data-index="0"
									:error="showUniqueStakeError(0)"
									@blur="checkUniqueStake($event, 0)"
									@input="setCheckStatus"
									@focus="changeActiveIn('stake_0')"
									id="j_stake_0"
								/>
							</div>
						</div>
						<p
							v-show="showUniqueStakeError(0) && !errorInfo.sumMsg"
							class="m-error-tips"
						>{{ getUniqueErrorMsg(0) }}</p>
					</div>
				</template>

			</div>
		</div>
		<Tips
			v-if="actionTips"
			type="error"
			:msg="actionTips"
		/>
		<Tips
			v-else-if="errorInfo.sumMsg"
			type="error"
			:msg="errorInfo.sumMsg"
		/>
		<FlexiBet v-show="showFlexibetBar"/>
		<BetslipSetting/>
		<div class="m-money-wrapper">
			<div
				v-if="isMultiple"
			>
				<div class="m-label m-label-fix">{{ $t('odds:label') }}</div>
				<div class="m-value m-value-fix">{{ showMultipleOdds }}</div>
			</div>
			<div
				v-if="showTitle"
				class="m-money"
			>
				<div class="m-label">
					<span>{{ $t('total:stake:label') }}</span>
					<div class="m-tips" @click="showStakeTips(defaultShowTipType)">
						<i class="m-icon-tips"></i>
					</div>
				</div>
				<div class="m-value">{{ showCurrency }} {{ getTotalStake }}</div>
			</div>
			<div class="m-money" v-if="showStakeUnion.originBonus > 0">
				<div class="m-label">Bonus</div>
				<div class="m-value">{{ showCurrency }} {{ showStakeUnion.showBonus }}</div>
			</div>
			<!-- 无税 -->
			<div class="m-money" v-if="+showStakeTax == 0">  
				<div class="m-label">{{ $t('to:win:label') }}</div>
				<div class="m-value">{{ showCurrency }} {{ showStakeUnion.potentialWin }}</div>
			</div>
			<!-- 无税 end -->
			<!-- 有税 -->
			<template v-else> 
				<div class="m-money">
						<div class="m-label">
							<span>{{ $t('pot:ex:tax') }}</span>
							<div class="m-tips" @click="showStakeTips(2)">
								<i class="m-icon-tips"></i>
							</div>
						</div>
						<div class="m-value">{{ showCurrency }} {{ 
							showStakeUnion.exciseTax }}</div>
				</div>
				<div class="m-money">
						<div class="m-label">{{ $t('actual:stake:label') }}</div>
						<div class="m-value">{{ showCurrency }} {{ 
							showStakeUnion.actualStake }}</div>
				</div>
				<div class="m-money">
						<div class="m-label">
							{{ $t('pot:wh:tax') }}
							<div class="m-tips" @click="showStakeTips(3)">
								<i class="m-icon-tips"></i>
							</div>
						</div>
						<div class="m-value">{{ showCurrency }} {{ 
							showStakeUnion.winTax }}</div>
				</div>
				<div class="m-money" >
					<div class="m-label">{{ $t('pot:win:label') }}</div>
					<div class="m-value">{{ showCurrency }} {{ showStakeUnion.potentialWinWithoutTax }}</div>
				</div>
			</template>
			<!-- 有税 end -->
			<div class="m-money">
				<div class="m-label">{{ $t('total:odds:label') }}</div>
				<div class="m-value">{{ showStakeUnion.finalOdds }}</div>
			</div>
		</div>

		<div class="m-btn-wrapper place-bet">
			<af-Button
				v-if="betLoading"
				extraType='text'
				:disabled="!submitStatus"
				:loading="true"
				class="m-btn--submit"
			>{{ $t('submitting:label') }}...
			</af-Button>

			<template v-else>
				<af-Button
					v-if="!acceptStatus"
					extraType='primary'
					:disabled="!submitStatus"
					:loading="false"
					@click="commitBet"
				>{{ $t('place:bet:label') }}
				</af-Button>

				<af-Button
					v-else
					extraType='primary'
					@click="accpetChanges"
				>{{ $t('accept:changes:label') }}
				</af-Button>
			</template>
		</div>

		<!-- 确认框 -->
		<template v-if="showComfirm">
			<div
				class="m-comfirm-wrapper"
				:style="getConfirmStyle"
			>
				<div>
					<div class="m-content-wrapper">
						<div class="m-label">{{ getConfirmTitle }}</div>
						<div class="m-value">{{ showCurrency }}<span class="m-money">{{ getShowPayMoney }}</span></div>
					</div>

					<!-- flexibet -->
					<template v-if="isFlexi">
						<div class="m-line">
							<div class="m-line-label m-line-label-fix">Options to be Correct</div>
							<div class="m-line-value">{{ flexiSelect }} of {{ flexiThreshold }}</div>
						</div>
						<div class="m-line">
							<div class="m-line-label">Total Odds</div>
							<div class="m-line-value">{{ showMultipleOdds }}</div>
						</div>
						<div class="m-line">
							<div class="m-line-label">To Win</div>
							<div class="m-line-value">{{ earnings }}</div>
						</div>
					</template>

					<div class="m-btn-wrapper">
						<af-Button
							extraType='text'
							@click="cancelPlaceBet"
						>{{ $t('cancel:label:lowercase') }}
						</af-Button>
						<af-Button
							extraType='primary'
							@click="commitPlaceBet"
						>{{ $t('confirm:label') }}
						</af-Button>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';

import 'packages/input';
import 'packages/button';

import {formatNumber} from 'utils';
import 'utils/dom';

import * as mutationTypes from 'store/betslipStake/mutationTypes';
import * as couponMutationTypes from 'store/coupons/mutationTypes';
import {UPDATEPAYMETHOD} from 'store/betslip/mutationTypes';
import {payConfirmTxt} from 'config/payConfig';
import {getStakeIndex} from 'config/stakeConfig';
import Tips from 'components/tips/index';
import stakeTipTmp from 'config/contryConfig/stakeTipTmp';
import { invokeLogin } from 'login/index'

import 'components/coupons';

import {createOrderTips} from '../js/config';

import PopTips from './popTips.vue';
import FlexiBet from './flexiBet.vue';
import BetslipSetting from './betslipSetting.vue';

import calcStakeMixins from '../js/calcStakeMixins';

export default {
	name: 'BetslipsStake',

	mixins: [calcStakeMixins],

	props: {},

	components: {
		Tips,
		FlexiBet,
		BetslipSetting
	},

	data() {
		return {
			stake: [], // single之外的组合stake
			unionStake: this.uStake || '', // single union
			errorInfo: {},
			loading: false,
			hasError: false,
			showComfirm: false,
			isReady: false,
			canCheckStake: false,		// 输入框失去焦点或者tab切换时，检测stake的值,(12/26增加enter健)
			activeInput: '',
			actionTips: '',
			marketGuide: '123123'
		};
	},

	computed: {
		...mapState('betslip', [
			'currentType',
			'acceptStatus',
			'flexiSelect',
			// 'flexLimit',
			'oddsThreshold',
			'bankerStatus',
			'bankerList',
			'needConfirm'
		]),
		...mapState('betslipStake', [
			'showStakeTax',
			'showCurrency',
			'showCurrencyFix',
			'minStake',
			'maxStake',
			'maxPotWin',
			'betLoading',
			'singleStake'
		]),
		...mapGetters('betslipStake', [
			'uStake',
			'stakeUnion',
			'selectionInfo',
			'hasSingle',
			'calTotalStake'
		]),
		...mapGetters('betslip', [
			'canBet',
			'getBetCount',
			'hasMutexGroup',
			'getSelectList',
			'isSupportFlexiBet',
			'flexiThreshold',
			'isFlexi',
			'getCurrentPayMethods'
		]),
		...mapGetters('coupons', [
			'getCouponInfo',
			'checkItem',
			'checkGiftId'
		]),
		...mapState('coupons', [
			'clickedGiftId'
		]),
		...mapState('bonus', [
			'maxBonus'
		]),
		isMultiple() {
			return this.currentType === 'multiple';
		},
		isSystem() {
			return this.currentType === 'system';
		},
		isSingle() {
			return this.currentType === 'single';
		},
		showMultipleOdds() {
			const stakeUnion = this.stakeUnion || [];

			return stakeUnion && stakeUnion.length && stakeUnion[0].odds || '';
		},
		showFlexibetBar() {
			// return this.isMultiple && this.isSupportFlexiBet && this.flexiThreshold >= this.flexLimit;
			return this.isMultiple && this.isSupportFlexiBet;
		},
		getConfirmTitle() {
			const isFlexi = this.isFlexi,
				getCurrentPayMethods = this.getCurrentPayMethods;

			if (isFlexi) {
				return 'Confirm to Pay?';
			}

			let showTxt = payConfirmTxt[getCurrentPayMethods];
			showTxt = showTxt ? ` ${showTxt}` : '';
			return this.$t('pay:confirm:text', [showTxt]);
		},
		unionErrorInfo() {
			if (this.errorInfo.union) {
				return this.errorInfo.union || '';
			}

			return '';
		},
		showComboTitle() { // 只要有single外的组合就展示title
			if (this.stakeUnion.length > 0) {
				return this.stakeUnion[1] ? true : !this.isSingles(this.stakeUnion[0]);
			}
			return false;
		},
		submitStatus() {
			// 有错误、无选项
			if (this.hasError || !this.stakeUnion.length) {
				return false;
			}

			// 无值
			if (!this.calTotalStake) {
				return true;
			}

			// 全部unavailable和suspended状态, 不可place bet
			if (!this.canBet) {
				return false;
			}

			let ret = false;
			const stake = this.stake || [];

			for (const key of this.getSelectList) {
				if (this.singleStake[key]) {
					ret = true;
					break;
				}
			}
			for (const stakeItem of stake) {
				if (stakeItem) {
					ret = true;
					break;
				}
			}

			return ret;
		},

		has1MoreSingle() {
			if (this.hasSingle) {
				return this.stakeUnion[0].count > 1;
			}
			return false;
		},
		// 格式totalStake
		getTotalStake() {
			const ret = this.calTotalStake;

			return ret ? formatNumber(ret, 2) : 0;
		},

		// pot win
		earnings() {
			let min = [],
				sum = [];
			for (const stakeItem of this.stakeUnion) {
				if (this.isSingles(stakeItem)) {
					const {orderInfo} = stakeItem;
					for (const item of orderInfo) {
						if (item.stake) {
							min.push(item.stake * item.odds);
							sum.push(item.stake * item.odds);
						}
					}
				} else {
					const tempStake = stakeItem.stake;
					if (tempStake) {
						min.push(stakeItem.min * tempStake);
						sum.push(stakeItem.sum * tempStake);
					}
				}
			}

			if (!min.length || !sum.length) {
				return 0;
			}

			min.sort((a, b) => a - b);
			min = min[0];

			let total = sum.reduce((temp, item) => temp += +item, 0);

			// 12/22 pot win包含bonus(只将bonus加到max pot win上)
			// const bonus = this.getBonus;
			const bonus = 0;

			if (min === total) {
				total += bonus;

				total = total > this.maxPotWin ? this.maxPotWin : total;
				return `${formatNumber(total, 2)}`;
			}

			total += bonus;

			total = total > this.maxPotWin ? this.maxPotWin : total;
			min = min > this.maxPotWin ? this.maxPotWin : min;

			return min === total ? `${formatNumber(total, 2)}` : `${formatNumber(min, 2)} ~ ${formatNumber(total, 2)}`;
		},

		// 确认支付金额
		getPayMoney() {
			if (this.checkGiftId) {
				const money = this.calTotalStake * 10000 - this.checkItem.originBal;
				return money > 0 ? money : 0;
			}

			return this.calTotalStake * 10000;
		},

		getShowPayMoney() {
			return formatNumber(this.getPayMoney / 10000, 2);
		},

		showTitle() {
			return this.getBetCount > 0;
		},

		showBonus() {
			// if (this.isFlexi) {
			// 	return;
			// }

			let hasBonus = false;

			const stakeUnion = this.stakeUnion || [];
			for (const stakeItem of stakeUnion) {
				if (stakeItem.bonusRatio) {
					hasBonus = true;
					break;
				}
			}

			return hasBonus;
		},

		// 计算bonus
		getBonus() {
			// if (this.isFlexi) {
			// 	return 0;
			// }

			const stakeUnion = this.stakeUnion || [];
			let ret = 0;
			for (const stakeItem of stakeUnion) {
				ret += stakeItem.bonusRatio * (+stakeItem.stake || 0);
			}
			console.log(ret)
			// ret /= 100;

			ret = ret > this.maxBonus ? this.maxBonus : ret;

			return ret;
		},

		getShowBonus() {
			return formatNumber(this.getBonus, 2);
		},
		getConfirmStyle() {
			const betWrapperDom = document.querySelector('#j_betslip .m-tabs');

			return {
				top: betWrapperDom && `-${betWrapperDom.offsetHeight}px` || '0px'
			};
		},
		// stake 输入框内容匹配规则：最多输入两位小数，整数长度限制为 maxStake 的长度
		inputReg() {
			const maxIntLength = String(this.maxStake).length;
			return new RegExp(`^\\d{0,${maxIntLength}}(\\.\\d{0,2})?`);
		},
		// 默认展示类型
		defaultShowTipType() {
			if (['24sport', 'sport88'].includes(window.op)) {
				return 4;
			}
			return 1;
		}
	},

	watch: {
		currentType(val, oldVal) {
			// tab切换时，设置检查stake状态
			this.canCheckStake = true;

			// 12/26
			this.activeInput = '';
		},
		stakeUnion(val, oldVal) {
			if (!val.length) {
				return;
			}

			// play all有值时，玩法变化时，各种玩法stake自动同步paly all的值，同时校验
			if (this.unionStake && this.isSystem && this.hasSingle) {
				this.fillStakeItem(true);
				this.checkStake('union', null);
			} else {
				this.stake.splice(0);
				for (const stakeItem of val) {
					// single时不输出
					this.stake.push(stakeItem.stake || '');
				}

				// play all无值时，逐个校验各个玩法stake和总stake
				this.stake.forEach((item, index) => {
					this.checkStake('unique', index);
				});
			}
		},
		getBetCount() {
			this.fixError();
			this.fixStake();
		},

		getSelectList() {
			this.fixError();
			this.fixStake();
		},

		clickedGiftId(val) {
			if (val) {
				this.changeCoupon();
			}
		},

		errorInfo(val, oldVal) {
			this.checkErrorInfo();
		},

		// acceptStatus
		acceptStatus(val) {
			if (val) {
				this.showComfirm = false;
				this.isReady = false;
			}
		},
		uStake() {
			if (this.uStake === '') {
				this.unionStake = '';
			}
		},
		// 是否可以生单
		isReady(val) {
			if (val) {
				this.placeBet(this.getPayMoney)
					.then(ret => {
						this.isReady = false;
					}, err => { // eslint-disable-line
						this.isReady = false;
					});

				this.showComfirm = false;
			}
		},
		calTotalStake(val) {
			if (val) {
				this.actionTips = '';
				this.errorInfo.sumMsg = '';
				if (val > this.maxStake) {
					this.errorInfo.sumMsg = this.$t('stake:max:limit:text', [formatNumber(this.maxStake)]);
				}
				this.singleError();
				this.checkErrorInfo();
			}
		}
	},

	methods: {
		...mapActions('betslipStake', [
			'placeBet',
		]),
		...mapActions('betslip', [
			'accpetChanges'
		]),
		...mapActions('coupons', [
			'getCoupons'
		]),
		...mapMutations('betslip', {
			savePayMethods: UPDATEPAYMETHOD
		}),
		...mapMutations('betslipStake', {
			updateStake: mutationTypes.UPDATESTAKE
		}),
		...mapMutations('coupons', {
			updateCouponStatus: couponMutationTypes.COUPONSUNAVAIABLE,
			changeChecked: couponMutationTypes.UPDATECHECKEDGIFTID,
			updateConfirmStatus: couponMutationTypes.UPFATECOMFIRMERROR,
			updateGiftsTips: couponMutationTypes.UPDATEDISABLEDTIPS
		}),
		getStakeNameI18n(name) {
			if (name === 'Singles') {
				return this.$t('play:all:single:label');
			}
			if (name === 'Doubles') {
				return this.$t('double:label');
			}
			if (name === 'Trebles') {
				return this.$t('triple:label');
			}
			// x Folds
			const folds = name.split(' ')[0];
			return this.$t('folds:label', [folds]);
		},
		setCheckStatus() {
			// 输入过程中，不检查输入的stake值
			this.canCheckStake && (this.canCheckStake = false);
		},
		changeActiveIn(type = '') {
			// 12/26
			this.activeInput = type;
		},
		fillStakeItem(silent = false) {
			if (silent) return;
			const map = {};
			this.getSelectList.forEach(key => {
				map[key] = this.unionStake;
			});
			this.updateStake({
				type: 'single',
				stake: map
			});
		},
		unionifyStake(opt) {
			const val = opt.value;
			this.unionStake = this.formatInput(val);

			// 修改其他stake的值，与该值相同
			this.fillStakeItem();

			this.fixStake();

			// stake值发生变化时，红包选中清空
			this.changeChecked({
				giftId: '',
				type: this.currentType
			});

			this.checkUnionStake(null, false, false);
		},
		checkUnionStake(event, checkMin = true, isBlur = true) {
			// if (this.unionStake) {
			this.canCheckStake = true;
			isBlur && (this.activeInput = '');
			// }

			this.checkStake('union', null, checkMin);
			this.checkErrorInfo();

			if (isBlur) {
				const originStake = this.unionStake;
				this.unionStake = +this.unionStake || '';

				if (originStake !== '' + this.unionStake) {
					this.fixStake();
				}
			}
		},
		isSingles(item) {
			return getStakeIndex(item.name) === 1;
		},
		singleError() {
			const stakeUnion = this.stakeUnion || [];
			if (stakeUnion[0] && this.isSingles(stakeUnion[0])) {
				const orderInfo = stakeUnion[0].orderInfo || [];
				for (let i = 0; i < orderInfo.length; i++) {
					if (orderInfo[i].stakeStr && orderInfo[i].stake < this.minStake) {
						this.errorInfo.sumMsg = this.$t('stake:min:limit:text', [formatNumber(this.minStake)]);
					}
				}
			}
		},
		formatInput(val) {
			let fixVal = val.replace(/[^\d\.]/g, '');
			const fixValArr = fixVal.match(this.inputReg);

			if (fixValArr) {
				fixVal = fixValArr[0];
			}
			return fixVal;
		},
		modifyStake(opt, event) {
			const val = opt.value,
				parentNode = event.target.closest('.m-input-wrapper');

			const index = parentNode.getAttribute('data-index');

			const fixVal = this.formatInput(val);
			this.stake.splice(+index, 1, fixVal);

			// system时，重置unionStake为0, 更新store中unionStake
			if (this.currentType === 'system' && this.unionStake) {
				// this.unionStake = '';
			}

			this.fixStake();

			// stake值发生变化时，红包选中清空
			this.changeChecked({
				giftId: '',
				type: this.currentType
			});

			this.checkUniqueStake(null, index, false, false);
		},
		checkUniqueStake(event, index, checkMin = true, isBlur = true) {
			// if (this.stake[index]) {
			this.canCheckStake = true;
			isBlur && (this.activeInput = '');
			// }

			this.checkStake('unique', index, checkMin);
			this.checkErrorInfo();

			if (isBlur) {
				const originStake = this.stake[index];
				this.stake[index] = +this.stake[index] || '';

				// 有前缀0，失去焦点时，更新store
				if (originStake !== '' + this.stake[index]) {
					this.fixStake();
				}
			}
		},
		checkStake(type = 'union', index = -1, checkMin = true) {
			if (!this.canCheckStake) {
				return;
			}

			// play all时检查错误信息之前，重置错误信息
			if (type === 'union') {
				this.errorInfo = {};

				this.errorInfo.union = this.getErrorMsg('union', null, checkMin);
			} else {
				const stakeList = this.stake || [];
				const tempStakeList = [...stakeList];

				tempStakeList.splice(index, 1);
				tempStakeList.unshift(stakeList[index]);

				for (let i = 0, len = tempStakeList.length; i < len; i++) {
					const tempIndex = i === 0 ? index : i <= index ? i - 1 : i,
						key = this.getErrorKey('unique', tempIndex),
						errorMsg = this.getErrorMsg('unique', tempIndex, checkMin);

					this.errorInfo = {
						...this.errorInfo,
						...{
							[key]: errorMsg,
							union: errorMsg ? '' : this.errorInfo.union
						}
					};
					if (errorMsg) {
						break;
					}
				}
			}

			this.checkTotalStake(type);

			// stake 全部检验完，重置检测状态
			this.canCheckStake = false;
		},
		getErrorKey(type = 'union', index = -1) {
			if (type === 'union') {
				return 'union';
			}

			const currentStakeObj = this.stakeUnion[index] || {};
			return currentStakeObj.name || 'Single';
		},
		getErrorMsg(type = 'union', index = -1, checkMin = false) {
			if (this.hasSingle && index === 0) {
				return '';
			}
			const checkData = type === 'unique' ? this.stake[index] : this.unionStake;

			if (checkMin && checkData && checkData < this.minStake) {
				return this.$t('stake:min:limit:error:text', [this.minStake]);
			}

			if (checkData && checkData > this.maxStake) {
				return this.$t('stake:max:limit:error:text', [formatNumber(this.maxStake)]);
			}

			return '';
		},
		checkTotalStake(type = 'union') {
			const totalStake = this.calTotalStake || 0;
			// if (type === 'union') {
			// 	totalStake = this.stakeUnion.reduce((temp, item) => {
			// 		const tempStake1 = item.count && +this.unionStake && (item.count * this.unionStake) || 0;
			// 		temp += tempStake1;
			// 		return temp;
			// 	}, 0);
			// } else {
			// 	totalStake = this.stakeUnion.reduce((temp, item, index) => {
			// 		const tempStake2 = item.count && +this.stake[index] && (item.count * this.stake[index]) || 0;
			// 		temp += tempStake2;
			// 		return temp;
			// 	}, 0);
			// }
			if (totalStake > this.maxStake) {
				this.errorInfo.sumMsg = this.$t('stake:max:limit:text', [formatNumber(this.maxStake)]);
			} else {
				// this.errorInfo.sumMsg = '';
			}
		},
		showUniqueStakeError(index = -1) {
			if (index > -1) {
				if (this.getUniqueErrorMsg(index)) {
					return true;
				}
			}

			return false;
		},
		getUniqueErrorMsg(index = -1) {
			const key = this.getErrorKey('unique', index);

			return this.errorInfo[key] || '';
		},

		checkErrorInfo() {
			const errorInfo = this.errorInfo || {};

			if (this.currentType === 'single') {
				this.hasError = !!errorInfo.Single;
				return;
			}

			if (this.currentType === 'multiple') {
				this.hasError = !!errorInfo.Multiple;
				return;
			}

			const errorKeys = Object.keys(errorInfo);

			for (const errorKey of errorKeys) {
				if (errorInfo[errorKey]) {
					this.hasError = true;
					return;
				}
			}

			this.hasError = false;
		},

		fixStake() {
			let options = {};

			// 更新store
			if (this.currentType === 'system') {
				const stakeUnion = this.stakeUnion || [],
					stakeCount = stakeUnion.length;
				for (let index = 0; index < stakeCount; index++) {
					// 注意这里先不要转成 Number 类型
					options[stakeUnion[index].name] = this.stake[index] || '';
				}
				options.union = +this.unionStake || '';
			} else {
				options = this.stake[0];
			}

			this.updateStake({
				type: this.currentType,
				stake: options
			});
		},

		fixError() {
			// bet数量变化时，设置检查stake状态
			this.canCheckStake = true;

			// betslip列表发成变化（新增，update，删除，check status）时，检查stake是否有误，矫正有误项
			if (this.unionStake && this.isSystem && this.hasSingle) {
				this.fillStakeItem();
				this.checkStake('union');
			} else {
				this.stake.forEach((item, index) => {
					this.checkStake('unique', index);
				});
			}
		},

		// 选择红包
		changeCoupon() {
			const currentCoupon = this.getCouponInfo(this.clickedGiftId);

			if (currentCoupon) {
				const totalStake = this.calTotalStake || 0,
					leastOrderAmount = +currentCoupon.leastOrderAmount;

				if (currentCoupon.maskTips) {
					this.updateCouponStatus(true);
					return;
				}

				// 没有stake、满减不符合条件
				if (!totalStake ||
					(leastOrderAmount && leastOrderAmount > totalStake)) {
					this.updateCouponStatus(true);
					this.updateGiftsTips(
						!totalStake ? 'Please enter a stake first' : 'Min. Stake Required Not Met'
					);
				} else {
					this.changeChecked({
						giftId: this.clickedGiftId,
						type: this.currentType
					});
				}
			}
		},

		commitBet() {
			if (!this.calTotalStake) {
				this.actionTips = this.$t('stake:min:limit:error:text', [this.minStake]);
				this.hasError = true;
				return;
			}

			if (this.isFlexi && +this.showMultipleOdds < +this.oddsThreshold) {
				this.$dialog();
				this.$dialog({
					title: null,
					contentData: {
						title: 'Note',
						msg: createOrderTips(this.oddsThreshold)
					},
					content: PopTips,
					button: []
				});
				return;
			}

			if (this.betLoading) {
				return;
			}

			if (!window.loginStatus) {
				invokeLogin(() =>{
					window.login({activeTab: 'Login'});
				});
				return;
			}

			// place bet clicked
			if (this.needConfirm !== 1) {
				this.commitPlaceBet();
			} else {
				this.showComfirm = true;
			}
		},

		cancelPlaceBet() {
			this.showComfirm = false;
		},

		commitPlaceBet() {
			// bet使用了红包，请求红包接口验证红包
			if (this.checkGiftId) {
				this.getCoupons({
					betType: 0,
					isComfirm: true
				}).then(ret => {
					if (ret && ret.conponAviable) {
						// 继续校验totalStake和coupon
						const totalStake = this.calTotalStake || 0,
							leastOrderAmount = this.checkItem && +this.checkItem.leastOrderAmount || 0;

						// 没有stake、满减不符合条件
						if (!totalStake ||
							(leastOrderAmount && leastOrderAmount > totalStake)) {
							// 重置coupon的confirm状态
							this.updateConfirmStatus(true);
							this.isReady = false;
							this.showComfirm = false;
						} else {
							this.isReady = true;
						}
					} else {
						this.isReady = false;
						this.showComfirm = false;
					}
				});
			} else {
				// 没有使用红包，直接生单
				this.isReady = true;
			}
		},
		handleEnterPress(event) {
			if (event.keyCode && event.keyCode === 13 && this.activeInput) {
				const currentInputDom = document.querySelector(`#j_${this.activeInput} input`);
				currentInputDom && (currentInputDom.blur());
			}
		},
		getPopStyle(marketGuide) {
			let baseWidth = 6;
			// 没办法先根据语言写死
			if (window.lang === 'zh_CN') {
				baseWidth = 12;
			}
			return `width: ${marketGuide.length * baseWidth}px;`;
		},
		// maxPayout 弹窗
		showStakeTips(type) {
			// type 1-totalStake 2-exciseTax 3-whTax
			const helpConetent = stakeTipTmp(type);
			this.$dialog({
				title: null,
				content: {
					template: helpConetent
				},
				button: ['*OK']
			});
		},
	},
	mounted() {
		window.addEventListener('keyup', e => {
			this.handleEnterPress(e);
		});
	},
	beforeDestroy() {
		window.removeEventListener('keyup', e => {
			this.handleEnterPress(e);
		});
	}
};
</script>
