var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("i", { staticClass: "m-icon-suc" }),
    _vm._v(" "),
    _c("div", { staticClass: "m-txt-main" }, [
      _vm._v(_vm._s(_vm.showCurrency) + _vm._s(_vm.getShowVal))
    ]),
    _vm._v(" "),
    _vm.contentData.isPartial
      ? _c("div", { staticClass: "m-txt-min" }, [
          _vm._v(_vm._s(_vm.i18n.t("partial:cashout:success")))
        ])
      : _c("div", { staticClass: "m-txt-min" }, [
          _vm._v(_vm._s(_vm.i18n.t("cashout:success")))
        ]),
    _vm._v(" "),
    _vm.contentData.isPartial
      ? _c("div", { staticClass: "m-txt-desc" }, [_vm._v(_vm._s(_vm.getTips))])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "ok-btn", on: { click: _vm.confirm } }, [
      _vm._v(_vm._s(_vm.i18n.t("ok:label")))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }