<template lang="html">
	<div class="m-item">
		<div
			class="m-lay-left"
			:style="getColorLumpStyle"
		>
			<i
				v-if="showCheckBox"
				:class="[
					'm-icon-check',
					{
						'm-icon-check--checked': checked,
						'm-icon-check--disabled': disabled
					}
				]"
				@click="toggleCheckStatus"
			></i>
		</div>
		<div
			class="m-lay-mid"
		>
			<div
				:class="[
					'm-lay-mid',
					{
						'm-lay-fix': showBankerBar
					}
				]"
			>
				<div class="m-item-play">
					<img class="m-sport-icon" v-if="curSportIcon" :src="curSportIcon"/>
					<div class="outcome-desc" :title="slip.outcomeInfo.desc">{{slip.outcomeInfo.desc}}</div>
					<div class="blank-block"/>
					<template v-if="slip.outcomeInfo.showOdds">
						<div class="outcome-old-odds" v-if="slip.outcomeInfo.oldOdds && slip.outcomeInfo.statusDesc">{{slip.outcomeInfo.oldOdds}}</div>
						<div class="outcome-odds">{{oddsTrans(slip.outcomeInfo.odds, oddsType)}}</div>
						<span
							v-if="slip.outcomeInfo.statusDesc === 'up'"
							class="m-icon-up"
						></span>
						<span
							v-if="slip.outcomeInfo.statusDesc === 'down'"
							class="m-icon-down"
						></span>
					</template>
					<template v-else>
						<span class="m-text-min m-text-btn">{{getBetStatusi18n(slip.outcomeInfo.statusDesc)}}</span>
					</template>
					<i
						class="m-icon-delete"
						@click="handleDelete"
					></i>
				</div>
				<div class="m-item-team" :title="getAgainst">
					<i class="m-icon-live" v-if="isLive">Live</i>{{slip.gameId ? slip.gameId +'  ' : ''}}{{getAgainst}}
				</div>
				<div class="m-item-main">
					<span class="m-item-market" :title="slip.marketInfo.desc">{{slip.marketInfo.desc}}</span>
					<AfInput
						v-if="hasInput"
						v-model="singleStake[slip.key]"
						:placeholder="$t('min:stake', [minStake])"
						:disabled="!slip.outcomeInfo.showOdds"
						@change="modifyStake"
					/>
				</div>
			</div>
			<div
				v-show="showBankerBar"
				class="m-banker-wrapper m-lay-right"
				@click="toggleBankerStatus"
			>
				<span
					:class="[
						'm-banker',
						{
							'm-banker--checked': isBanker,
							'm-banker--disabled': disabled
						}
					]"
				>B</span>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';

import { EventBus } from 'kernel/js/eventBus.js';
import 'packages/input';
import * as mutationTypes from 'store/betslip/mutationTypes';

import listEvent from 'config/eventConfig/listEvent.js';
import commonEvent from 'config/eventConfig/commonEvent.js';
import { colorLump } from 'config/stakeConfig';
import { getIdToSport } from 'config/sportsType';

export default {
	name: 'BetslipsList',

	props: {
		slip: {
			type: Object,
			default() {
				return {};
			}
		},
		checked: {
			type: Boolean,
			'default': false
		},
		showBankerBar: {
			type: Boolean,
			'default': false
		},
		isBanker: {
			type: Boolean,
			'default': false
		},
		showCheckBox: {
			type: Boolean,
			'default': false
		}
	},
	computed: {
		...mapState('betslip', [
			'getBankerCount',
			'getBetCount',
			'eventColorLump',
			'currentType',
			'suspendedKeys',
			'unavailableKeys',
			'bankerList',
			'bankerStatus',
			'oddsType'
		]),
		...mapGetters('betslip', [
			'getSelectList'
		]),
		...mapState('betslipStake', [
			'singleStake',
			'minStake',
			'maxStake',
		]),
		...mapGetters('betslipStake', [
			'hasSingle'
		]),
		disabled() {
			const key = this.slip.key;
			return this.suspendedKeys.includes(key) || this.unavailableKeys.includes(key);
		},
		idToSport() {
			return getIdToSport();
		},
		curSportIcon() {
			const sport = this.idToSport[this.slip.sportId] || {};
			return sport.colorIcon;
		},
		isMetuxItem() {
			let ret = false;
			if (this.currentType !== 'single') {
				const index = this.eventColorLump.indexOf(this.slip.eventId);
				if (index !== -1) {
					ret = true;
				}
			}
			return ret;
		},
		getColorLumpStyle() {
			let ret = '';
			if (this.isMetuxItem) {
				const index = this.eventColorLump.indexOf(this.slip.eventId);
				if (index !== -1) {
					ret = `border-color: ${colorLump[index]};`;
				}
			}
			return ret;
		},
		hasInput() {
			const key = this.slip.key || '';
			const bankerList = this.bankerStatus ? this.bankerList : [];
			if (!this.bankerStatus) {
				return this.hasSingle;
			}
			return this.hasSingle && (bankerList.length === 0 || bankerList.indexOf(key) > -1);
		},
		getAgainst() {
			return `${this.slip.home} - ${this.slip.away}`;
		},
		isLive() {
			const slip = this.slip || {},
				marketInfo = slip.marketInfo || {};

			return !slip.isFinish && marketInfo.product === 1;
		},
		// stake 输入框内容匹配规则：最多输入两位小数，整数长度限制为 maxStake 的长度
		inputReg() {
			const maxIntLength = String(this.maxStake).length;
			return new RegExp(`^\\d{0,${maxIntLength}}(\\.\\d{0,2})?`);
		}
	},
	methods: {
		...mapActions('betslip', [
			'deleteBetSlip',
		]),
		...mapMutations('betslip', {
			changeCheckStatus: mutationTypes.UPDATECHECKSTATUS,
			updateBankerList: mutationTypes.UPDATEBANKERS
		}),
		...mapMutations('betslipStake', [
			'updateStake',
		]),
		handleDelete() {
			const key = this.slip.key;
			this.deleteBetSlip(key);

			// 通知其他列表，投注项删除
			EventBus.$emit(commonEvent.DELETE_BET_SLIPS_ITEM, key);
		},
		formatInput(val) {
			let fixVal = val.replace(/[^\d\.]/g, '');
			const fixValArr = fixVal.match(this.inputReg);

			if (fixValArr) {
				fixVal = fixValArr[0];
			}
			return fixVal;
		},
		modifyStake(opt) {
			const stake = {};
			const val = this.formatInput(opt.value || '');
			stake[this.slip.key] = val;
			this.updateStake({
				type: 'single',
				stake: Object.assign(this.singleStake, stake, {
					union: ''
				})
			});
		},
		toggleCheckStatus() {
			if (this.disabled) {
				return;
			}

			this.changeCheckStatus({
				checked: !this.checked,
				key: this.slip.key
			});
		},
		getBetStatusi18n(status) { // 通过方法翻译betstatus用于展示的两个状态
			if (status === 'suspended') {
				return this.$t('suspend:status');
			}
			if (status === 'unavailable') {
				return this.$t('unavailable:status');
			}
			return '';
		},
		toggleBankerStatus() {
			if (this.disabled) {
				return;
			}
			// 最多能选event项
			// if (this.getBankerCount === this.getBetCount) {
			//
			// }

			// 更新store banker列表
			this.updateBankerList(this.slip.key);
		},
	},
	mounted() {
		EventBus.$on(listEvent.LIST_ITEM_DELETE, this.deleteBetSlip);
	}
};
</script>

<style lang="css">
</style>
