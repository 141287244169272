<template lang="html">
  <div>
    <i class="m-icon-suc"/>
  	<div class="m-txt-main">{{showCurrency}}{{getShowVal}}</div>
    <div class="m-txt-min" v-if="contentData.isPartial">{{i18n.t('partial:cashout:success')}}</div>
  	<div class="m-txt-min" v-else>{{i18n.t('cashout:success')}}</div>
  	<div class="m-txt-desc" v-if="contentData.isPartial">{{getTips}}</div>
    <div class="ok-btn" @click="confirm">{{i18n.t('ok:label')}}</div>
  </div>
</template>

<script>
import { i18n } from 'locale/setup';
import { showCurrency } from 'config/currencyConfig';

export default {
	data() {
		return {
			showCurrency,
			i18n
		};
	},
	computed: {
		getTips() {
			const contentData = this.contentData || {},
				isPartial = contentData.isPartial || false,
				count = contentData.remainCount || 0;

			if (isPartial) {
				return i18n.t('partial:cashout:remain', [i18n.tc('x:time', count, { count })]);
			}

			return '';
		},
		getShowVal() {
			const contentData = this.contentData || {};
			let val = contentData.val;

			val = +val;

			return val.toFixed(2);
		}
	},
	methods: {
		confirm() {
			this.$dialog();
		}
	}
};
</script>

<style lang="less">
@import 'base/style/variable.less';
@import "base/style/icon.less";

.m-openBet--suc.es-dialog {
  min-width: 280px;
	.es-dialog-main {
		margin-top: 0px;
		padding: 0 20px;
    	box-sizing: border-box;

		text-align: center;

		.m-icon-suc {
      height: 40px;
      line-height: 40px;
      .m-icon-success-bg();
      &::before {
        color: @c09D5DEtoE1FF2F;
        font-size: 40px;
        vertical-align: middle;
      }
    }

		.m-txt-main {
			margin-top: 6px;
			line-height: 24px;
			font-size: 20px;
			font-weight: bold;
			color: @c09D5DEtoE1FF2F;
		}

		.m-txt-min {
			margin-top: 14px;
			line-height: 24px;
			font-size: 14px;
			color: @pcDark;
		}

		.m-txt-desc {
			margin-top: 8px;
			line-height: 18px;
			font-size: 14px;
			color: @pcDark;
		}
    .ok-btn {
      display: inline-block;
      cursor: pointer;
      width: 120px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      background: @c09D5DEtoE1FF2F;
      border-radius: 2px;
      font-size: 16px;
      color: @cFFFFFFto000000;
      margin: 12px 0 18px 0;
    }
	}
}
</style>
