import { formatNumber, unformatNumber } from 'utils';
import { getStakeIndex } from 'config/betslip/stakeConfig';
import { showStakeTax, showWinTax } from 'config/taxConfig';

export default {
	data() {
		return {
			singleActualStake: {} // 单条的actual stake
		};
	},
	computed: {
		showStakeUnion() {
			const stakeUnion = this.stakeUnion || [];
			const res = {
				totalStake: 0,
				exciseTax: 0.0, // 税费 excise tax
				actualStake: 0, // 减去excise tax之后的stake
				minActualStake: 0, // 最小potwin对应的stake
				winTax: 0.0, // 奖金的税费
				potentialWinWithoutTax: 0, // 减去税费后的实得奖金
				totalOdds: 0,
				count: 0,
				// bonus 乘过stake
				bonus: 0,
				// 最小bonus 乘过stake
				minBonus: null,
				// 原始的bonus，没有乘 stake
				originBonus: 0,
				// 用于显示的bonus
				showBonus: 0,
				showTotalStake: '0',
				potentialWin: '0',
				isMinWithoutBonus: false, // 最小to win是否包含bonus
				minOdds: '',
				maxOdds: '',
				finalOdds: ''
			};
			let maxFinal = 0;
			let minFianl = 0;
			let minOdds = 0;
			let maxOdds = 0;
			if (this.currentType === 'single') {
				const cur = stakeUnion[0] || {},
					tempSingle = +cur.stake;
				res.count += cur.count || 0;
				res.boostCount = cur.boostCount || 0;

				const oddsInfo = cur.oddsInfoMap || [],
					bonus = cur.bonus || [],
					singleStake = this.singleActualStake || {};

				for (const tempBonus of bonus) {
					if (!tempBonus) {
						break;
					}

					res.bonus +=
						(tempBonus.value || 0) *
							(singleStake[tempBonus.key || ''] || 0) || 0;
					res.originBonus += tempBonus.value || 0;
				}

				let minPotWin = 0;
				let maxPotWin = 0;

				for (let i = 0, len = oddsInfo.length; i < len; i++) {
					const item = oddsInfo[i];
					const tempOdds = [];

					for (const oddsItem of item) {
						if (!oddsItem) {
							break;
						}

						tempOdds.push({
							win:
								(oddsItem.value || 0) *
								(singleStake[oddsItem.key || ''] || 0),
							stake: singleStake[oddsItem.key || ''],
							odds: oddsItem.value
						});
					}

					tempOdds.sort((a, b) => a.win - b.win);

					if (
						!minPotWin ||
						(tempOdds.length && tempOdds[0].win < minPotWin)
					) {
						minPotWin = tempOdds.length ? tempOdds[0].win : 0;
						res.minActualStake = tempOdds.length ?
							tempOdds[0].stake :
							0;
						res.isMinWithoutBonus = true;
					}

					maxPotWin +=
						(tempOdds.length &&
							tempOdds[tempOdds.length - 1].win) ||
						0;
				}

				if (tempSingle) {
					if (!minFianl || minFianl > minPotWin) {
						minFianl = minPotWin;
					}

					maxFinal = maxPotWin;
				}
				res.totalStake += tempSingle;
			} else if (this.currentType === 'multiple') {
				// 计算 stakeUnion 的 actual stake

				stakeUnion.forEach(cur => {
					const stake = +cur.stake;
					if (cur.count) {
						res.count += cur.count;

						const min = cur.min;
						const sum = cur.sum;

						res.bonus += +cur.bonus * stake || 0;
						res.minBonus += +cur.minBonus * stake || 0;
						res.originBonus += +cur.bonus || 0;
						const actualStake = +showStakeTax ?
							(stake * (1 - +showStakeTax)).toFixed(2) :
							stake;
						res.totalStake += stake * cur.count;

						if (stake) {
							if (!minFianl || (min && minFianl > min * stake)) {
								minFianl = min * stake;
								res.minActualStake = actualStake;
								res.isMinWithoutBonus = false;
							}
							maxFinal += sum * stake;
						}
					}
				});
			} else {
				// system
				stakeUnion.forEach(cur => {
					if (this.isSingles(cur)) {
						/** for 串关组合中的 singles */
						const tempSingle = cur.stake || 0;
						res.count += cur.count || 0;
						res.boostCount = cur.boostCount || 0;

						const oddsInfo = cur.oddsInfoMap || [],
							bonus = cur.bonus || [],
							singleStake = this.singleActualStake || {};

						for (const tempBonus of bonus) {
							if (!tempBonus) {
								break;
							}

							res.bonus +=
								(tempBonus.value || 0) *
									(singleStake[tempBonus.key || ''] || 0) ||
								0;
							res.originBonus += tempBonus.value || 0;
						}

						let minPotWin = 0;
						let maxPotWin = 0;
						for (let i = 0, len = oddsInfo.length; i < len; i++) {
							const item = oddsInfo[i];
							const tempOdds = [];

							for (const oddsItem of item) {
								if (!oddsItem) {
									break;
								}
								if (!singleStake[oddsItem.key]) {
									break;
								}

								tempOdds.push({
									win:
										(oddsItem.value || 0) *
										(singleStake[oddsItem.key || ''] || 0),
									stake: singleStake[oddsItem.key] || 0,
									odds: +singleStake[oddsItem.key] ? oddsItem.value : 0
								});
							}
							tempOdds.sort((a, b) => a.win - b.win);
							if (
								!minPotWin ||
								(tempOdds.length &&
									tempOdds[0] &&
									tempOdds[0].win < minPotWin &&
									+tempOdds[0].stake)
							) {
								minPotWin = tempOdds.length ?
									tempOdds[0].win :
									0;
								res.minActualStake = tempOdds.length ?
									tempOdds[0].stake :
									0;
								minOdds = tempOdds.length ?
								tempOdds[0].odds :
								0;
							}
							maxPotWin +=
								(tempOdds.length &&
									tempOdds[tempOdds.length - 1].win) ||
								0;
							maxOdds +=
							(tempOdds.length &&
								+tempOdds[tempOdds.length - 1].odds) ||
							0;
						}
						if (tempSingle) {
							if (!minFianl || minFianl > minPotWin) {
								minFianl = minPotWin;
								res.isMinWithoutBonus = true;
							}
							maxFinal = maxPotWin;
						}
						res.totalStake += +tempSingle;
					} else {
						/** for 串关组合中的各种 2 串, 3 串等 */
						const stake = +cur.stake;
						const actualStake = +showStakeTax ?
							(stake * (1 - +showStakeTax)).toFixed(2) :
							stake;
						if (cur.count) {
							res.count += cur.count;

							/** min 和 sum 是在 getSysOddsInfo() 计算的 */
							const min = cur.min;
							const sum = cur.sum;
							res.bonus += +cur.bonus * actualStake || 0;
							const tempBonus = +cur.minBonus * actualStake || 0;
							if (tempBonus && res.minBonus === null) {
								res.minBonus = tempBonus;
							} else if (tempBonus && res.minBonus !== null) {
								res.minBonus =
									res.minBonus > tempBonus ?
										tempBonus :
										res.minBonus;
							} else if (tempBonus === 0 && cur.stake) {
								res.minBonus = 0;
							}
							// res.minBonus = tempBonus < res.minBonus ? tempBonus : res;
							res.originBonus += +cur.bonus || 0;
							res.totalStake += stake * cur.count;

							if (stake) {
								if (
									!minFianl ||
									(min && minFianl > min * actualStake)
								) {
									minFianl = min * actualStake;
									res.minActualStake = actualStake;
									res.isMinWithoutBonus = false;
								}
								maxFinal += sum * actualStake;
								// 如果是区间 最小值和当前最小odds对比 最大值累加
								if (cur.odds.indexOf('~') > 0) {
									const tempOdds = cur.odds.split('~').map(item => Number(item.trim()));
									if (tempOdds[1] < minOdds) {
										minOdds = tempOdds[0];
									} else if (minOdds === 0){
										minOdds = tempOdds[0];
									}
									maxOdds += tempOdds[1];
								} else {
									if (+cur.odds < minOdds) {
										minOdds = +cur.odds;
									}
									maxOdds += +cur.odds;
								}
							}
						}
					}
				});
			}
			// 计算最后的odds
			if (maxOdds === minOdds) {
				res.finalOdds = formatNumber(minOdds, 2);
			} else {
				res.finalOdds = `${formatNumber(minOdds, 2)} ~ ${formatNumber(maxOdds, 2)}`;
			}
			if (stakeUnion.length === 1 && this.currentType === 'multiple') {
				res.totalOdds = stakeUnion[0].odds;
			}
			// 计算 actualStake excisetax
			if (Number(showStakeTax) && res.totalStake) {
				const exciseTax = (res.totalStake * +showStakeTax).toFixed(2);
				res.exciseTax = formatNumber(exciseTax, 2);
				res.actualStake = (res.totalStake - exciseTax).toFixed(2);
			}
			if (res.totalStake) {
				res.showTotalStake = formatNumber(res.totalStake, 2);
			}
			if (res.bonus > this.maxBonus) {
				res.bonus = this.maxBonus;
			}
			// max值加bonsu，min也加
			let maxPotentialWin = 0;
			maxPotentialWin = maxFinal + res.bonus;
			if (maxPotentialWin > this.maxPotWin) {
				maxPotentialWin = this.maxPotWin;
			}
			if (!res.isMinWithoutBonus) {
				minFianl += res.minBonus;
			}
			if (minFianl > this.maxPotWin) {
				minFianl = this.maxPotWin;
			}
			res.potentialWin =
				maxFinal === minFianl || !minFianl ?
					formatNumber(maxPotentialWin, 2) :
					minFianl === maxPotentialWin ?
					formatNumber(minFianl, 2) :
					`${formatNumber(minFianl, 2)} ~ ${formatNumber(
							maxPotentialWin,
							2
					  )}`;
			// 计算减去 wh tax 税后的 potwin
			if (res.potentialWin.indexOf('~') > -1) {
				const minTax = (
					(minFianl - res.minActualStake) *
					+showWinTax
				).toFixed(2);
				const min = (minFianl - minTax).toFixed(2);
				const maxTax = (
					(maxPotentialWin - res.actualStake) *
					+showWinTax
				).toFixed(2);
				const max = (maxPotentialWin - maxTax).toFixed(2);
				res.winTax = `${formatNumber(minTax, 2)} ~ ${formatNumber(
					maxTax,
					2
				)}`;
				res.potentialWinWithoutTax = `${formatNumber(
					min,
					2
				)} ~ ${formatNumber(max, 2)}`;
			} else {
				res.winTax = formatNumber(
					(unformatNumber(res.potentialWin) - +res.actualStake) *
						+showWinTax,
					2
				);
				res.potentialWinWithoutTax = formatNumber(
					(
						unformatNumber(res.potentialWin) -
						unformatNumber(res.winTax)
					).toFixed(2),
					2
				);
			}
			if (res.bonus) {
				// res.showBonus = formatNumber(res.bonus, 2);
				// console.log('final', res.minBonus, res.bonus);
				res.showBonus =
					res.minBonus === res.bonus ?
						formatNumber(res.bonus, 2) :
						`${formatNumber(res.minBonus, 2)} ~ ${formatNumber(
								res.bonus,
								2
						  )}`;
			}
			return res;
		}
	},
	watch: {
		// 计算单条的actual stake
		singleStake: {
			deep: true,
			handler(val) {
				for (const i in val) {
					this.singleActualStake[i] = (
						this.singleStake[i] *
						(1 - +showStakeTax)
					).toFixed(2);
				}
			}
		}
	},
	methods: {
		/** FIXME: 7 月之前版本在两个 mixin 中都有同样的方法, 复用的不科学. */
		isSingles(item) {
			return item && item.name && getStakeIndex(item.name) === 1;
		}
	}
};
