var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["nav-bar-wrap", { showMore: _vm.showMore }] }, [
    _c("div", { staticClass: "nav-bar" }, [
      _c(
        "div",
        { staticClass: "nav-list" },
        [
          _vm._l(_vm.navList, function(item, index) {
            return _c(
              "div",
              {
                class: ["nav-item", { active: +item.id === +_vm.sportId }],
                on: {
                  mouseleave: _vm.leaveItem,
                  mouseenter: function($event) {
                    _vm.hoverItem(item)
                  },
                  click: function($event) {
                    _vm.select(item)
                  }
                }
              },
              [
                _c("div", { staticClass: "icon-wrap" }, [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: +item.id === +_vm.sportId,
                        expression: "+item.id === +sportId"
                      }
                    ],
                    staticClass: "nav-icon",
                    attrs: { src: item.colorIcon }
                  }),
                  _vm._v(" "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          +item.id !== +_vm.hoverId &&
                          +item.id !== +_vm.sportId,
                        expression:
                          "+item.id !== +hoverId && +item.id !== +sportId"
                      }
                    ],
                    staticClass: "nav-icon",
                    attrs: { src: item.icon }
                  }),
                  _vm._v(" "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          +item.id === +_vm.hoverId &&
                          +item.id !== +_vm.sportId,
                        expression:
                          "+item.id === +hoverId && +item.id !== +sportId"
                      }
                    ],
                    staticClass: "nav-icon",
                    attrs: { src: item.hoverIcon }
                  }),
                  _vm._v(" "),
                  item.id !== "popular" && !_vm.sizeLoading
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.sportNums["sr:sport:" + item.id]))
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "nav-title" }, [
                  _vm._v(_vm._s(item.title))
                ])
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "nav-more-game",
              on: { mouseenter: _vm.enterMore, mouseleave: _vm.leaveMore }
            },
            [
              _c(
                "div",
                {
                  class: ["nav-item", { active: _vm.inMoreList }],
                  on: {
                    mouseenter: function($event) {
                      _vm.hoverItem({ id: "99999" })
                    },
                    mouseleave: _vm.leaveItem
                  }
                },
                [
                  _c("div", { staticClass: "icon-wrap" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.inMoreList,
                          expression: "inMoreList"
                        }
                      ],
                      staticClass: "nav-icon",
                      attrs: { src: _vm.itemInMoreList.colorIcon }
                    }),
                    _vm._v(" "),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.inMoreList && +_vm.hoverId !== 99999,
                          expression: "!inMoreList && +hoverId !== 99999"
                        }
                      ],
                      staticClass: "nav-icon",
                      attrs: { src: _vm.itemInMoreList.icon }
                    }),
                    _vm._v(" "),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.inMoreList && +_vm.hoverId === 99999,
                          expression: "!inMoreList && +hoverId === 99999"
                        }
                      ],
                      staticClass: "nav-icon",
                      attrs: { src: _vm.itemInMoreList.hoverIcon }
                    }),
                    _vm._v(" "),
                    _vm.inMoreList && !_vm.sizeLoading
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.sportNums["sr:sport:" + _vm.itemInMoreList.id]
                            )
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "nav-title" }, [
                    _vm._v(_vm._s(_vm.itemInMoreList.title))
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.showMore
                ? _c("i", { staticClass: "m-icon-arrow-up" })
                : _c("i", { staticClass: "m-icon-arrow-down" }),
              _vm._v(" "),
              _vm.showMore
                ? _c(
                    "div",
                    { staticClass: "more-game-list" },
                    _vm._l(_vm.moreList, function(item, index) {
                      return _c(
                        "div",
                        {
                          class: [
                            "nav-item",
                            { active: +item.id === +_vm.sportId }
                          ],
                          on: {
                            click: function($event) {
                              _vm.select(item)
                            },
                            mouseleave: _vm.leaveItem,
                            mouseenter: function($event) {
                              _vm.hoverItem(item)
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "icon-wrap" }, [
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: +item.id === +_vm.sportId,
                                  expression: "+item.id === +sportId"
                                }
                              ],
                              staticClass: "nav-icon",
                              attrs: { src: item.colorIcon }
                            }),
                            _vm._v(" "),
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    +item.id !== +_vm.hoverId &&
                                    +item.id !== +_vm.sportId,
                                  expression:
                                    "+item.id !== +hoverId && +item.id !== +sportId"
                                }
                              ],
                              staticClass: "nav-icon",
                              attrs: { src: item.icon }
                            }),
                            _vm._v(" "),
                            _c("img", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    +item.id === +_vm.hoverId &&
                                    +item.id !== +_vm.sportId,
                                  expression:
                                    "+item.id === +hoverId && +item.id !== +sportId"
                                }
                              ],
                              staticClass: "nav-icon",
                              attrs: { src: item.hoverIcon }
                            }),
                            _vm._v(" "),
                            item.id !== "popular" && !_vm.sizeLoading
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.sportNums["sr:sport:" + item.id])
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "nav-title" }, [
                            _vm._v(_vm._s(item.title))
                          ])
                        ]
                      )
                    })
                  )
                : _vm._e()
            ]
          )
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }