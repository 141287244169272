var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasMutexGroup && !_vm.isSingle
        ? _c("div", { staticClass: "m-stake-tips" }, [
            _c("i", { staticClass: "m-icon--tips" }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.$t("contingent:label")))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "m-plays-wrapper" }, [
        _vm.isSystem && _vm.hasSingle && _vm.has1MoreSingle
          ? _c("div", { staticClass: "m-opt-assist" }, [
              _c("div", { staticClass: "m-label" }, [
                _vm._v(_vm._s(_vm.$t("play:all:single:label")))
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "m-content" },
                [
                  _c("span", { staticClass: "m-s-unit" }, [
                    _vm._v(_vm._s(_vm.showCurrency))
                  ]),
                  _vm._v(" "),
                  _c("AfInput", {
                    attrs: {
                      error: !!_vm.unionErrorInfo,
                      placeholder: _vm.$t("min:stake", [_vm.minStake]),
                      id: "j_unionStake"
                    },
                    on: { change: _vm.unionifyStake },
                    model: {
                      value: _vm.unionStake,
                      callback: function($$v) {
                        _vm.unionStake = $$v
                      },
                      expression: "unionStake"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.unionErrorInfo
                ? _c("p", { staticClass: "m-text-error" }, [
                    _vm._v(_vm._s(_vm.unionErrorInfo))
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "m-plays" },
          [
            _vm.showTitle
              ? [
                  false && _vm.showComboTitle
                    ? _c("div", { staticClass: "m-plays-title" }, [
                        _c("div", { staticClass: "m-type" }, [
                          _vm._v(_vm._s(_vm.$t("type:label")))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "m-num" }, [
                          _vm._v(_vm._s(_vm.$t("number:label")))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "m-money" }, [
                          _vm._v(
                            _vm._s(_vm.$t("stake:label")) +
                              "(" +
                              _vm._s(_vm.showCurrencyFix) +
                              ")"
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.stakeUnion, function(stakeItem, index) {
                    return [
                      !_vm.isSingles(stakeItem)
                        ? _c(
                            "div",
                            {
                              key: stakeItem.name,
                              staticClass: "m-plays-item",
                              attrs: { id: "j_stake_" + index }
                            },
                            [
                              _c("div", { staticClass: "m-line-wrapper" }, [
                                _c("div", { staticClass: "m-type" }, [
                                  _vm._v(
                                    _vm._s(_vm.getStakeNameI18n(stakeItem.name))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "m-num" }, [
                                  _vm._v(_vm._s(stakeItem.count || ""))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "m-money" },
                                  [
                                    _c("AfInput", {
                                      attrs: {
                                        placeholder: _vm.$t("min:stake", [
                                          _vm.minStake
                                        ]),
                                        "data-index": index,
                                        error: _vm.showUniqueStakeError(index)
                                      },
                                      on: {
                                        change: _vm.modifyStake,
                                        blur: function($event) {
                                          _vm.checkUniqueStake($event, index)
                                        },
                                        input: _vm.setCheckStatus,
                                        focus: function($event) {
                                          _vm.changeActiveIn("stake_" + index)
                                        }
                                      },
                                      model: {
                                        value: _vm.stake[index],
                                        callback: function($$v) {
                                          _vm.$set(_vm.stake, index, $$v)
                                        },
                                        expression: "stake[index]"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.showUniqueStakeError(index) &&
                                        !_vm.errorInfo.sumMsg,
                                      expression:
                                        "showUniqueStakeError(index) && !errorInfo.sumMsg"
                                    }
                                  ],
                                  staticClass: "m-error-tips"
                                },
                                [_vm._v(_vm._s(_vm.getUniqueErrorMsg(index)))]
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  })
                ]
              : [
                  _c("div", { staticClass: "m-plays-item" }, [
                    _c("div", { staticClass: "m-line-wrapper" }, [
                      _c("div", { staticClass: "m-label" }, [
                        _vm._v(_vm._s(_vm.$t("total:stake:label")))
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "m-value" },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.showCurrency))]),
                          _vm._v(" "),
                          _c("AfInput", {
                            attrs: {
                              placeholder: _vm.$t("min:stake", [_vm.minStake]),
                              "data-index": 0,
                              error: _vm.showUniqueStakeError(0),
                              id: "j_stake_0"
                            },
                            on: {
                              change: _vm.modifyStake,
                              blur: function($event) {
                                _vm.checkUniqueStake($event, 0)
                              },
                              input: _vm.setCheckStatus,
                              focus: function($event) {
                                _vm.changeActiveIn("stake_0")
                              }
                            },
                            model: {
                              value: _vm.stake[0],
                              callback: function($$v) {
                                _vm.$set(_vm.stake, 0, $$v)
                              },
                              expression: "stake[0]"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.showUniqueStakeError(0) &&
                              !_vm.errorInfo.sumMsg,
                            expression:
                              "showUniqueStakeError(0) && !errorInfo.sumMsg"
                          }
                        ],
                        staticClass: "m-error-tips"
                      },
                      [_vm._v(_vm._s(_vm.getUniqueErrorMsg(0)))]
                    )
                  ])
                ]
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm.actionTips
        ? _c("Tips", { attrs: { type: "error", msg: _vm.actionTips } })
        : _vm.errorInfo.sumMsg
        ? _c("Tips", { attrs: { type: "error", msg: _vm.errorInfo.sumMsg } })
        : _vm._e(),
      _vm._v(" "),
      _c("FlexiBet", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showFlexibetBar,
            expression: "showFlexibetBar"
          }
        ]
      }),
      _vm._v(" "),
      _c("BetslipSetting"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "m-money-wrapper" },
        [
          _vm.isMultiple
            ? _c("div", [
                _c("div", { staticClass: "m-label m-label-fix" }, [
                  _vm._v(_vm._s(_vm.$t("odds:label")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "m-value m-value-fix" }, [
                  _vm._v(_vm._s(_vm.showMultipleOdds))
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showTitle
            ? _c("div", { staticClass: "m-money" }, [
                _c("div", { staticClass: "m-label" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("total:stake:label")))]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "m-tips",
                      on: {
                        click: function($event) {
                          _vm.showStakeTips(_vm.defaultShowTipType)
                        }
                      }
                    },
                    [_c("i", { staticClass: "m-icon-tips" })]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "m-value" }, [
                  _vm._v(
                    _vm._s(_vm.showCurrency) + " " + _vm._s(_vm.getTotalStake)
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showStakeUnion.originBonus > 0
            ? _c("div", { staticClass: "m-money" }, [
                _c("div", { staticClass: "m-label" }, [_vm._v("Bonus")]),
                _vm._v(" "),
                _c("div", { staticClass: "m-value" }, [
                  _vm._v(
                    _vm._s(_vm.showCurrency) +
                      " " +
                      _vm._s(_vm.showStakeUnion.showBonus)
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          +_vm.showStakeTax == 0
            ? _c("div", { staticClass: "m-money" }, [
                _c("div", { staticClass: "m-label" }, [
                  _vm._v(_vm._s(_vm.$t("to:win:label")))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "m-value" }, [
                  _vm._v(
                    _vm._s(_vm.showCurrency) +
                      " " +
                      _vm._s(_vm.showStakeUnion.potentialWin)
                  )
                ])
              ])
            : [
                _c("div", { staticClass: "m-money" }, [
                  _c("div", { staticClass: "m-label" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("pot:ex:tax")))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "m-tips",
                        on: {
                          click: function($event) {
                            _vm.showStakeTips(2)
                          }
                        }
                      },
                      [_c("i", { staticClass: "m-icon-tips" })]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "m-value" }, [
                    _vm._v(
                      _vm._s(_vm.showCurrency) +
                        " " +
                        _vm._s(_vm.showStakeUnion.exciseTax)
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "m-money" }, [
                  _c("div", { staticClass: "m-label" }, [
                    _vm._v(_vm._s(_vm.$t("actual:stake:label")))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "m-value" }, [
                    _vm._v(
                      _vm._s(_vm.showCurrency) +
                        " " +
                        _vm._s(_vm.showStakeUnion.actualStake)
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "m-money" }, [
                  _c("div", { staticClass: "m-label" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.$t("pot:wh:tax")) +
                        "\n\t\t\t\t\t\t"
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "m-tips",
                        on: {
                          click: function($event) {
                            _vm.showStakeTips(3)
                          }
                        }
                      },
                      [_c("i", { staticClass: "m-icon-tips" })]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "m-value" }, [
                    _vm._v(
                      _vm._s(_vm.showCurrency) +
                        " " +
                        _vm._s(_vm.showStakeUnion.winTax)
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "m-money" }, [
                  _c("div", { staticClass: "m-label" }, [
                    _vm._v(_vm._s(_vm.$t("pot:win:label")))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "m-value" }, [
                    _vm._v(
                      _vm._s(_vm.showCurrency) +
                        " " +
                        _vm._s(_vm.showStakeUnion.potentialWinWithoutTax)
                    )
                  ])
                ])
              ],
          _vm._v(" "),
          _c("div", { staticClass: "m-money" }, [
            _c("div", { staticClass: "m-label" }, [
              _vm._v(_vm._s(_vm.$t("total:odds:label")))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "m-value" }, [
              _vm._v(_vm._s(_vm.showStakeUnion.finalOdds))
            ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "m-btn-wrapper place-bet" },
        [
          _vm.betLoading
            ? _c(
                "af-Button",
                {
                  staticClass: "m-btn--submit",
                  attrs: {
                    extraType: "text",
                    disabled: !_vm.submitStatus,
                    loading: true
                  }
                },
                [_vm._v(_vm._s(_vm.$t("submitting:label")) + "...\n\t\t")]
              )
            : [
                !_vm.acceptStatus
                  ? _c(
                      "af-Button",
                      {
                        attrs: {
                          extraType: "primary",
                          disabled: !_vm.submitStatus,
                          loading: false
                        },
                        on: { click: _vm.commitBet }
                      },
                      [_vm._v(_vm._s(_vm.$t("place:bet:label")) + "\n\t\t\t")]
                    )
                  : _c(
                      "af-Button",
                      {
                        attrs: { extraType: "primary" },
                        on: { click: _vm.accpetChanges }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("accept:changes:label")) + "\n\t\t\t"
                        )
                      ]
                    )
              ]
        ],
        2
      ),
      _vm._v(" "),
      _vm.showComfirm
        ? [
            _c(
              "div",
              { staticClass: "m-comfirm-wrapper", style: _vm.getConfirmStyle },
              [
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "m-content-wrapper" }, [
                      _c("div", { staticClass: "m-label" }, [
                        _vm._v(_vm._s(_vm.getConfirmTitle))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "m-value" }, [
                        _vm._v(_vm._s(_vm.showCurrency)),
                        _c("span", { staticClass: "m-money" }, [
                          _vm._v(_vm._s(_vm.getShowPayMoney))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.isFlexi
                      ? [
                          _c("div", { staticClass: "m-line" }, [
                            _c(
                              "div",
                              { staticClass: "m-line-label m-line-label-fix" },
                              [_vm._v("Options to be Correct")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "m-line-value" }, [
                              _vm._v(
                                _vm._s(_vm.flexiSelect) +
                                  " of " +
                                  _vm._s(_vm.flexiThreshold)
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "m-line" }, [
                            _c("div", { staticClass: "m-line-label" }, [
                              _vm._v("Total Odds")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "m-line-value" }, [
                              _vm._v(_vm._s(_vm.showMultipleOdds))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "m-line" }, [
                            _c("div", { staticClass: "m-line-label" }, [
                              _vm._v("To Win")
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "m-line-value" }, [
                              _vm._v(_vm._s(_vm.earnings))
                            ])
                          ])
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "m-btn-wrapper" },
                      [
                        _c(
                          "af-Button",
                          {
                            attrs: { extraType: "text" },
                            on: { click: _vm.cancelPlaceBet }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("cancel:label:lowercase")) +
                                "\n\t\t\t\t\t"
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "af-Button",
                          {
                            attrs: { extraType: "primary" },
                            on: { click: _vm.commitPlaceBet }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("confirm:label")) + "\n\t\t\t\t\t"
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }