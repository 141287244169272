<template>
  <div class="top-nav-wrap">
    <div class="top-nav">
      <template v-for="(nav, index) in topNavs">
        <div v-if="nav.subNav" :class="['top-nav-item', {active: isActive(nav)}]" @mouseenter="showSubNav" @mouseleave="hideSubNav">
          {{nav.title}}
          <div class="top-sub-nav" v-if="isSubNavShow">
            <template v-for="(nav, index) in topNavs[0].subNav">
              <div :class="['sub-nav-item', {active: isSubNavActive(nav)}]" @click="goToUrl(nav)">{{nav.title}}</div>
            </template>
            <div class="flex-block"/>
          </div>
        </div>
        <div v-else :class="['top-nav-item']" @click="goToUrl(nav)">
          <div :class="['text', {active: isActive(nav)}]">{{nav.title}}</div>
        </div>
      </template>
      <div class="flex-block"/>
      <SimpleSelect :class="['nav-simple-select']"
  		:data="formatTypeList"
  		@selected="onSelectOddsType" :defaultIndex="typeList.indexOf(oddsType)"></SimpleSelect>
      <div class="m-divier"/>
      <SimpleSelect :class="['nav-simple-select']"
    	:data="formatLangList"
    	@selected="onSelectLang" :defaultIndex="defaultLangIndex"></SimpleSelect>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import SimpleSelect from 'components/simpleSelect';
import { pagePath } from 'config/pageConfig';
import { typeList, getTypeI18n } from 'utils/odds';
import { getLangList } from 'utils/lang';
import { getTopNavs } from '../js/config';
import { invokeLogin } from 'login/index';

export default {
	props: ['value'],
	components: {
		SimpleSelect
	},
	data() {
		return {
			topNavs: getTopNavs(),
			typeList,
			isSubNavShow: false
		};
	},
	created() {

	},
	computed: {
		...mapState('betslip', ['oddsType']),
		defaultLangIndex() {
			let index = 0;
			this.formatLangList.forEach((lang, i) => {
				if (lang.name === window.lang) {
					index = i;
				}
			});
			return index;
		},
		formatTypeList() {
			const list = this.typeList || [];
			const i18nMap = getTypeI18n();
			return list.map(item => ({
				label: i18nMap[item],
				value: item
			}));
		},
		formatLangList() {
			return getLangList();
		}
	},
	methods: {
		...mapMutations('betslip', ['changeOddsType']),
		onSelectOddsType(payload) {
			this.changeOddsType(payload.item.value);
		},
		onSelectLang(value) {
			window.location.href = `${pagePath.home}?lang=${value.item.name}`;
		},
		showSubNav() {
			if (!this.isSubNavShow) {
				this.isSubNavShow = true;
			}
		},
		hideSubNav() {
			if (this.isSubNavShow) {
				this.isSubNavShow = false;
			}
		},
		isActive(nav) {
			if (nav.subNav) {
				let haveSub = false;
				nav.subNav.forEach(item => {
					if (item.name === this.value) {
						haveSub = true;
					}
				});
				return this.value === nav.name || haveSub;
			}
			return this.value === nav.name;
		},
		isSubNavActive(nav) {
			return nav.name === this.value;
		},
		goToUrl(nav) {
			if (nav && nav.link) {
				// 这个逻辑先保留
				// if (['Result'].indexOf(nav.name) > -1) {
				// 	window.open(nav.link);
				// 	return;
				// }
				if (['Transactions', 'Bet history'].includes(nav.name)) {
					if (!window.loginStatus) {
						invokeLogin(() => {
							location.href = nav.link;
						});
						return;
					}
				}

				location.href = nav.link;
			}
		}
	}
};
</script>

<style lang="less">
@import '~base/styles/variable.less';
.top-nav-wrap {
  background-color: #F98500;
  color: @white;
  border-left: 1px solid @lightGray;
  border-right: 1px solid @lightGray;
}

.top-nav {
  position: relative;
  width: 1000px;
  min-width: 1000px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .top-nav-item {
    position: relative;
    .text {
      padding: 0 6px;
      cursor: pointer;
      font-size: 16px;
      height: 38px;
      line-height: 38px;
      word-break:keep-all;
      white-space:nowrap;
      &:hover {
        background-color: #FF6C14;
      }
      &.active {
        font-weight: bold;
        color: #F98500;
        background-color: @white;
      }
    }
    .top-sub-nav {
      position: absolute;
      left: 0;
      top: 38px;
      width: 1000px;
      min-width: 1000px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @white;
      border: 1px solid @black;
      z-index: 2;
      .sub-nav-item {
        cursor: pointer;
        padding: 0 15px;
        font-size: 16px;
        line-height: 35px;
        color: @black;
        font-weight: normal;
        border-bottom: 3px solid transparent;
        &:hover {
          color: @hoverYellow;
        }
        &.active {
          font-weight: bold;
          color: @yellowTextColor;
        }
      }
    }
  }
  .divider {
    height: 24px;
    width: 1px;
    background-color: @grayTextColor;
  }
  .flex-block {
    flex: 1 1 auto;
  }
  .nav-simple-select {
    min-width: 90px;
    background-color: transparent;
    color: @white;
    &.simple-select-wrap.simple-select-wrap-expand {
      .select-title,.select-list {
        background-color: #F98500;
      }
      .select-list {
        top: 37px;
        border: 1px solid @white;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        .select-item {
          line-height: 32px;
          height: 32px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          border-bottom: 1px solid #EEEEEE;
          &:hover {
            background: #ED7534;
          }
          &.select-item-active {
            background: #F7D06F;
          }
          &:last-of-type {
            border: none;
          }
        }
      }
    }
    &.simple-select-wrap {
      font-size: 12px;
      height: 38px;
      line-height: 38px;
      .select-title {
        height: 38px;
        line-height: 38px;
        text-align: center;
        .arrow-icon {
          color: @white;
          height: 36px;
          line-height: 36px;
          background-color: #F98500;
        }
      }
    }
  }
  .m-divier {
    margin: 0 6px;
    height: 24px;
    width: 1px;
    background: @white;
  }
}
</style>
