<template>
	<Layout navValue="Today" :fetchSizeParams="fetchSizeParams">
		<template slot="skeleton">
			<skeleton></skeleton>
		</template>
		<MainSection slot="main-pagelet" rightWidth="235px">
			<div slot="top">
				<RecommendEvents></RecommendEvents>
			</div>
			<div slot="mid">
				<live-match></live-match>
				<important-match></important-match>
			</div>
			<div slot="right">
				<RightPagelet v-if="!showSkeleton" :showRegister="false"/>
			</div>
		</MainSection>
	</Layout>
</template>

<script>
// import { LS } from 'storage';
// import md5 from 'blueimp-md5';
import Layout from 'components/layout/layout';
import MainSection from 'components/layout/main';
import RightPagelet from 'components/layout/right.vue';
import { mapState, mapActions } from 'vuex';
import { getTimeParam } from 'config/timeConfig';
// import top from './top.vue';
import RecommendEvents from './recommendEvents.vue';
import importantMatch from './importantMatch.vue';
import liveMatch from './liveMatch.vue';
import Skeleton from 'components/skeleton/skeleton'

export default {

	name: 'home',
	components: {
		Layout,
		MainSection,
		RightPagelet,
		RecommendEvents,
		importantMatch,
		liveMatch,
		Skeleton
	},
	data() {
		return {
			fetchSizeParams: {
				productId: 3, // 今日比赛属于prematch
				...getTimeParam({
					value: 'today'
				})
			}
		};
	},
	computed: {
		...mapState('layout', [
			'showSkeleton'
		]),
		...mapState('home', ['recommendEvents'])
	},
	watch: {
	},
	methods: {
		...mapActions('home', [
			// 'fetchHomePC',
			'subProductStatus',
			'unSubProductStatus'
		]),
	},

	created() {
		this.subProductStatus();
	},
	destroyed () {
		this.unSubProductStatus();
	}
};
</script>

<style lang="less">
@import './home.less';
</style>
