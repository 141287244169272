<template>
	<div class="important-match" v-if="!showSkeleton">
		<div class="banner">
			<span class="text">
				<img src="./image/sport.png"/>
				{{ $t('highlight:label') }}
			</span>
			<div class="config" v-show="!loading">
				<span @click="fetchData" class="refresh">{{ $t('refresh:label') }}</span>
			</div>
		</div>
		<div id="important-match">
			<div class="sport-name" v-if="sportSizeByOrder && sportSizeByOrder.length">
				<div
					:class="['sport-name-item', {
						active: currentSportId === sport.id
					}]"
					v-for="sport in preSportSize"
					:key="sport.id"
					@click="onChangeSport(sport.id)">
					<div class="text">{{ sport.name }}<span>{{ sport.eventSize > 99 ? '99+' : sport.eventSize }}</span>
					</div>
				</div>
				<SimpleSelect v-if="postSportSize.length > 0"
							  :class="['sport-simple-select', isSelectedActive ? 'sport-simple-select-active' : '' ]"
							  :data="postSportSize" :placeholder="$t('more-game:label')"
							  @selected="onSelectedSport" ref="select"></SimpleSelect>
			</div>
			<div v-loading:fetchData="loading">
				<template v-if="!loading">
					<div v-if="sportProductStatus === 1" class="m-sport-bet-error">
						{{ $t('data:load:fail') }}
					</div>
					<div v-else-if="!hasSports" class="m-sport-bet-no-data">
						<p>{{ $t('no:match:available:1') }}</p>
						<p>{{ $t('no:match:available:2') }}</p>
					</div>
					<div class="match" v-else>
						<div class="flex-box">
							<div class="left">
								<div :class="['market-group', {
									'active': currentGroup === i
									}]" v-for="(group, i) in sportMarketIds[currentSportId]" :key="i"
									 v-if="group.value !== 'default'" @mouseenter="changeGroup(i)">{{ group.name }}
								</div>
							</div>
							<div class="right">
								<div class="market-block"
									 v-for="(market, i) in sportMarketIds[currentSportId][currentGroup].marketIds"
									 :key="i">
									<div class="market-name">
										{{ getMarketConfig(market, productId, currentSportId).name }}
									</div>
									<div class="outcome-name-list">
										<div class="outcome-name" v-if="title"
											 v-for="(title, i) in getMarketConfig(market, 3, currentSportId).titleArr"
											 :key="i">{{ title }}
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 循环日期 -->
						<template v-for="(dateEvents, key) in sport.eventOrder">
							<!-- 如果是赛前比赛多一行显示日期和title -->
							<template v-for="(eventId, index) in dateEvents">
								<SportEvent
									:key="eventId"
									:event="sport.events[eventId]"
									:currentMarketIds="currentMarketIds"
									:sportId="currentSportId"
									:productId="productId"
								>
								</SportEvent>
							</template>
						</template>
						<div class="view-all-wrap" v-if="sport">
							<div class="blank-block"/>
							<div class="view-all" @click="viewAllImportant">
								<i18n path="view:all:sport" tag="p">
									<span place="sport">{{ $t('today:adj') }} {{ currentSportName }}</span>
									<i place="match">{{ $tc('match:label', sportSizeMap[currentSportId]) }}
										{{ sportSizeMap[currentSportId] }}</i>
								</i18n>
							</div>
							<i class="icon-arrow--right" @click="viewAllImportant"/>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import SportEvent from 'components/eventList/sportEvent.vue';
import LiveMatchTracker from 'packages/liveMatchTracker/liveMatchTracker';
import {mapState, mapMutations, mapActions} from 'vuex';
import {getSportConfigLowerCase, getIdToSport} from 'config/sportsType';
import {baseUrl} from 'config/pageConfig';
import {isEmptyObject} from 'utils';
import SimpleSelect from 'components/simpleSelect';
import 'components/loading';
import 'components/popOver';
import {getMarketConfig} from 'config/marketConfig';
import {getTimeParam} from 'config/timeConfig';
import commonEvent from 'config/eventConfig/commonEvent';
import {EventBus} from 'kernel/js/eventBus';

export default {
	name: 'importantMatch',
	components: {
		SportEvent,
		LiveMatchTracker,
		SimpleSelect
	},
	computed: {
		...mapState('layout', [
			'showSkeleton'
		]),
		...mapState('home', [
			'sport',
			'currentGroup',
			'getSportMarketIds',
			'sportProductStatus'
		]),
		...mapState('sportSize', [
			'sportSize'
		]),
		sportMarketIds() {
			return this.getSportMarketIds();
		},
		supportSportsId() {
			return Object.keys(this.sportMarketIds) || [];
		},
		currentMarketIds() {
			return this.sportMarketIds[this.currentSportId][this.currentGroup].marketIds;
		},
		hasSports() {
			return this.sport && this.sport.eventOrder && !isEmptyObject(this.sport.eventOrder);
		},
		sportSizeByOrder() {
			// 这里用来过滤一下不在highlight展示的体育类型
			const sizes = this.sportSize || [];
			return sizes.filter(item => this.supportSportsId.indexOf(item.id) > -1);
		},
		sportLen() {
			return this.sportSizeByOrder.length;
		},
		preSportSize() {
			return this.sportLen > 7 ? this.sportSizeByOrder.slice(0, 6) : this.sportSizeByOrder;
		},
		postSportSize() {
			const sizes = this.sportLen > 7 ? this.sportSizeByOrder.slice(6) : [];
			return sizes.map(item => ({
				...item,
				label: item.name
			}));
		},
		postSportId() {
			return this.postSportSize.map(item => item.id);
		},
		isSelectedActive() {
			return this.postSportId.includes(this.currentSportId);
		},
		idToSport() {
			return getIdToSport();
		},
		sportConfigLowerCase() {
			return getSportConfigLowerCase();
		},
		currentSportName() {
			return this.currentSportId ? this.idToSport[this.currentSportId].title : '';
		},
		sportSizeMap() {
			const map = {};
			this.sportSize.forEach(sport => {
				map[sport.id] = sport.eventSize || 0;
			});
			return map;
		}
	},
	data() {
		return {
			productId: 3,
			// 当前联赛
			currentTournamentId: null,
			// 当前运动类型
			currentSportId: '',
			loading: false,
			h2hStatus: {},
			appLoaded: false, //如果是骨架屏，该值为true，则不触发骨架屏展示
		};
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapMutations('home', ['updateCurrentGroup']),
		...mapActions('sportSize', ['fetchSportSize']),
		...mapActions('home', [
			'fetchPcImportantEvents',
		]),
		getMarketConfig,
		toggleH2h(eventId) {
			this.h2hStatus = {
				[eventId]: !this.h2hStatus[eventId]
			};
		},
		changeSport(id) {
			this.currentSportId = id;
			this.fetchData();
		},
		changeTournament(id) {
			this.currentTournamentId = id;
		},
		changeGroup(i) {
			this.updateCurrentGroup(i);
		},
		// importantMatch -- 请求不同的 sportId 下的比赛
		fetchData() {
			if (!this.appLoaded) {
				EventBus.$emit(commonEvent.SKELETON_COMPONENT_REGISTER)
			}
			this.loading = true;
			return this.fetchSportSize({
				productId: this.productId,
				option: 1,
				...getTimeParam({
					value: 'today'
				})
			})
				// 默认选中第一个
				.then(data => {
					// data = data.filter(sport => limitSportId.includes(sport.id));

					// 如果tab更新，正好选中的那个被干掉了，就切换到第一个去
					if (data && data.length) {
						if (!this.currentSportId || !data.find(cur => cur.id === this.currentSportId)) {
							this.currentSportId = data[0].id;
						}
					} else {
						this.currentSportId = null;
					}
				})
				.then(() => {
					if (this.currentSportId) {
						const marketId = this.getMarketIdsBySportId(this.currentSportId);
						if (marketId) {
							return this.fetchPcImportantEvents({
								sportId: this.currentSportId,
								marketId
							})
								.then(() => {
									if (this.sport && this.sport.tournamentOrder &&
										this.sport.tournamentOrder.length) {
										this.currentTournamentId = this.sport.tournamentOrder[0];
									}
								});
						}
					}
				})
				.then(res => {
					if (!res || !res.abort) {
						this.loading = false;
					}
				})
				.catch(res => {

					if (!res || !res.abort) {
						this.loading = -1;
					}
				}).finally(() => {
					if(!this.appLoaded) {
						EventBus.$emit(commonEvent.SKELETON_COMPONENT_LOADED)
						this.appLoaded = true
					}
				});
		},
		getMarketIdsBySportId(sportId) {
			const markets = this.sportMarketIds[sportId];
			let arr = [];
			markets.forEach(market => {
				arr = arr.concat(market.marketIds);
			});
			return arr.join(',');
		},
		// 跳到 Sports -- Game List页面
		viewAllImportant() {
			const url = `${baseUrl}sport/${this.sportConfigLowerCase[this.currentSportId.slice(9)]}/today`;
			window.location.href = url;
		},
		onSelectedSport(payload) {
			const id = payload.item.id;
			this.changeSport(id);
		},
		onChangeSport(id) {
			this.changeSport(id);
			const $select = this.$refs.select;
			$select && $select.clear();
		}
	}
};
</script>

<style lang="less" scoped>
@import '~base/style/variable.less';
@import '~base/style/mixin.less';

.flex-box {
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid @lightGrayDarkGray;

	.left {
		width: 230px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 15px 0;

		.market-group {
			flex: 0 0 auto;
			padding: 0 8px;
			white-space: nowrap;
			line-height: 18px;
			font-size: 12px;
			color: @lightGrayLightWhite;
			border-right: 1px solid @lightGrayLightWhite;
			cursor: pointer;

			&.active {
				color: @c09D5DEtoE1FF2F;
				border-color: @c09D5DEtoE1FF2F;
			}
		}
	}

	.right {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		color: @lightGrayLightWhite;
		padding-right: 49px;

		.market-block {
			flex: 1;
			line-height: 24px;

			.market-name {
				width: 100%;
				text-align: center;
			}

			.outcome-name-list {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.outcome-name {
				flex: 1;
				text-align: center;
			}
		}
	}
}
</style>
<style lang="less">
@import '~base/style/variable.less';

.important-match {
	#important-match {
		.match {
			.match-row {
				display: flex;
				align-items: center;
				justify-content: center;

				.left-team-cell {
					flex: 0 0 230px;
				}

				.market-size {
					margin-left: 6px;
					flex: 0 0 43px;
					white-space: nowrap;
					color: @lightGrayLightWhite;
				}

				.market-cell {
					display: flex;
					align-items: center;
					justify-content: center;

					.m-market {
						flex: 1 1 auto;
						margin-right: 1px;
					}
				}
			}
		}
	}
}

#important-match {
	.sport-name {
		display: flex;
		width: 725px;
		box-sizing: border-box;

		// justify-content: space-around;
		.sport-simple-select {
			height: 48px;
			line-height: 48px;
			color: #FFF;
			width: 158px;
			font-size: 14px;
			text-align: left;
			margin-bottom: -1px;
			background-color: #FFF;
			color: #353A45;

			&:hover {
				border-bottom: 4px solid #BEEDFF;
			}

			.select-title {
				padding: 0 20px;

				.arrow-icon {
					background-color: #FFF;
				}

				background-color: #FFF;
			}

			.select-list {
				background-color: #FFF;
			}

			.select-item {
				padding: 0 18px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				&:hover {
					color: #FFF;
				}
			}

			.select-item-active {
				color: #FFF;
			}
		}

		.sport-simple-select-active {
			border-bottom: 4px solid @c09D5DEtoE1FF2F;
		}
	}
}
</style>
