import 'core/core';
import 'base/base';

import { userPushInit } from 'base/js/userPush';

import store from 'store/home';
import Vue from 'vue';
import { i18n, loadLanguageAsync } from 'locale/setup';
import home from './home.vue';

window.v_store = store;

loadLanguageAsync().then(lang => {
	new Vue({
		el: '#app',
		store,
		i18n,
		render: h => h(home)
	});

	userPushInit();
});
