
function getState () {
	return {
		sportSize: []
	};
}

export default getState();

export { getState };
