<template>
	<div v-if="!showSkeleton">
		<div class="recommend-block" v-if="recommendEvents && recommendEvents.events && recommendProductStatus !== 1">
			<div :class="['recommend-events']"
				 v-if="recommendProductStatus !== 1"
				 v-loading:fetchRecommendEvents="recommendLoading">
				<!-- 横向滚动比赛列表 -->
				<div class="recommend-wrap" @mouseenter="disableTimer" @mouseleave="generateTimer">
					<ul class="recommend-list" ref="nav" :style="`transform: translateX(-${moveX}px)`">
						<EventRecommend :event="recommendEvents.events[eventId]" :key="eventId"
										v-for="eventId in recommendEvents.eventOrder"/>
					</ul>
				</div>
			</div>
			<div class="recommend-page" v-if="true || total > 1">
				<div :class="['page-point', { active: i === pageNum }]" @click="goPage(i)" v-for="(num, i) in total"
					 :key="i"/>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import 'components/loading';
import EventRecommend from './eventRecommend.vue';
import commonEvent from 'config/eventConfig/commonEvent';
import {EventBus} from 'kernel/js/eventBus';

export default {
	name: 'RecommendEvents',
	components: {
		EventRecommend
	},
	data() {
		return {
			pageWidth: 1000,
			pageSize: 4, // 这里一页四个写死，配合前端样式
			pageNum: 0,
			duration: 4000,
			timer: ''
		};
	},
	computed: {
		...mapState('layout', [
			'showSkeleton'
		]),
		...mapState('home', [
			'recommendEvents',
			'recommendLoading',
			'recommendProductStatus'
		]),
		moveX() {
			return this.pageWidth * this.pageNum;
		},
		total() {
			if (this.recommendEvents && this.recommendEvents.eventOrder) {
				return Math.ceil(this.recommendEvents.eventOrder.length / 4);
			}
			return 0;
		}
	},
	watch: {},
	methods: {
		...mapActions('home', [
			'fetchRecommendEvents',
			'subRecommendEvents',
			'unsubRecommendEvents'
		]),
		goPage(i) {
			this.pageNum = i;
			this.generateTimer();
		},
		generateTimer() {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.pageNum++;
				if (this.pageNum >= this.total) {
					this.pageNum = 0;
				}
				this.generateTimer();
			}, this.duration);
		},
		disableTimer() {
			clearTimeout(this.timer);
		}
	},
	mounted() {
		EventBus.$emit(commonEvent.SKELETON_COMPONENT_REGISTER)
		this.fetchRecommendEvents().then(() => {
			this.generateTimer();
			EventBus.$emit(commonEvent.SKELETON_COMPONENT_LOADED)
		});
		this.subRecommendEvents();
	},
	beforeDestroy() {
		this.unsubRecommendEvents();
	}
};
</script>

<style lang="less" scoped>
@import "base/style/variable.less";
@import 'base/style/mixin.less';

.recommend-block {
	width: 1000px;
	min-width: 1000px;
	margin: 0 auto;

	.recommend-page {
		line-height: 12px;
		display: flex;
		align-items: center;
		justify-content: center;

		.page-point {
			cursor: pointer;
			margin-right: 10px;
			width: 10px;
			height: 10px;
			border: 1px solid #F98500;
			border-radius: 50%;

			&.active {
				background-color: #F98500;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.feature-game-label {
	color: @dark2;
	font-size: 14px;
	line-height: 28px;
	background-color: @white;
	padding: 0 10px;
}

.recommend-events {
	background-color: @cFFFFFFto06182F;

	.recommend-wrap {
		position: relative;
		height: 156px;
		overflow: hidden;

		.recommend-list {
			position: absolute;
			z-index: 1;
			white-space: nowrap;
			transition-timing-function: cubic-bezier();
			transition-property: all;
			transition-duration: 1s;

			.m-event-recommend {
				.m-recommend-header {
					.m-title-img {
						margin-left: -19px;
					}
				}
			}
		}
	}
}

</style>
